import * as React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import secreto from "../../assets/icons/secreto.svg";
// import karaoke from "../../assets/icons/karaoke.svg";
// import mentalismo from "../../assets/icons/mentalismo.svg";
// import juego from "../../assets/icons/juego.svg";
import { ReactComponent as Secreto } from "../../assets/icons/secreto.svg";
import { ReactComponent as Karaoke } from "../../assets/icons/karaoke.svg";
import { ReactComponent as Mentalismo } from "../../assets/icons/mentalismo.svg";
import { ReactComponent as Juego } from "../../assets/icons/juego.svg";

function JocLlegenda({ item }) {
	const Icon = IconJoc(item.icon);

	return (
		<Box mt={1} display="flex">
			<Icon width={23} fill={item.color} />
			<Typography variant="caption" color="black" ml={0.5}>
				{item.nom}
			</Typography>
		</Box>
	);
}

function JocCalendari({ joc }) {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");

	const Icon = IconJoc(joc?.icon);

	return <Icon fill={joc?.color} className={matches ? classes.desk : classes.mobile} />;
}

const IconJoc = (icon) => {
	switch (icon) {
		case "secreto":
			return Secreto;
		case "karaoke":
			return Karaoke;
		case "mentalismo":
			return Mentalismo;
		case "juego":
			return Juego;
		default:
			break;
	}
};

const useStyles = makeStyles((theme) => ({
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
	desk: {
		position: "absolute",
		top: 3,
		right: 3,
		width: 20,
		height: 20,
	},
	mobile: {
		position: "absolute",
		opacity: 0.3,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		margin: "auto",
		width: 40,
		height: 40,
	},
}));

export { JocLlegenda, IconJoc, JocCalendari };
