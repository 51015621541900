import { RemoveRedEye } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";

const TicketsColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "numero",
			label: "Número",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "client.nom",
			label: "Nom client",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "client.email",
			label: "E-mail client",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "compra_ticket.unique_id",
			label: "ID compra",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		// {
		// 	name: "telefon",
		// 	label: "Telèfon",
		// 	options: {
		// 		filter: false,
		// 		sort: true,
		// 		sortOrder: "desc",
		// 	},
		// },
		// {
		// 	name: "created_at",
		// 	label: "Data alta",
		// 	options: {
		// 		filter: false,
		// 		sort: true,
		// 		sortOrder: "desc",
		// 		customBodyRender: (value) =>
		// 			moment(value).format("DD/MM/YYYY HH:mm"),
		// 	},
		// },
		// {
		// 	name: "id",
		// 	label: "Accions",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		empty: true,
		// 		customBodyRenderLite: (dataIndex) => {
		// 			return (
		// 				<IconButton
		// 					onClick={() =>
		// 						navigate(
		// 							`/admin/clients/${activitats[dataIndex].id}`,
		// 							{
		// 								state: activitats[dataIndex],
		// 							}
		// 						)
		// 					}
		// 				>
		// 					<RemoveRedEye />
		// 				</IconButton>
		// 			);
		// 		},
		// 	},
		// },
	];
	return columns;
};

export default TicketsColumns;
