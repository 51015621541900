import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import theme from "../../../theme";
import sanefa from "../../../assets/img/habitacions/sanefa.svg";
import bg from "../../../assets/img/fons-2.jpg";
import { CartesHotel } from "../../../components/elements/CartesHotel";
import hotelImg from "../../../assets/img/hotel/hotel-logo.jpg";
import barImg from "../../../assets/img/hotel/bar-1.jpg";
import restaurantImg from "../../../assets/img/restaurant/Restaurant_1_v2.jpg";
import saloImg from "../../../assets/img/hotel/salo-1.jpg";
import paper from "../../../assets/img/paper-popup.png";
import img2 from "../../../assets/img/restaurant/Restaurant_2_v1.jpg";
import { Email, Phone } from "@mui/icons-material";

const imatges = [{ foto: hotelImg }, { foto: saloImg }, { foto: barImg }, { foto: restaurantImg }, { foto: img2 }, { foto: saloImg }];

export default function Franquicia() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();

	return (
		<Page title="Insomnia Corporation - Franquicia">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Grid spacing={8} container>
						<Grid item md={6} xs={12} mb={5}>
							{matches && <CartesHotel imatges={imatges} />}
						</Grid>

						<Grid item md={6} xs={12} zIndex={10}>
							{!matches && (
								<Box className={classes.wrapImg} my={4}>
									<img src={hotelImg} alt={"Insomnia Hotel"} className={classes.img} />
								</Box>
							)}
							<Box>
								<Typography variant="h1" pb={0} fontSize={40} color={theme.color.secondary}>
									Franquicia la Marca Insomnia
								</Typography>
								<Typography variant="h3" pb={3} color={theme.color.secondary}>
									y Haz Realidad tus Sueños
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
							</Box>
							<Typography pt={3} variant="body1" color={theme.color.secondary}>
								En Insomnia te ofrecemos la oportunidad de franquiciar nuestra marca y adentrarte en el mundo de los escape rooms, un sector
								lleno de emociones, creatividad y grandes oportunidades. No importa si tienes solo una idea, un local o no dispones de nada,
								nosotros te acompañamos en todo el proceso para hacer realidad tu proyecto.
							</Typography>
							<Typography py={2} variant="h3" color={theme.color.secondary}>
								Asesoramiento y Montaje
							</Typography>

							<Typography py={0} variant="body1" color={theme.color.secondary}>
								Nuestro equipo te brinda un asesoramiento completo desde el primer momento:
								<ul>
									<li>
										<strong>Diseño del proyecto</strong>: Te ayudamos a conceptualizar la idea, adaptar el espacio y seleccionar las
										temáticas que mejor se adapten a tu público objetivo.
									</li>
									<li>
										<strong>Desarrollo técnico</strong>: Nos encargamos de toda la planificación y montaje técnico, asegurando que el escape
										room sea funcional, seguro y emocionante.
									</li>
									<li>
										<strong>Escape rooms llave en mano</strong>: Ofrecemos el servicio de escape rooms llave en mano, lo que significa que
										te entregamos el proyecto completamente terminado y listo para abrir al público. Nos encargamos del diseño, la
										construcción y la ambientación, para que puedas centrarte en lo más importante: ¡dar la bienvenida a tus jugadores!
									</li>
								</ul>
							</Typography>
							<Box my={10}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Typography py={2} variant="h3" color={theme.color.secondary}>
								Sobre Nuestra Marca
							</Typography>
							<Typography py={0} variant="body1" color={theme.color.secondary}>
								<ul>
									<li>
										<strong>El Cóctel del Doctor</strong>: Primer escape room de terror en España desde 2013. Toda una referencia en el
										género de terror inmersivo.
									</li>
									<li>
										<strong>La Casa</strong>: Nombrada el 6º mejor escape room del mundo en 2021, "La Casa" es un escape room icónico que
										ofrece una experiencia de juego única y envolvente.
									</li>
									<li>
										<strong>Insomnia Hotel</strong>: El único hotel del mundo donde cada habitación es un escape room, proporcionando una
										experiencia sin igual a los huéspedes.
									</li>
								</ul>
							</Typography>
							<Box my={10}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Typography py={2} variant="h3" color={theme.color.secondary}>
								Únete a Insomnia
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								Con Insomnia, tendrás el respaldo de una marca consolidada en el sector y disfrutarás de un proyecto hecho a medida, diseñado
								para garantizar el éxito. Además, contamos con dos locales casi listos para estrenar, lo que te permitirá entrar rápidamente en
								el negocio.
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								Si buscas una inversión en el mundo del entretenimiento con un equipo profesional que te guíe en todo momento, ¡somos tu mejor
								opción!
							</Typography>
							<Box display={"flex"} ml={2}>
								<Email style={{ color: theme.palette.secondary.main }} />
								<Typography ml={2}>
									<a style={{ color: theme.palette.secondary.main }} href="mailto:info@insomniacorp.com">
										info@insomniacorp.com
									</a>
								</Typography>
							</Box>
							<Box display={"flex"} mt={2} ml={2}>
								<Phone style={{ color: theme.palette.secondary.main }} />
								<Typography ml={2}>
									<a style={{ color: theme.palette.secondary.main }} href="tel:+34689153847">
										Llámanos para más información
									</a>
								</Typography>
							</Box>
							<Typography py={3} variant="h4" color={theme.color.secondary}>
								¡Franquicia Insomnia y únete al futuro del entretenimiento!
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 40,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(5deg)",
		},
		textAlign: "center",
	},

	wrapImg2: {
		transform: "rotate(5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.color.secondary,
	},
	calendari: {
		background: `url(${paper})`,
		padding: 40,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		marginBottom: 100,
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			padding: 10,
		},
	},
}));
