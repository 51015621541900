import moment from "moment";
import { useEffect, useState } from "react";

function esJoc(data, dataJoc) {
	const exist = dataJoc?.filter(
		(item) =>
			moment(item.data).format("YYYY-MM-DD") ===
			moment(data).format("YYYY-MM-DD")
	)?.[0];

	let activat = false;
	if (exist) {
		activat = exist.joc_id === "1";
	}

	return activat;
}

function teJoc(data, dataJoc) {
	const exist = dataJoc?.filter(
		(item) =>
			moment(item.data).format("YYYY-MM-DD") ===
			moment(data).format("YYYY-MM-DD")
	)?.[0];

	let activat = false;
	if (exist) {
		activat = exist.joc_id;
	}

	return activat;
}

function tePleRestaurant(data, dataJoc) {
	const exist = dataJoc?.filter(
		(item) =>
			moment(item.data).format("YYYY-MM-DD") ===
			moment(data).format("YYYY-MM-DD")
	)?.[0];

	let activat = false;
	if (exist) {
		activat = true;
	}

	return activat;
}

function quinJoc(data, dataJoc) {
	const exist = dataJoc?.filter(
		(item) =>
			moment(item.data).format("YYYY-MM-DD") ===
			moment(data).format("YYYY-MM-DD")
	)?.[0];

	return exist?.joc;
}

const useCountdown = (targetDate) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(
		countDownDate - new Date().getTime()
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const hours = Math.floor(
		(countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

	return [days, hours, minutes, seconds];
};

export { esJoc, quinJoc, teJoc, useCountdown, tePleRestaurant };
