import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "../../../components/elements/CustomButton";
import { ReactComponent as Dificultat } from "../../../assets/icons/dificultat.svg";
import { ReactComponent as Persones } from "../../../assets/icons/persones.svg";
import { ReactComponent as Temps } from "../../../assets/icons/temps.svg";
import { ReactComponent as Terror } from "../../../assets/icons/terror.svg";

export default function ScaperoomItem({ scape, setScape, setOpen }) {
	const classes = useStyles();

	const editar = () => {
		setScape(scape);
		setOpen(true);
	};

	return (
		<Grid item md={3} xs={12}>
			<Box className={classes.hab}>
				<Box>
					<Avatar src={"https://api.insomniacorp.com/storage/" + scape?.foto} sx={{ width: "100%", height: "100%" }} variant="square">
						...
					</Avatar>
					<Box mt={2}>
						<Typography variant="h3">{scape?.nom}</Typography>
						<Typography variant="body2">{scape?.desc.substring(0, 80)}...</Typography>
						{Number(scape?.pagament_complet) ? (
							<Typography variant="body2">
								<strong>Pagament complet</strong>
							</Typography>
						) : (
							<Typography variant="body2">
								<strong>Paga i senyal:</strong> {scape?.scaperoom.preu_ps} €
							</Typography>
						)}
						<Box display="flex" justifyContent={"space-between"} spacing={2} m={2}>
							<Box display="flex" flexDirection={"column"} alignItems="center">
								<Persones width={40} />{" "}
								<Typography variant="body2">
									{scape?.scaperoom.min_p}-{scape?.scaperoom.max_p}
								</Typography>
							</Box>
							<Box display="flex" flexDirection={"column"} alignItems="center">
								<Temps width={40} /> <Typography variant="body2">{scape?.scaperoom.temps} min</Typography>
							</Box>
							<Box display="flex" flexDirection={"column"} alignItems="center">
								<Dificultat width={40} /> <Typography variant="body2">{scape?.scaperoom.dificultat}/5</Typography>
							</Box>
							<Box display="flex" flexDirection={"column"} alignItems="center">
								<Terror width={40} /> <Typography variant="body2">{scape?.scaperoom.terror}/5</Typography>
							</Box>
						</Box>
						<Typography>Preus: </Typography>
						<Grid container spacing={1}>
							{scape?.preus?.map((preu) => (
								<Grid item xs={4}>
									<Box className={classes.pastilla}>
										<Typography variant="caption" color="primary">
											{preu.persones}p: {preu.preu} €
										</Typography>
									</Box>
								</Grid>
							))}
						</Grid>
					</Box>
				</Box>
				<Box mt={3}>
					<Stack direction={"row"} spacing={2}>
						<CustomButton title="Editar" onClick={editar} />
					</Stack>
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	hab: {
		backgroundColor: "white",
		padding: 20,
	},
	pastilla: {
		border: "1px solid black",
		backgroundColor: theme.color.secondary,
		display: "table",
		paddingLeft: 5,
		paddingRight: 5,
	},
}));
