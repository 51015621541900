import { Container, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import theme from "../../../theme";
import logo from "../../../assets/logo.png";
import PremisSobre from "./PremisSobre";

export function Sobre() {
	const classes = useStyles();

	return (
		<Page title="Insomnia Corporation - Sobre la corporación">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Grid spacing={10} container>
						<Grid item md={6} xs={12} mb={5}>
							<Box display={"flex"} flexDirection={"column"}>
								<Typography
									variant="h3"
									pb={4}
									fontSize={35}
									color={theme.color.secondary}
								>
									Sobre la corporación
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
								<Typography
									pt={3}
									pb={1}
									variant="body1"
									color={theme.color.secondary}
								>
									Insomnia Corporation llevamos más de 15 años
									dedicados al mundo del terror.
								</Typography>
								<Typography
									py={1}
									variant="body1"
									color={theme.color.secondary}
								>
									En 2013 inauguramos el Cóctel del Doctor, el
									primer escape room de terror del país, hoy
									en día, sigue siendo una experiencia
									increíble.
								</Typography>
								<Typography
									py={1}
									variant="body1"
									color={theme.color.secondary}
								>
									En 2020 abrimos La Casa, un escape House,
									premiada como mejor escape room de terror
									del país y 6º mejor escape room del mundo en
									2021.
								</Typography>
								<Typography
									pb={3}
									pt={1}
									variant="body1"
									color={theme.color.secondary}
								>
									En 2021, abrimos Insomnia Hotel, el único
									hotel del mundo en el que cada habitación es
									un escape room distinto.
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
							</Box>
						</Grid>

						<Grid item md={6} xs={12}>
							<Box>
								<img
									src={logo}
									alt="Logo Insomnia Corp."
									className={classes.logo}
								/>
							</Box>
						</Grid>
					</Grid>
					<Typography
						variant="h3"
						pt={10}
						pb={2}
						fontSize={35}
						color={theme.color.secondary}
					>
						Reconocimientos
					</Typography>
					<Divider
						sx={{
							borderColor: theme.color.secondary,
						}}
					/>
					<Box my={10}>
						<PremisSobre />
					</Box>
				</Container>
			</Box>
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 100,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	logo: {
		zIndex: 0,
		width: "100%",
		paddingTop: 40,
	},
	calendari: {
		padding: 40,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
}));
