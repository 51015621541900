import { Add } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import ScaperoomAddDialog from "./ScaperoomAddDialog";
import ScaperoomItem from "./ScaperoomItem";

export default function ScaperoomsAdmin() {
	const [scapes, setScapes] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [update, setUpdate] = useState(0);
	const [scape, setScape] = useState();

	useEffect(() => {
		const obtenir = async () => {
			const scapes = await getElements("scaperooms");
			setScapes(scapes.result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	const obrir = () => {
		setScape([]);
		setOpen(true);
	};

	return (
		<PageAdmin
			title="Escape Rooms"
			button={
				<Tooltip title="Afegir escape room">
					<IconButton onClick={obrir}>
						<Add />
					</IconButton>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<Grid container spacing={2}>
					{scapes?.map((sca) => (
						<ScaperoomItem
							scape={sca}
							setScape={setScape}
							setOpen={setOpen}
						/>
					))}
				</Grid>
			</Loading>
			<ScaperoomAddDialog
				open={open}
				setOpen={setOpen}
				setUpdate={setUpdate}
				scape={scape}
				setLoading={setLoading}
				loading={loading}
			/>
		</PageAdmin>
	);
}
