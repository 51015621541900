import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";

const Thumb = (props) => {
	const [thumb, setThumb] = useState();

	useEffect(() => {
		if (props?.file?.[0]?.name) {
			let reader = new FileReader();

			reader.onloadend = () => {
				setThumb(reader.result);
			};
			reader?.readAsDataURL(props.file?.[0]);
		} else {
			if (props.file?.length === 0) {
				setThumb("");
			} else {
				setThumb("https://api.insomniacorp.com/storage/" + props.file);
			}
		}
	}, [props?.file]);

	return (
		<Avatar
			variant="rounded"
			src={thumb}
			alt={props?.file?.name}
			sx={{
				width: "100%",
				height: 300,
			}}
		/>
	);
};

export default Thumb;
