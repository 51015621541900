import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "./CustomButton";
import pastilla from "../../assets/img/paper.png";
import { useNavigate } from "react-router";
export default function PastillaItem({ nom, desc, foto, setOpen, link = "/habitacions" }) {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Grid item md={6} xs={12}>
			<Box className={classes.hab}>
				<Box display="flex" flexDirection={"column"} position="relative">
					<Avatar src={foto} sx={{ width: "100%", height: "100%" }} variant="square" />
					<Box mt={2}>
						<Typography variant="h6" mb={3}>
							{nom}
						</Typography>
						<Typography variant="body1">{desc}</Typography>
					</Box>
				</Box>
				<Box mt={3}>
					<Stack direction={"row"} spacing={2}>
						<CustomButton title="Reservar" fullWidth onClick={() => setOpen(true)} />
						<CustomButton title="+ Info" fullWidth onClick={() => navigate(link)} />
					</Stack>
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	hab: {
		background: `url(${pastilla})`,
		padding: 40,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	pastilla: {
		border: "1px solid black",
		backgroundColor: theme.color.secondary,
		display: "table",
		paddingLeft: 5,
		paddingRight: 5,
	},
}));
