import { RemoveRedEye } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";

const ClientsColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "email",
			label: "E-mail",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "poblacio",
			label: "Població",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "telefon",
			label: "Telèfon",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "created_at",
			label: "Data alta",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					moment(value).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(
									`/admin/clients/${activitats[dataIndex].id}`,
									{
										state: activitats[dataIndex],
									}
								)
							}
						>
							<RemoveRedEye />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default ClientsColumns;
