import { Add, CalendarMonth, CardMembership, Delete, Edit, Email, Person } from "@mui/icons-material";
import { Avatar, Card, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { getElement, getElements, updateElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import Estat from "../../../components/elements/Estat";
import Pill from "../../../components/elements/Pill";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import theme from "../../../theme";
import ClientDialogEdit from "./ClientDialogEdit";
import ReservaDialogAdd from "./ReservaDialogAdd";
import ReservaDialogDelete from "./ReservaDialogDelete";
import ReservaDialogEdit from "./ReservaDialogEdit";
import ReservaDialogRefund from "./ReservaDialogRefund";

function ReservaSingle() {
	const classes = useStyles();
	const [reserva, setReserva] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [openAdd, setOpenAdd] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [clientId, setClientId] = useState("");
	const [valueClient, setValueClient] = useState("");
	const [label, setLabel] = useState("");
	const [name, setName] = useState("");
	const [update, setUpdate] = useState(0);
	const [openEditReserva, setOpenEditReserva] = useState(false);
	const [reservaElementId, setReservaElementId] = useState();
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("reserves", id);
			setReserva(result);
			setLoading(false);
		};
		obtenir();
	}, [id, update]);

	const editClient = (id, label, name, value) => {
		setClientId(id);
		setLabel(label);
		setName(name);
		setValueClient(value);
		setOpenEdit(true);
	};

	const reenviar = async () => {
		setLoading(true);
		const { message } = await getElement("reenviar", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate(true);
		setLoading(false);
	};

	return (
		<PageAdmin title={"Reserva " + (reserva?.client.nom ?? "")}>
			<Loading loading={loading}>
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<Pill title={reserva?.unique_id} icon={<CardMembership />} label="ID" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill title={reserva?.client.nom} icon={<Person />} label="Nom" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill title={reserva?.client?.email} icon={<Email />} label="E-mail" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill title={moment(reserva?.created_at).format("LLL")} icon={<CalendarMonth />} label="Data creació" color={theme.color.primary} />
					</Grid>
				</Grid>
				<Grid spacing={2} container mt={2}>
					<Grid item md={8} xs={12}>
						<Box className={classes.bloc}>
							<Box display="flex" justifyContent={"space-between"} alignItems="center" mb={2}>
								<Typography variant="h3">Detalls comanda</Typography>
								<IconButton
									onClick={() => {
										setOpenAdd(true);
										setReservaElementId("");
									}}
								>
									<Add />
								</IconButton>
							</Box>
							<Divider />
							{reserva?.reserva_elements.map((item) => (
								<Box className={classes.linia} key={item?.id}>
									<Box display={"flex"} alignItems="center">
										<Avatar
											src={"https://api.insomniacorp.com/storage/" + item.producte.foto}
											sx={{ width: 70, height: 70 }}
											variant="rounded"
										/>
										<Box>
											<Typography ml={2} textAlign="left">
												{item.producte.nom}
											</Typography>
											{item?.producte?.tipus_id === "3" ? (
												<Typography ml={2} variant="body2" color="black">
													{item?.variant}
												</Typography>
											) : (
												<Typography ml={2} variant="body2" color="black">
													{moment(item.data).format("LLL")}
												</Typography>
											)}
										</Box>
									</Box>
									<Box>
										{item?.producte?.tipus_id === "3" ? (
											<Typography ml={2}>{item.quantitat} unitats</Typography>
										) : (
											<Typography ml={2}>{item.quantitat} persones</Typography>
										)}
									</Box>
									<Box>
										<Typography ml={2}>
											{item.preu} € (pagat: {item.pagat} €)
										</Typography>
									</Box>
									<Box>
										<IconButton
											onClick={() => {
												setReservaElementId(item.id);
												setOpenDelete(true);
											}}
										>
											<Delete />
										</IconButton>
										<IconButton
											onClick={() => {
												setReservaElementId(item.id);
												setOpenEditReserva(true);
											}}
										>
											<Edit />
										</IconButton>
									</Box>
								</Box>
							))}
							<Box m={2} display="flex" justifyContent={"space-between"}>
								<Box>
									{reserva?.cupo && <Typography>CUPÓ</Typography>}
									<Typography>GASTOS ENVIO</Typography>
									<Typography>PAGAT</Typography>
									<Typography variant="h3">TOTAL</Typography>
									<Typography>PENDENT</Typography>
								</Box>
								<Box textAlign={"right"}>
									{reserva?.cupo && (
										<Typography ml={2}>
											{reserva?.cupo?.nom} ({reserva?.cupo?.preu} €)
										</Typography>
									)}
									<Typography ml={2}>{reserva?.gastos_envio} €</Typography>
									<Typography ml={2}>{Math.round(reserva?.pagat * 100) / 100} €</Typography>
									<Typography ml={2} variant="h3">
										{Math.round((Number(reserva?.preu) + Number(reserva?.gastos_envio)) * 100) / 100} €
									</Typography>
									<Typography ml={2}>{Math.round((reserva?.preu - reserva?.pagat) * 100) / 100} €</Typography>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item md={4} xs={12}>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Informació
							</Typography>
							<Divider />
							<Detall
								title="Nom"
								text={reserva?.client.nom}
								edit
								onClick={() => editClient(reserva?.client.id, "Nom", "nom", reserva?.client.nom)}
							/>
							<Detall
								title="Telèfon"
								text={reserva?.client.telefon}
								edit
								onClick={() => editClient(reserva?.client.id, "Telèfon", "telefon", reserva?.client.telefon)}
							/>
							<Detall
								title="E-mail"
								text={reserva?.client.email}
								edit
								onClick={() => editClient(reserva?.client.id, "E-mail", "email", reserva?.client.email)}
							/>
							<Detall
								title="Població"
								text={reserva?.client.poblacio}
								edit
								onClick={() => editClient(reserva?.client.id, "Població", "poblacio", reserva?.client.poblacio)}
							/>
							<Detall
								title="Adreça"
								text={reserva?.client.direccion}
								edit
								onClick={() => editClient(reserva?.client.id, "Adreça", "direccion", reserva?.client.direccion)}
							/>
							<Detall
								title="Província"
								text={reserva?.client.provincia}
								edit
								onClick={() => editClient(reserva?.client.id, "Província", "provincia", reserva?.client.provincia)}
							/>
							<Detall
								title="Còdi Postal"
								text={reserva?.client.codigo_postal}
								edit
								onClick={() => editClient(reserva?.client.id, "Codi Postal", "codigo_postal", reserva?.client.codigo_postal)}
							/>
							<Detall title="Pagat total" text={Number(reserva?.preu) + Number(reserva?.gastos_envio) + " €"} />
							<Detall title="Estat" text={<Estat estat={reserva?.estat} change id={reserva?.unique_id} />} />
						</Box>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Accions
							</Typography>
							<Divider />
							<Stack spacing={2} direction={"row"} mt={3}>
								<CustomButton onClick={() => reenviar()} fullWidth title={"Reenviar correus de confirmació"} />
								{reserva?.idpayuser && <CustomButton onClick={() => setOpen(true)} fullWidth title={"Efectuar devolució"} danger />}
							</Stack>
						</Box>
						{/* {reserva?.idpayuser && (
							<Box className={classes.bloc}>
								<Typography variant="h3" mb={2}>
									Devolució
								</Typography>
								<Divider />
							</Box>
						)} */}
					</Grid>
				</Grid>
			</Loading>
			<ReservaDialogRefund open={open} setOpen={setOpen} reserva={reserva} setUpdate={setUpdate} />
			<ReservaDialogAdd open={openAdd} setOpen={setOpenAdd} reserva={reserva} setUpdate={setUpdate} />
			<ReservaDialogEdit open={openEditReserva} setOpen={setOpenEditReserva} reserva={reserva} setUpdate={setUpdate} id={reservaElementId} />
			<ReservaDialogDelete reserva_element={reservaElementId} open={openDelete} setOpen={setOpenDelete} setUpdate={setUpdate} />
			<ClientDialogEdit id={clientId} name={name} label={label} setOpen={setOpenEdit} open={openEdit} setUpdate={setUpdate} value={valueClient} />
		</PageAdmin>
	);
}

export default ReservaSingle;

function Detall({ title, text, edit, onClick }) {
	const classes = useStyles();
	return (
		<Box className={classes.detall} justifyContent={"space-between"}>
			<Box>
				<Typography variant="body1">{title}</Typography>
				<Typography variant="body2" color="black" letterSpacing={0}>
					{text}
				</Typography>
			</Box>
			{edit && (
				<IconButton onClick={onClick}>
					<Edit />
				</IconButton>
			)}
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
	},
	detall: {
		display: "flex",
		alignItems: "center",
		padding: 10,
	},
	linia: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
}));
