import { Checkbox, CircularProgress, Container, Dialog, DialogTitle, Divider, FormControlLabel, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import paper from "../../../assets/img/paper-confirmacio.png";
import theme from "../../../theme";
import LoadingPublic from "../../../components/layouts/LoadingPublic";
import { useLocalStorage } from "usehooks-ts";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/elements/CustomButton";
import CustomTextField from "../../../components/elements/CustomTextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { createReserva, getElement } from "../../../API/API";
import { useSnackbar } from "notistack";
import CartItemCheckout from "../../../components/elements/CartItemCheckout";
import TitlePublic from "../../../components/elements/TitlePublic";

const schemaTorneig = yup.object({
	nom: yup.string().required("El nombre es obligatorio"),
	telefon: yup.string().required("El teléfono es obligatorio"),
	email: yup.string().email("No es un E-mail válido").required("El e-mail es obligatorio"),
	poblacio: yup.string().required("La población es obligatoria"),
});

function Checkout() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [cart, setCart] = useLocalStorage("cart", []);
	const [preu, setPreu] = useState();
	const [pagat, setPagat] = useState(0);
	const [frame, setFrame] = useState();
	const [envio, setEnvio] = useState(false);
	const [gastosEnvio, setGastosEnvio] = useState(false);
	const [open, setOpen] = useState(false);
	const [loadingCupo, setLoadingCupo] = useState(false);
	const [cupo, setCupo] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaTorneig),
	});

	useEffect(() => {
		setValue("cart", cart);
		let p = 0;
		let pg = 0;
		let preu_mercha = 0;
		cart?.map((item) => {
			p = item.preu + p;
			pg = item.pagat + pg;
			if (item?.producte?.tipus_id === "3") {
				if (item?.producte?.material?.cupo === "0") {
					setEnvio(true);
					preu_mercha = preu_mercha + Number(item?.producte?.material?.preu * Number(item?.quantitat));
				}
			}
		});
		console.log(preu_mercha);
		if (preu_mercha < 50) {
			setGastosEnvio(true);
		} else {
			setGastosEnvio(false);
		}
		setPreu(p);
		setPagat(pg);
	}, [cart]);

	useEffect(() => {
		setValue("preu", preu);
		setValue("pagat", pagat);
	}, [preu, pagat]);

	useEffect(() => {
		const subscription = watch(async (value, { name, type }) => {
			if (name === "cupo") {
				if (value.cupo.length === 8) {
					setLoadingCupo(true);
					const { result, message } = await getElement("comprovarCupons", value.cupo);
					if (result) {
						setCupo(result);
					} else {
						setCupo("NO");
					}
					setLoadingCupo(false);
				} else {
					setCupo("NO");
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	const reservar = async (values) => {
		window.scrollTo(0, 0);
		values.preu = Math.round((preu - (cupo?.preu ?? 0)) * 100) / 100;

		if (Math.round((preu - (cupo?.preu ?? 0) - pagat) * 100) / 100 < 0) {
			values.pagat = pagat + Math.round((preu - (cupo?.preu ?? 0) - pagat) * 100) / 100;
		}
		setLoading(true);
		const { message, frame, variant, id } = await createReserva(values);
		if (frame !== "") {
			setFrame(frame?.URL_REDIRECT);
			setOpen(true);
		} else {
			navigate("/reserves/urlOk?r=" + id);
		}
		enqueueSnackbar(message, {
			variant: variant,
		});
		// setLoading(false);
		// navigate("/reserves");
		setCart([]);
	};

	const removeItem = (id) => {
		const newCart = cart.filter((item) => item.index !== id);
		setCart(newCart);
		enqueueSnackbar("Producto eliminado del carro", {
			variant: "success",
		});
	};

	return (
		<Page title="Insomnia Corp. - Confirmación">
			<Box className={classes.portada}>
				<Container maxWidth={"md"} style={{ padding: 0 }}>
					<TitlePublic title="Confirmación" />
					{cart?.length === 0 ? (
						<Box mb={15} textAlign="center">
							<Typography py={5} color="secondary">
								No hay productos en la cesta
							</Typography>
							<CustomButton danger onClick={() => navigate("/reserves")} title="Volver a las reservas" />
						</Box>
					) : (
						<LoadingPublic loading={loading}>
							<Box className={classes.fons} px={matches ? 8 : 2} py={2}>
								<form onSubmit={handleSubmit(reservar)}>
									<Box my={8}>
										<Typography pb={1} variant="h6">
											MI CESTA
										</Typography>
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>
										{cart?.map((item, index) => (
											<CartItemCheckout item={item} last={index + 1 === cart?.length} removeItem={removeItem} />
										))}
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>
									</Box>
									<Box my={9}>
										<Typography pb={1} variant="h6">
											TIENES UN CUPÓN?
										</Typography>
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>
										<CustomTextField
											register={register}
											name="cupo"
											label="Código del cupón"
											inputProps={{ maxLength: 8 }}
											errors={errors.cupo}
											disabled={loadingCupo}
											endAdornment={loadingCupo && <CircularProgress size="30px" />}
										/>
										{cupo === "NO" && (
											<Typography variant="caption" color="#A13633">
												Cupón no valido...
											</Typography>
										)}
										{cupo?.id > 0 && (
											<Box>
												<Typography variant="caption" color="green">
													Cupón valido! - Descuento de {cupo.preu} €
												</Typography>
											</Box>
										)}
										<Divider
											sx={{
												borderWidth: 1,
												marginTop: 3,
												borderColor: theme.color.background,
											}}
										/>
									</Box>

									<Box my={9}>
										<Typography pb={1} variant="h6">
											RELLENA LA INFORMACIÓN
										</Typography>
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>
										<Grid container spacing={matches ? 3 : 0}>
											<Grid item md={6} xs={12}>
												<CustomTextField register={register} name="nom" label="Nombre y apellidos" errors={errors.nom} />
											</Grid>
											<Grid item md={6} xs={12}>
												<CustomTextField register={register} name="email" label="E-mail" errors={errors.email} />
											</Grid>
										</Grid>
										<Grid container spacing={matches ? 3 : 0} mb={3}>
											<Grid item md={6} xs={12}>
												<CustomTextField register={register} name="telefon" label="Telèfon" errors={errors.telefon} />
											</Grid>
											<Grid item md={6} xs={12}>
												<CustomTextField register={register} name="poblacio" label="Población" errors={errors.poblacio} />
											</Grid>
										</Grid>
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>
										{cart?.some((i) => i?.producte?.tipus_id === "3") && (
											<>
												<Typography variant="h3" mt={3}>
													Datos de envío
												</Typography>
												<Grid container spacing={matches ? 3 : 0}>
													<Grid item md={4} xs={12}>
														<CustomTextField register={register} name="direccion" label="Dirección" errors={errors.direccion} />
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomTextField register={register} name="provincia" label="Provincia" errors={errors.provincia} />
													</Grid>
													<Grid item md={4} xs={12}>
														<CustomTextField
															register={register}
															name="codigo_postal"
															label="Código Postal"
															errors={errors.codigo_postal}
														/>
													</Grid>
												</Grid>
												<Typography variant="caption" color="black" mb={2}>
													Envíos solo en España
												</Typography>
												<Divider
													sx={{
														marginTop: 2,
														borderWidth: 1,
														borderColor: theme.color.background,
													}}
												/>
											</>
										)}
									</Box>

									<Box my={8}>
										<Typography pb={1} variant="h6">
											PAGO
										</Typography>
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>
										<Box display="flex" justifyContent={"space-between"} my={4}>
											<Typography variant="h2">Total:</Typography>
											<Typography variant="h2">{Math.round((preu - (cupo?.preu ?? 0)) * 100) / 100} €</Typography>
										</Box>
										<Divider />

										<Box display="flex" justifyContent={"space-between"} my={1}>
											<Typography variant="h3">A pagar ahora:</Typography>
											{Math.round((preu - (cupo?.preu ?? 0) - pagat) * 100) / 100 < 0 ? (
												<Typography variant="h3">{pagat + Math.round((preu - (cupo?.preu ?? 0) - pagat) * 100) / 100} €</Typography>
											) : (
												<Typography variant="h3">{pagat} €</Typography>
											)}
										</Box>
										{cupo?.id > 0 && (
											<Box display="flex" justifyContent={"space-between"} my={1}>
												<Typography variant="h3">Cupón de descuento:</Typography>
												<Typography variant="h3" color="green">
													-{cupo?.preu} €
												</Typography>
											</Box>
										)}
										<Box display="flex" justifyContent={"space-between"} my={1}>
											<Typography variant="h3">Pendiente:</Typography>
											<Typography variant="h3">
												{Math.round((preu - (cupo?.preu ?? 0) - pagat) * 100) / 100 < 0
													? 0
													: Math.round((preu - (cupo?.preu ?? 0) - pagat) * 100) / 100}{" "}
												€
											</Typography>
										</Box>
										{envio && (
											<Box display="flex" justifyContent={"space-between"} my={1}>
												<Typography variant="h3">Gastos de envio:</Typography>
												<Typography variant="h3">{gastosEnvio ? "6 €" : "Envio gratis!"}</Typography>
											</Box>
										)}
										<Divider />
										<Box display={matches ? "flex" : "block"} justifyContent={"space-between"} alignItems="center" my={2}>
											<Box my={2}>
												<Typography fontSize={15} color="black">
													El pago corresponde a la paga y señal.
												</Typography>
												<Typography fontSize={15} color="black">
													El resto tendrá que se abonado el dia de la actividad.
												</Typography>
												<FormControlLabel
													control={<Checkbox required />}
													label={
														<Box>
															<Typography variant="body1" display={"flex"}>
																He leído y acepto la{" "}
																<Box
																	ml={1}
																	style={{
																		textDecoration: "underline",
																	}}
																	onClick={() => navigate("/legal/politica-privacitat")}
																>
																	Política de Privacidad
																</Box>
															</Typography>
														</Box>
													}
												/>
											</Box>
											<CustomButton title="Pagar" type="submit" loading={loading} fullWidth={!matches} />
										</Box>
									</Box>
								</form>
							</Box>
						</LoadingPublic>
					)}
				</Container>
				<Dialog open={open} maxWidth="lg">
					<DialogTitle>
						<Typography variant="h2" textAlign={"center"}>
							Pago con tarjeta
						</Typography>
					</DialogTitle>
					{frame && (
						<iframe
							src={frame}
							title="Pagament"
							height={300}
							width={matches ? 500 : "auto"}
							style={{
								paddingBottom: 50,
								border: "none",
							}}
						/>
					)}
				</Dialog>
			</Box>
		</Page>
	);
}

export default Checkout;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 200,
		height: "100%",
		background: `url(${bg})`,
		backgroundAttachment: "fixed",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
		paddingBottom: 200,
	},
	fons: {
		background: `url(${paper})`,
		backgroundSize: "cover",
		backgroundRepeat: "round",
	},
	reserves: {
		color: theme.color.primary,
		fontWeight: 700,
		textDecoration: "none",
	},
	senyal: {
		width: 140,
		marginBottom: 20,
		[theme.breakpoints.down("sm")]: {
			width: 100,
		},
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
}));
