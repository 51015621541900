import { Close } from "@mui/icons-material";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
// import { useCountdown } from "usehooks-ts";
import { useCountdown } from "../Utils";

export default function CartItem({ item, removeItem }) {
	const expired = new Date(item?.created_at);
	const tenminutes = expired.setHours(expired.getHours(), expired.getMinutes() + 10, expired.getSeconds(), 0);
	const [days, hours, minutes, seconds] = useCountdown(new Date(tenminutes));

	useEffect(() => {
		if (minutes + seconds <= 0) {
			removeItem(item?.index);
		}
	}, [minutes, seconds]);

	return (
		<ListItem
			alignItems="flex-start"
			secondaryAction={
				removeItem ? (
					<IconButton edge="end" aria-label="comments" onClick={() => removeItem(item?.index, item)}>
						<Close />
					</IconButton>
				) : (
					<Typography>{Math.round(item?.preu * 100) / 100} €</Typography>
				)
			}
		>
			<ListItemAvatar>
				<Avatar variant="square" src={"https://api.insomniacorp.com/storage/" + item?.producte.foto} sx={{ marginTop: 1 }} />
			</ListItemAvatar>
			<ListItemText
				primary={item?.hora ? item?.producte.nom : item?.producte?.tipus_id === "3" ? item?.producte.nom : "Insomnia Hotel - " + item?.producte.nom}
				secondary={
					<>
						{item?.producte?.tipus_id === "2" || item?.producte?.tipus_id === "1" ? (
							<Typography fontSize={15}>
								{moment(item?.data).format("DD/MM/YYYY")}
								{item?.hora !== "00:00:00" ? (
									<span>
										{" "}
										a las {moment(item?.hora, "HH:mm:ss").format("HH:mm")}
										{" h "}-{" "}
									</span>
								) : (
									" a las 18:00h - "
								)}
								{item.quantitat} persones
							</Typography>
						) : (
							<>
								{Number(item?.producte?.material?.talles) === 1 && <Typography fontSize={15}>Talla: {item.variacio}</Typography>}
								<Typography fontSize={15}>Cantidad: {item.quantitat}</Typography>
							</>
						)}
						{minutes + seconds > 0 && (
							<Typography fontSize={15}>
								Tienes {minutes}min y {seconds}s para reservar
							</Typography>
						)}
					</>
				}
			/>
		</ListItem>
	);
}
