import { ShoppingCart } from "@mui/icons-material";
import { Badge, Divider, IconButton, List, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/elements/CustomButton";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router";
import CartItem from "../../components/elements/CartItem";
import { useSnackbar } from "notistack";
import { removeCistella } from "../../API/API";
import moment from "moment";

function Cart() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [preu, setPreu] = useState(0);
	const [pagat, setPagat] = useState(0);
	const [envio, setEnvio] = useState(false);
	const [gastosEnvio, setGastosEnvio] = useState(false);
	const classes = useStyles();
	const [cart, setCart] = useLocalStorage("cart", []);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		let p = 0;
		let pg = 0;
		let preu_mercha = 0;
		cart?.map((item) => {
			p = item.preu + p;
			pg = item.pagat + pg;
			if (item?.producte?.tipus_id === "3") {
				if (item?.producte?.material?.cupo === "0") {
					setEnvio(true);
					preu_mercha = preu_mercha + Number(item?.producte?.material?.preu * Number(item?.quantitat));
				}
			}
		});
		console.log(preu_mercha);
		if (preu_mercha < 50) {
			setGastosEnvio(true);
		} else {
			setGastosEnvio(false);
		}
		setPreu(p);
		setPagat(pg);
	}, [cart]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const removeItem = async (id, element) => {
		setLoading(true);
		await removeCistella({
			data: moment(element.data).format("YYYY-MM-DD") + " " + element.hora,
			producte_id: element.producte_id,
		});
		const newCart = cart.filter((item) => item.index !== id);
		setCart(newCart);
		enqueueSnackbar("Producto eliminado del carro", {
			variant: "success",
		});
		setLoading(false);
	};

	return (
		<Box>
			<IconButton onClick={handleClick}>
				<Badge badgeContent={cart?.length} color="error">
					<ShoppingCart color="secondary" />
				</Badge>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				className={classes.popover}
			>
				<Typography pt={1} pb={1} pl={2}>
					Carrito
				</Typography>
				<Divider />
				{cart?.length === 0 ? (
					<Typography p={2}>No hay productos en el carrito</Typography>
				) : (
					<List>
						{cart?.map((item, index) => (
							<div key={index}>
								<CartItem item={item} removeItem={removeItem} />
								{index !== cart.length - 1 && <Divider variant="inset" component="li" />}
							</div>
						))}
					</List>
				)}
				<Divider />
				{envio && (
					<Box display="flex" justifyContent={"space-between"}>
						<Typography pt={1} pb={1} pl={2} fontSize={16}>
							Gastos de envio:
						</Typography>
						<Box textAlign={"right"}>
							<Typography pt={1} pr={2} variant="h3" fontSize={16}>
								{gastosEnvio ? "6 €" : "Envio gratis!"}
							</Typography>
						</Box>
					</Box>
				)}
				<Box display="flex" justifyContent={"space-between"}>
					<Typography pb={1} pl={2}>
						Total:
					</Typography>
					<Box textAlign={"right"}>
						<Typography pr={2} variant="h3">
							{Math.round(preu * 100) / 100} €
						</Typography>

						<Typography variant="caption" color="black" pb={1} pr={2}>
							A pagar: {pagat + (gastosEnvio ? 6 : 0)} €
						</Typography>
					</Box>
				</Box>
				<CustomButton
					title="Pagar"
					fullWidth
					danger
					onClick={() => {
						navigate("/reserves/checkout");
						handleClose();
					}}
					disabled={cart?.length === 0}
					loading={loading}
				/>
			</Popover>
		</Box>
	);
}

export default Cart;

const useStyles = makeStyles((theme) => ({
	popover: {
		"& .MuiPaper-root": {
			borderRadius: 0,
			backgroundColor: theme.color.secondary,
		},
	},
}));
