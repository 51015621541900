import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getElement } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import Loading from "../../../components/layouts/Loading";
import theme from "../../../theme";
import { ReactComponent as Dificultat } from "../../../assets/icons/dificultat.svg";
import { ReactComponent as Persones } from "../../../assets/icons/persones.svg";
import { ReactComponent as Temps } from "../../../assets/icons/temps.svg";
import { ReactComponent as Terror } from "../../../assets/icons/terror.svg";
import CustomButton from "../../../components/elements/CustomButton";
import DialogReserva from "../reserves/DialogReserva";
import Premis from "./Premis";
import { FAQS } from "../../../components/elements/FAQS";
import casaImg from "../../../assets/img/escape-casa.jpg";
import coctelImg from "../../../assets/img/escape-coctel.jpg";

function ScapeSingle() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const { id } = useParams();
	const [scape, setScape] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("producte", id);
			setScape(result);
			setLoading(false);
		};
		id && obtenir();
	}, [id]);

	return (
		<Page title="Insomnia Corporation - Restaurant">
			<Box className={classes.portada}>
				<Container>
					<Loading loading={loading}>
						<Grid spacing={6} container>
							<Grid item md={6} xs={12} mb={5}>
								<Box p={4} className={matches ? classes.wrapImg : classes.wrapImgMobile}>
									<img src={scape?.id === 6 ? casaImg : coctelImg} alt={scape?.nom} className={matches ? classes.img : classes.imgMobile} />
								</Box>
							</Grid>
							<Grid item md={6} xs={12}>
								<CustomButton title={"Reservar " + scape?.nom} light onClick={() => setOpen(true)} fullWidth={!matches} />
								<Box my={2} />
								<Divider sx={{ borderColor: theme.color.secondary }} />
								<Typography
									py={3}
									dangerouslySetInnerHTML={{ __html: scape?.desc }}
									color={theme.color.secondary}
									variant="body1"
									textAlign={"left"}
								></Typography>
								<Divider sx={{ borderColor: theme.color.secondary }} />
								<Box display={"flex"} justifyContent={"space-between"} my={3} alignItems={"center"}>
									<Typography variant="h4" color={theme.color.secondary}>
										Precio
									</Typography>

									<Typography variant="body1" color={theme.color.secondary}>
										<span style={{ fontSize: 30 }}>{scape?.preus?.filter((i) => i.persones === "2")[0].preu} </span>€ / persona
									</Typography>
								</Box>
								<Divider sx={{ borderColor: theme.color.secondary }} />
								<Params select={scape} />
								<Divider sx={{ borderColor: theme.color.secondary }} />
								<Premis scape={scape} />
								<Divider sx={{ borderColor: theme.color.secondary, marginBottom: 3 }} />
								<CustomButton title={"Reservar " + scape?.nom} light onClick={() => setOpen(true)} fullWidth={!matches} />
								<Typography variant="h4" pt={4} pb={2}>
									FAQS
								</Typography>
								<FAQS />
							</Grid>
						</Grid>
					</Loading>
				</Container>
			</Box>
			<DialogReserva open={open} setOpen={setOpen} select={scape} />
		</Page>
	);
}

export default ScapeSingle;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	wrapImg: {
		transform: "rotate(-10deg)",
		position: "fixed",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
	},
	img: {
		zIndex: 0,
		width: 450,
		border: "4px solid " + theme.color.secondary,
	},
	wrapImgMobile: {
		transform: "rotate(-6deg)",
		transition: "0.2s",
		paddingBottom: 50,
		zIndex: 0,
		"&:hover": {
			transform: "rotate(2deg)",
		},
	},
	imgMobile: {
		zIndex: 0,
		width: "100%",
		border: "4px solid " + theme.color.secondary,
	},
}));

function Params({ select }) {
	return (
		<Grid container spacing={2} mb={2} mt={1}>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Persones width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						{select?.scaperoom.min_p}-{select?.scaperoom.max_p} jugadores
					</Typography>
				</Box>
			</Grid>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Temps width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						{select?.scaperoom.temps} minutos
					</Typography>
				</Box>
			</Grid>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Dificultat width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						Dificultad {select?.scaperoom.dificultat}/5
					</Typography>
				</Box>
			</Grid>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Terror width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						Terror {select?.scaperoom.terror}/5
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
}
