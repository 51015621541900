import { Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import logo from "../../../../assets/logo.png";
import senyal from "../../../../assets/img/senyal.png";
import bg from "../../../../assets/img/fons.jpg";
import Page from "../../../../components/layouts/Page";
import { Warning } from "@mui/icons-material";

function Destruct() {
	const classes = useStyles();
	const [timeout, setTime] = useState(10);
	const [finish, setFinish] = useState(false);

	useEffect(() => {
		timeout > 0
			? setTimeout(() => setTime(timeout - 1), 1000)
			: setFinish(true);
	}, [timeout]);

	return (
		<Page title="Hotel Insomnia - Carta">
			<Box className={classes.portada} p={3}>
				<Warning color="primary" style={{ fontSize: 90 }} />
				{finish ? (
					<Typography color="white" variant="h4" textAlign={"center"}>
						Dispositivo inutilizable
					</Typography>
				) : (
					<>
						<Typography color="white" textAlign={"center"}>
							El dispositivo se apagarà en
						</Typography>
						<Typography
							color="white"
							variant="h1"
							my={2}
							textAlign={"center"}
						>
							{timeout}
						</Typography>{" "}
						<Typography color="white" textAlign={"center"}>
							segundos
						</Typography>
					</>
				)}
			</Box>
		</Page>
	);
}

export default Destruct;

const useStyles = makeStyles((theme) => ({
	portada: {
		height: "90vh",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
}));
