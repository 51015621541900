import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../../theme";
import CustomButton from "../../../components/elements/CustomButton";
import { addElement, getElement, getElements, refund } from "../../../API/API";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import moment from "moment";
import DialogReservaCalendari from "../../public/reserves/componentsSR/DialogReservaCalendari";
import DialogReservaCalendariHotel from "../../public/reserves/componentsH/DialogReservaHotelCalendari";
import DialogReservaPreu from "../../public/reserves/componentsSR/DialogReservaPreu";
import DialogReservaHotelPreu from "../../public/reserves/componentsH/DialogReservaHotelPreu";
import Loading from "../../../components/layouts/Loading";
import { esJoc } from "../../../components/Utils";

function ReservaDialogAdd({ setUpdate, open, setOpen, reserva }) {
	const [producte, setProducte] = useState("");
	const [preu, setPreu] = useState(0);
	const [data, setData] = useState();
	const [hora, setHora] = useState();
	const [jugadors, setJugadors] = useState();
	const [habitacio, setHabitacio] = useState();
	const [tipus, setTipus] = useState();
	const [loading, setLoading] = useState(false);
	const [loadingInit, setLoadingInit] = useState(true);
	const [productes, setProductes] = useState([]);
	const [dataJoc, setDataJoc] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, register, setValue, reset } = useForm();

	useEffect(() => {
		const obtenir = async () => {
			const productes = await getElements("productes");
			const dataJoc = await getElements("datajoc");
			setDataJoc(dataJoc.result);
			setProductes(productes.result);
			setLoadingInit(false);
		};
		open && obtenir();

		open && setValue("reserva_id", reserva?.id);
		setValue("pagat", 0);
		setHora("00:00:00");
	}, [open]);

	// useEffect(() => {
	// 	const obtenir = async () => {
	// 		setLoading(true);
	// 		const { result } = await getElement("reservesElement", id);
	// 		setReservaElement(result);
	// 		reset(result);
	// 		setPreu(result.preu);
	// 		setData(moment(result.data).toDate());
	// 		setHora(
	// 			moment(result.data, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
	// 		);
	// 		setProducte(result.producte);
	// 		setJugadors(Number(result.quantitat));
	// 		setTipus(result.producte.tipus_id);
	// 		setLoading(false);
	// 	};
	// 	if (id) obtenir();
	// }, [id, open]);

	// useEffect(() => {
	// 	console.log(producte);
	// }, [producte]);

	// useEffect(() => {
	// 	if (jugadors) {
	// 		setValue("quantitat", jugadors);
	// 		const preu = producte?.preus?.filter(
	// 			(item) =>
	// 				Number(item?.persones) === jugadors
	// 		)[0]?.preu;
	// 		setPreu(jugadors * preu);
	// 	} else {
	// 		setPreu("-");
	// 	}
	// }, [jugadors, producte]);

	useEffect(() => {
		setValue("preu", Number(preu));
		setValue("tipus", tipus);
		setValue("data", moment(data).format("YYYY-MM-DD") + " " + hora);
		setValue("quantitat", jugadors);
	}, [hora, data, preu, tipus, jugadors]);

	const handleChange = (event) => {
		setProducte(event.target.value);
		setTipus(event.target.value.tipus_id);
		setValue("producte_id", event.target.value.id);
		// if (event.target.tipus_id === 2) {
		setHabitacio(event.target.value);
		// }
	};

	const handleClose = () => {
		setOpen(false);
		reset();
		setPreu();
		setHabitacio();
		setProducte();
		setJugadors();
		setTipus();
	};

	const crear = async (values) => {
		console.log(values);
		values.preu = preu * jugadors;
		setLoading(true);
		const { message } = await addElement("reservesElement", values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate((prev) => prev + 1);
		handleClose();
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Afegir concepte
				</Box>
			</DialogTitle>
			<DialogContent>
				<Loading loading={loadingInit}>
					<form onSubmit={handleSubmit(crear)}>
						<Box my={3}>
							<Box mb={3}>
								<Grid spacing={3} container>
									<Grid md={6} item>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">
												Sel·lecciona el producte
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={producte}
												label="Sel·lecciona el producte"
												onChange={handleChange}
											>
												{productes?.map((item) => (
													<MenuItem
														key={item.id}
														value={item}
													>
														{item?.nom}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid md={6} item>
										<TextField
											{...register("pagat")}
											label="Quantitat pagada"
											type={"number"}
											fullWidth
											InputLabelProps={{ shrink: true }}
											inputProps={{
												step: "0.01",
											}}
										/>
									</Grid>
								</Grid>
							</Box>

							{producte &&
								(tipus === "1" ? (
									<>
										<DialogReservaCalendari
											data={data}
											hora={hora}
											jugadors={jugadors}
											loading={loading}
											select={producte}
											setData={setData}
											setHora={setHora}
											setJugadors={setJugadors}
											setPreu={setPreu}
											admin
										/>
										<DialogReservaPreu
											data={data}
											hora={hora}
											jugadors={jugadors}
											preu={preu}
											select={producte}
											admin
										/>
									</>
								) : (
									<>
										<DialogReservaCalendariHotel
											data={data}
											jugadors={jugadors}
											loading={loading}
											select={producte}
											setData={setData}
											setJugadors={setJugadors}
											habitacions={productes?.filter(
												(item) =>
													item?.tipus_id === tipus
											)}
											habitacio={habitacio}
											dataJoc={dataJoc}
											setHabitacio={setHabitacio}
											setPreu={setPreu}
											setLoading={setLoading}
											admin
										/>
										<DialogReservaHotelPreu
											data={data}
											dataJoc={dataJoc}
											habitacio={habitacio}
											jugadors={jugadors}
											preu={preu}
											admin
										/>
									</>
								))}
						</Box>
						<Stack spacing={2} direction="row">
							<CustomButton
								title={"Tancar"}
								danger
								fullWidth
								onClick={() => setOpen(false)}
							/>
							<CustomButton
								type="submit"
								title={"Crear"}
								fullWidth
								loading={loading}
							/>
						</Stack>
					</form>
				</Loading>
			</DialogContent>
		</Dialog>
	);
}

export default ReservaDialogAdd;
