import { Avatar, Box, Divider, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useCountdown } from "../Utils";

export default function CartItemCheckout({ item, last, removeItem, comprat }) {
	const matches = useMediaQuery("(min-width:960px)");
	const expired = new Date(item?.created_at);
	const tenminutes = expired.setHours(expired.getHours(), expired.getMinutes() + 10, expired.getSeconds(), 0);
	const [days, hours, minutes, seconds] = useCountdown(new Date(tenminutes));

	useEffect(() => {
		if (minutes + seconds <= 0) {
			removeItem && removeItem(item?.index);
			console.log("Elemento eliminado del carro por tiempo");
		}
	}, [minutes, seconds]);

	return (
		<Box>
			<Box alignItems="center" display="flex" justifyContent={"space-between"} my={3}>
				<Box display="flex" flexDirection={"row"}>
					<Avatar
						variant="square"
						src={"https://api.insomniacorp.com/storage/" + item?.producte.foto}
						sx={{
							width: matches ? 130 : 80,
							height: matches ? 130 : 80,
						}}
					/>
					<Box display="flex" flexDirection={"column"} justifyContent="center" ml={3}>
						<Typography variant="h6" mb={2}>
							{item?.producte.nom}
						</Typography>
						{item?.producte?.tipus_id === "1" || item?.producte?.tipus_id === "2" ? (
							<>
								<Typography>
									{moment(item?.data).format("DD/MM/YYYY")}
									{item?.hora && (
										<span>
											{" "}
											a las {moment(item?.hora, "HH:mm:ss").format("HH:mm")}
											{" h "}
										</span>
									)}
								</Typography>
								<Typography>{item.quantitat} personas</Typography>
							</>
						) : (
							<>
								{Number(item?.producte?.material?.talles) === 1 && <Typography fontSize={15}>Talla: {item.variacio}</Typography>}

								<Typography>Cantidad: {item.quantitat}</Typography>
							</>
						)}

						{minutes + seconds > 0 && !comprat && (
							<Typography fontSize={15}>
								Tienes {minutes}min y {seconds}s para reservar
							</Typography>
						)}
					</Box>
				</Box>
				<Box width={100} textAlign="right">
					<Typography variant="h2" style={{ fontWeight: 500, fontSize: 30 }}>
						{Math.round(item?.preu * 100) / 100} €
					</Typography>
				</Box>
			</Box>
			{!last && <Divider />}
		</Box>
	);
}
