import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import { IconJoc, JocLlegenda } from "../../../components/elements/JocLlegenda";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import JocsDialogEdit from "./JocsDialogEdit";

function JocsAdmin() {
	const [jocs, setJocs] = useState([]);
	const [jocSel, setJocSel] = useState();
	const [open, setOpen] = useState(false);
	const [update, setUpdate] = useState(0);
	const [loading, setLoading] = useState(true);
	const classes = useStyles();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("jocs");
			setJocs(result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	const handleEdit = (joc) => {
		setJocSel(joc);
		setOpen(true);
	};

	return (
		<PageAdmin title="Esdeveniments especials">
			<Loading loading={loading}>
				<Grid spacing={3} container>
					{jocs?.map((joc) => {
						const Icon = IconJoc(joc?.icon);
						return (
							<Grid item md={3}>
								<Box className={classes.element}>
									<Grid
										spacing={3}
										container
										mb={2}
										alignItems="center"
									>
										<Grid item md={3}>
											<Icon
												fill={joc?.color}
												className={classes.icon}
											/>
										</Grid>
										<Grid item md={9}>
											<Typography variant="h3" ml={2}>
												{joc?.nom}
											</Typography>
										</Grid>
									</Grid>
									<Typography mb={3}>{joc?.desc}</Typography>
									<CustomButton
										title={"Editar"}
										fullWidth
										onClick={() => {
											setJocSel(joc);
											setOpen(true);
										}}
									/>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</Loading>
			<JocsDialogEdit
				setOpen={setOpen}
				setUpdate={setUpdate}
				joc={jocSel}
				open={open}
			/>
		</PageAdmin>
	);
}

export default JocsAdmin;

const useStyles = makeStyles((theme) => ({
	element: {
		backgroundColor: "white",
		padding: 10,
	},
	icon: {
		width: "100%",
	},
}));
