import { Add, Delete, DeleteForever, DeleteOutline } from "@mui/icons-material";
import {
	Checkbox,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Fab,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { addScape, deleteElement, updateScape } from "../../../API/API";
import Badge from "../../../components/elements/Badge";
import CustomButton from "../../../components/elements/CustomButton";
import CustomTextField from "../../../components/elements/CustomTextField";
import ImageInput from "../../../components/elements/ImageInput";
import Thumb from "../../../components/elements/Thumb";
import Loading from "../../../components/layouts/Loading";
import theme from "../../../theme";
import CustomCheckbox from "../../../components/elements/CustomCheckbox";

const diesWeek = [0, 1, 2, 3, 4, 5, 6];
const horesDia = ["16:00:00", "18:00:00", "20:00:00", "22:00:00"];

function ScaperoomAddDialog({ scape, setUpdate, open, setOpen, setLoading, loading }) {
	const { enqueueSnackbar } = useSnackbar();
	const [inLoading, setInLoading] = useState(true);
	const [dies, setDies] = useState([]);
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		trigger,
		control,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			preus: [{ persones: "", preu: "" }],
		},
	});

	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: "preus",
	});

	useEffect(() => {
		setInLoading(true);
		if (scape?.id) {
			setTimeout(() => {
				reset(scape);
				setValue("min_p", scape?.scaperoom?.min_p);
				setValue("max_p", scape?.scaperoom?.max_p);
				setValue("calendari_id", scape?.scaperoom?.calendari_id);
				setValue("temps", scape?.scaperoom?.temps);
				setValue("dificultat", scape?.scaperoom?.dificultat);
				setValue("terror", scape?.scaperoom?.terror);
				setValue("dies", scape?.scaperoom.dies);
				setValue("preu_ps", scape?.scaperoom.preu_ps);
				setValue("pagament_complet", scape?.pagament_complet);
				setDies(JSON.parse(scape?.scaperoom.dies) ?? []);
				setInLoading(false);
			}, 400);
		} else {
			setTimeout(() => {
				reset({ preus: [{ persones: "", preu: "" }] });
				replace([{ persones: "", preu: "" }]);
				setInLoading(false);
			}, 400);
		}
	}, [scape]);

	const handleClose = () => {
		setOpen(false);
	};

	const guardar = async (values) => {
		setLoading(true);
		const { message } = await updateScape(scape.id, values);

		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	const crear = async (values) => {
		setLoading(true);
		const { message } = await addScape(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	const eliminar = async () => {
		setLoading(true);
		const { message } = await deleteElement("scapens", scape.id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	const changeDies = (value) => {
		if (dies?.some((dia) => dia.dia === value)) setDies(dies?.filter((dia) => dia.dia !== value));
		else setDies([...dies, { dia: value, hores: horesDia }]);
	};

	const changeHora = (hora, dia) => {
		// console.log("hores");
		let d = dies?.filter((i) => i.dia === dia)[0];

		if (d?.hores.some((h) => h === hora)) {
			setDies([...dies.filter((i) => i.dia !== dia), { dia: dia, hores: d.hores?.filter((h) => h !== hora) }]);
		} else setDies([...dies.filter((i) => i.dia !== dia), { dia: dia, hores: [...d?.hores, hora] }]);
	};

	useEffect(() => {
		setValue("dies", JSON.stringify(dies));
	}, [dies]);

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
		>
			{scape?.id ? (
				<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
					<Box display="flex" justifyContent={"space-between"}>
						Modificar {scape.nom}
						{/* <Fab onClick={eliminar} color="error" size="small">
							<Delete />
						</Fab> */}
					</Box>
				</DialogTitle>
			) : (
				<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
					<Box display="flex" justifyContent={"space-between"}>
						Crear scape room
					</Box>
				</DialogTitle>
			)}
			<DialogContent>
				<form onSubmit={handleSubmit(scape?.id ? guardar : crear)}>
					<Loading loading={inLoading}>
						<Box py={2}>
							<Grid spacing={3} container>
								<Grid item md={6}>
									<CustomTextField name={"nom"} register={register} label="Nom escape" required />
									<CustomTextField name={"resum"} register={register} label="Resum breu" required />
									<CustomTextField name={"desc"} register={register} label="Descripcio" rows={3} multiline required />
									<Grid container spacing={2}>
										<Grid item md={6} xs={12}>
											<CustomTextField name={"min_p"} register={register} label="Persones mínimes" required type="number" />
										</Grid>
										<Grid item md={6} xs={12}>
											<CustomTextField name={"max_p"} register={register} label="Persones màximes" required type="number" />
										</Grid>
									</Grid>
								</Grid>
								<Grid item md={6} mt={3}>
									<Thumb file={getValues("foto")} />
									<ImageInput trigger={trigger} register={register} name="foto" text={"Carregar imatge"} />
								</Grid>
							</Grid>
							<CustomTextField name={"calendari_id"} register={register} label="Enllaç Google Calendar" required />

							<Grid container spacing={2} mb={3}>
								<Grid item md={4} xs={12}>
									<CustomTextField name={"temps"} register={register} label="Temps (minuts)" required type="number" />
								</Grid>
								<Grid item md={4} xs={12}>
									<CustomTextField name={"dificultat"} register={register} label="Dificultat (1-5)" max={5} required type="number" />
								</Grid>
								<Grid item md={4} xs={12}>
									<CustomTextField name={"terror"} register={register} label="Terror (1-5)" max={5} required type="number" />
								</Grid>
							</Grid>
							<Divider />
							<Typography variant={"h4"} mt={2} mb={2}>
								Dies disponibilitat
							</Typography>
							<Grid spacing={2} container>
								{diesWeek.map((dia) => (
									<Grid item sm>
										<Badge
											text={moment(dia, "e").locale("ca").format("dddd")}
											set={changeDies}
											index={dia}
											select={dies?.filter((item) => item.dia === dia)[0]?.dia}
										/>
										{horesDia.map((hora) => (
											<Badge
												text={moment(hora, "HH:mm:ss").format("HH:mm") + " h"}
												set={changeHora}
												index2={dia}
												index={hora}
												select={dies?.filter((i) => i.dia === dia)[0]?.hores?.filter((item2) => item2 === hora)[0]}
											/>
										))}
									</Grid>
								))}
							</Grid>
							<Divider />
							<Typography variant={"h4"} mt={2} mb={2}>
								Preus
							</Typography>
							<Controller
								name={"pagament_complet"}
								control={control}
								render={({ field }) => (
									<FormControlLabel
										style={{ margin: 0 }}
										label="Els clients faràn el pagament complet"
										control={
											<Checkbox
												onChange={(e) => field.onChange(e.target.checked)}
												size={"small"}
												checked={field.value === "1" || field.value === true}
											/>
										}
									/>
								)}
							/>
							<Box my={3} />
							<Collapse in={!Number(watch("pagament_complet"))}>
								<TextField
									label="Paga i senyal"
									type="number"
									InputProps={{
										endAdornment: <InputAdornment position="end">€</InputAdornment>,
									}}
									{...register("preu_ps")}
								/>
							</Collapse>
							<Box my={3} />
							{fields.map((item, index) => (
								<Stack key={item?.id} mb={2} spacing={2} direction="row">
									<TextField label="Persones" type="number" {...register(`preus.${index}.persones`)} />
									<TextField
										label="Preu"
										type="number"
										InputProps={{
											endAdornment: <InputAdornment position="end">€</InputAdornment>,
										}}
										{...register(`preus.${index}.preu`)}
									/>
									<IconButton size="small" onClick={() => remove(index)}>
										<DeleteOutline size={15} />
									</IconButton>
								</Stack>
							))}
							<Box textAlign="center">
								<IconButton
									onClick={() =>
										append({
											preu: null,
											persones: null,
										})
									}
									color="primary"
								>
									<Add />
								</IconButton>
								<IconButton onClick={() => replace([])}>
									<DeleteForever />
								</IconButton>
							</Box>
						</Box>
					</Loading>
					<Stack direction={"row"} spacing={2}>
						<CustomButton title="Tancar" fullWidth danger onClick={() => setOpen(false)} />
						<CustomButton type="submit" title="Guardar" fullWidth loading={loading} />
					</Stack>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default ScaperoomAddDialog;
