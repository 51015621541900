import { Add } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import CartaCategoriaDialogAdd from "./CartaCategoriaDialogAdd";
import CartaCategoriaItem from "./CartaCategoriaItem";
import CartaDialogAdd from "./CartaDialogAdd";

function CartaAdmin() {
	const [cartes, setCartes] = useState([]);
	const [open, setOpen] = useState(false);
	const [openCarta, setOpenCarta] = useState(false);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(0);
	const [select, setSelect] = useState();
	const [selectCarta, setSelectCarta] = useState();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("carta_categories");
			setCartes(result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	const obrir = () => {
		setSelect("");
		setOpen(true);
	};

	return (
		<PageAdmin
			title="Carta"
			button={
				<Tooltip title="Afegir categoria carta">
					<IconButton onClick={obrir}>
						<Add />
					</IconButton>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<Grid spacing={3} container>
					{cartes?.map((item) => (
						<>
							<CartaCategoriaItem
								item={item}
								setSelect={setSelect}
								setOpen={setOpen}
								setUpdate={setUpdate}
								openCarta={openCarta}
								setOpenCarta={setOpenCarta}
								setSelectCarta={setSelectCarta}
							/>
						</>
					))}
				</Grid>
			</Loading>
			<CartaCategoriaDialogAdd setUpdate={setUpdate} open={open} setOpen={setOpen} cat={select} ubicacio={1} />

			<CartaDialogAdd setUpdate={setUpdate} open={openCarta} setOpen={setOpenCarta} carta={selectCarta} catId={select?.id} setCarta={setSelectCarta} />
		</PageAdmin>
	);
}

export default CartaAdmin;
