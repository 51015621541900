import {
	Dialog,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { deleteElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import theme from "../../../theme";

function CalendarDeleteDialog({
	restriccio,
	open,
	setOpen,
	loading,
	setLoading,
	setUpdate,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setOpen(false);
	};

	const deleteRestriccio = async () => {
		setLoading(true);
		const { message } = await deleteElement("restriccions", restriccio.id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Eliminar restricció
				</Box>
			</DialogTitle>
			<DialogContent>
				<Typography variant="h4" my={4}>
					{moment(restriccio?.start).format("LLL")}
				</Typography>
				<Stack direction={"row"} spacing={2}>
					<CustomButton
						title="Tancar"
						fullWidth
						onClick={() => setOpen(false)}
					/>
					<CustomButton
						onClick={() => deleteRestriccio()}
						title="Eliminar"
						fullWidth
						danger
						loading={loading}
					/>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

export default CalendarDeleteDialog;
