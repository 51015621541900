import { Add } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import MaterialAddDialog from "./MaterialAddDialog";
import MaterialItem from "./MaterialItem";

export default function ProductesAdmin() {
	const [materials, setMaterials] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [update, setUpdate] = useState(0);
	const [material, setMaterial] = useState();

	useEffect(() => {
		const obtenir = async () => {
			const materials = await getElements("materials");
			setMaterials(materials.result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	const obrir = () => {
		setMaterial([]);
		setOpen(true);
	};

	return (
		<PageAdmin
			title="Productes"
			button={
				<Tooltip title="Afegir producte">
					<IconButton onClick={obrir}>
						<Add />
					</IconButton>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<Grid container spacing={2}>
					{materials?.map((hab) => (
						<MaterialItem material={hab} setMaterial={setMaterial} setOpen={setOpen} />
					))}
				</Grid>
			</Loading>
			<MaterialAddDialog open={open} setOpen={setOpen} setUpdate={setUpdate} material={material} setLoading={setLoading} loading={loading} />
		</PageAdmin>
	);
}
