import { Box, CircularProgress, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	loading: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 0,
		height: 800,
	},
}));

export default function LoadingPublic({ loading, children }) {
	const classes = useStyles();
	return (
		<Box>
			{!loading ? (
				<Fade in={!loading} timeout={{ enter: 600, exit: 450 }}>
					<div>{children}</div>
				</Fade>
			) : (
				<Box className={classes.loading}>
					<Fade in={loading}>
						<CircularProgress color="secondary" />
					</Fade>
				</Box>
			)}
		</Box>
	);
}
