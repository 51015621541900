import { Close } from "@mui/icons-material";
import { Checkbox, Dialog, DialogContent, DialogTitle, Divider, Fab, FormControlLabel, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import paper from "../../../assets/img/paper-popup.png";
import { makeStyles } from "@mui/styles";
import "../../../theme/calStyle.css";
import "moment/locale/es";
import "moment/min/moment-with-locales";
import { useNavigate } from "react-router";
import theme from "../../../theme";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomButton from "../../../components/elements/CustomButton";
import CustomTextField from "../../../components/elements/CustomTextField";
import { createCompraTicket, getElements } from "../../../API/API";

const schemaTorneig = yup.object({
	nom: yup.string().required("El nombre es obligatorio"),
	telefon: yup.string().required("El teléfono es obligatorio"),
	email: yup.string().email("No es un E-mail válido").required("El e-mail es obligatorio"),
	poblacio: yup.string().required("La población es obligatoria"),
});

export default function DialogComprarTicket({ select, open, setOpen }) {
	const [preu, setPreu] = useState(select);
	const [loading, setLoading] = useState(false);
	const [openP, setOpenP] = useState(false);
	const [limit, setLimit] = useState(0);
	const matches = useMediaQuery("(min-width:960px)");
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const navigate = useNavigate();
	const [frame, setFrame] = useState();

	useEffect(() => {
		setPreu(select);

		const getNumTickets = async () => {
			const data = await getElements("getTicketsDisponibles");
			setLimit(data?.result);
		};
		open && getNumTickets();
	}, [open]);

	const handleClose = () => {
		setPreu();
		setOpen(false);
	};

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaTorneig),
	});

	const comprar = async (values) => {
		values.preu = preu;
		setLoading(true);
		const { message, frame, variant, id } = await createCompraTicket(values);
		if (frame !== "") {
			setFrame(frame?.URL_REDIRECT);
			setOpenP(true);
		} else {
			navigate("/reserves/compra-tickets/UrlOk?r=" + id);
		}
		enqueueSnackbar(message, {
			variant: variant,
		});
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
					background: `url(${paper})`,
					backgroundRepeat: "round",
					backgroundSize: "cover",
					padding: matches ? 30 : 0,
				},
			}}
			maxWidth="lg"
			fullScreen={!matches}
		>
			<DialogContent>
				<Box className={classes.close}>
					<Fab classesName={classes.closeButton} size="small" color="secondary" onClick={handleClose}>
						<Close sx={{ fontSize: 30 }} />
					</Fab>
				</Box>
				<Typography variant="h6" pt={3} pb={1}>
					Comprar participaciones
				</Typography>
				<Divider sx={{ border: 1 }} />
				{Number(limit) > 12 ? (
					<Grid container spacing={2} mb={4} mt={2}>
						<Grid item md={4}>
							<Box className={[classes.participacio, preu === 1 ? "active" : ""]} onClick={() => setPreu(1)}>
								<Typography variant="h1" color={theme.color.secondary}>
									1€
								</Typography>
								<Typography variant="body2" color={theme.color.secondary} style={{ whiteSpace: "nowrap" }}>
									2 participaciones
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4}>
							<Box className={clsx(classes.participacio, preu === 3 ? "active" : "")} onClick={() => setPreu(3)}>
								<Typography variant="h1" color={theme.color.secondary}>
									3€
								</Typography>
								<Typography variant="body2" color={theme.color.secondary} style={{ whiteSpace: "nowrap" }}>
									7 participaciones
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4}>
							<Box className={clsx(classes.participacio, preu === 5 ? "active" : "")} onClick={() => setPreu(5)}>
								<Typography variant="h1" color={theme.color.secondary}>
									5€
								</Typography>
								<Typography variant="body2" color={theme.color.secondary} style={{ whiteSpace: "nowrap" }}>
									12 participaciones
								</Typography>
							</Box>
						</Grid>
					</Grid>
				) : (
					<Typography variant="body1">No quedan participaciones</Typography>
				)}

				<form onSubmit={handleSubmit(comprar)}>
					<Box my={6}>
						<Typography pb={1} variant="h6">
							RELLENA LA INFORMACIÓN
						</Typography>
						<Divider
							sx={{
								borderWidth: 1,
								borderColor: theme.color.background,
							}}
						/>
						<Grid container spacing={matches ? 3 : 0}>
							<Grid item md={6} xs={12}>
								<CustomTextField register={register} name="nom" label="Nombre y apellidos" errors={errors.nom} />
							</Grid>
							<Grid item md={6} xs={12}>
								<CustomTextField register={register} name="email" label="E-mail" errors={errors.email} />
							</Grid>
						</Grid>
						<Grid container spacing={matches ? 3 : 0} mb={3}>
							<Grid item md={6} xs={12}>
								<CustomTextField register={register} name="telefon" label="Telèfon" errors={errors.telefon} />
							</Grid>
							<Grid item md={6} xs={12}>
								<CustomTextField register={register} name="poblacio" label="Población" errors={errors.poblacio} />
							</Grid>
						</Grid>
					</Box>

					<Box my={4}>
						<Typography pb={1} variant="h6">
							PAGO
						</Typography>
						<Divider
							sx={{
								borderWidth: 1,
								borderColor: theme.color.background,
							}}
						/>
						<Box display="flex" justifyContent={"space-between"} my={1}>
							<Typography variant="h3">Participaciones:</Typography>
							<Typography variant="h3">{preu === 1 ? "2" : preu === 3 ? "7" : "12"} tickets</Typography>
						</Box>
						<Box display="flex" justifyContent={"space-between"} my={1}>
							<Typography variant="h3">A pagar:</Typography>
							<Typography variant="h2">{preu} €</Typography>
						</Box>

						<Divider />
						<Box display={matches ? "flex" : "block"} justifyContent={"space-between"} alignItems="center" my={2}>
							<Box my={2}>
								<FormControlLabel
									control={<Checkbox required />}
									label={
										<Box>
											<Typography variant="body1" display={"flex"}>
												He leído y acepto la{" "}
												<Box
													ml={1}
													style={{
														textDecoration: "underline",
													}}
													onClick={() => navigate("/legal/politica-privacitat")}
												>
													Política de Privacidad
												</Box>
											</Typography>
										</Box>
									}
								/>
							</Box>
							{Number(limit) > 12 && <CustomButton title="Comprar" type="submit" loading={loading} fullWidth={!matches} />}
						</Box>
					</Box>
				</form>
			</DialogContent>
			<Dialog open={openP} maxWidth="lg">
				<DialogTitle>
					<Typography variant="h2" textAlign={"center"}>
						Pago con tarjeta
					</Typography>
				</DialogTitle>
				{frame && (
					<iframe
						src={frame}
						title="Pagament"
						height={300}
						width={matches ? 500 : "auto"}
						style={{
							paddingBottom: 50,
							border: "none",
						}}
					/>
				)}
			</Dialog>
		</Dialog>
	);
}

const useStyles = makeStyles((theme) => ({
	img: {
		width: 50,
		height: 50,
	},
	close: {
		position: "absolute",
		top: 20,
		right: 20,
		zIndex: 100,
	},
	closeButton: {
		backgroundColor: theme.color.secondary,
	},
	participacio: {
		backgroundColor: theme.color.primary,
		padding: 10,
		textAlign: "center",
		transition: "0.2s",
		cursor: "pointer",
		borderRadius: 10,
		"& .MuiTypography-root": {
			color: theme.color.secondary,
			transform: "scale(1.05)",
		},
		"&:hover": {
			backgroundColor: theme.color.third,
			"& .MuiTypography-root": {
				color: theme.color.primary,
			},
		},
		"&.active": {
			transform: "scale(1.05)",
			backgroundColor: theme.color.third,
			"& .MuiTypography-root": {
				color: theme.color.primary,
			},
		},
		"-webkit-mask-image":
			"radial-gradient(circle 10px at 0 50%, transparent 0, transparent 20px, black 21px), radial-gradient(circle 10px at 100% 50%, transparent 0, transparent 20px, black 21px)",
		"-webkit-mask-composite": "destination-in",
	},
}));
