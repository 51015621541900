import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import clsx from "clsx";

export default function Badge({
	text,
	index,
	set,
	select,
	foto,
	disabled,
	index2 = null,
}) {
	const classes = useStyles();

	return (
		<Box
			className={clsx(
				index === select && classes.selected,
				disabled ? classes.disabled : classes.pill
			)}
			mb={2}
			onClick={() => !disabled && set(index, index2)}
		>
			{foto && (
				<Avatar
					src={"https://api.insomniacorp.com/storage/" + foto}
					variant="square"
					sx={{ width: "100%", height: 80 }}
				/>
			)}
			<Typography
				style={{ transition: "0.2s" }}
				textAlign={foto ? "center" : "left"}
				pr={1}
				pl={1}
			>
				{text}
			</Typography>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	pill: {
		transition: "0.2s",
		border: "2px solid black",
		display: "table",
		cursor: "pointer",
		width: "100%",
		"& img": {
			transition: "0.2s",
		},
		"&:hover": {
			backgroundColor: theme.color.third + "90",
			"& img": {
				scale: 1.1,
			},
		},
	},
	selected: {
		backgroundColor: theme.color.third + "90",
		"& .MuiTypography-root": {
			color: "white",
		},
	},
	disabled: {
		border: "2px solid black",
		display: "table",
		width: "100%",
		backgroundColor: "#1a191820",
		cursor: "not-allowed",
		"& .MuiTypography-root": {
			color: "#1a191840",
		},
	},
}));
