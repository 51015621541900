import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../../theme";
import CustomButton from "../../../components/elements/CustomButton";
import Loading from "../../../components/layouts/Loading";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { updateElement } from "../../../API/API";

function JocsDialogEdit({ setUpdate, open, setOpen, joc }) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, register, setValue, reset } = useForm();

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	useEffect(() => {
		setValue("nom", joc?.nom);
		setValue("desc", joc?.desc);
	}, [joc]);

	const guardar = async (values) => {
		setLoading(true);
		const { message } = await updateElement("jocs", joc?.id, values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate((prev) => prev + 1);
		handleClose();
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Editar esdeveniment especial
				</Box>
			</DialogTitle>
			<DialogContent>
				<Loading loading={loading}>
					<form onSubmit={handleSubmit(guardar)}>
						<Box my={3}>
							<TextField
								{...register("nom")}
								fullWidth
								label="Nom"
							/>
							<Box my={4} />
							<TextField
								{...register("desc")}
								fullWidth
								label="Descripció"
								multiline
								rows={5}
							/>
						</Box>
						<Stack spacing={2} direction="row">
							<CustomButton
								title={"Tancar"}
								danger
								fullWidth
								onClick={() => setOpen(false)}
							/>
							<CustomButton
								type="submit"
								title={"Guardar"}
								fullWidth
								loading={loading}
							/>
						</Stack>
					</form>
				</Loading>
			</DialogContent>
		</Dialog>
	);
}

export default JocsDialogEdit;
