import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, Select, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { changeState, changeStateCompraTicket } from "../../API/API";

const useStyles = makeStyles((theme) => ({
	icon: { padding: 5 },
	badge: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingRight: 10,
		paddingLeft: 10,
		borderRadius: 15,
		color: "white",
		textAlign: "center",
		transition: "0.2s",
		cursor: "pointer",
		"&:hover": {
			boxShadow: "0px 0px 5px 0px " + theme.palette.background.main,
		},
	},
	badgeNL: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingRight: 10,
		paddingLeft: 10,
		borderRadius: 15,
		color: "white",
		textAlign: "center",
	},
	espera: {
		backgroundColor: "#ababab80",
	},
	completada: {
		backgroundColor: "#8bc34a",
	},
	cancelada: {
		backgroundColor: "#ff1744",
	},
	pagada: {
		backgroundColor: "#03a9f4",
	},
	interna: {
		backgroundColor: theme.color.secondary,
	},
}));

const Estat = ({ estat, change, id, ct = false }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [newState, setNewState] = useState();
	const [newLabel, setNewLabel] = useState(estat?.nom);
	const [style, setStyle] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setStyle(
			estat?.nom === "Pagada"
				? classes.pagada
				: estat?.nom === "Completada"
				? classes.completada
				: estat?.nom === "En espera"
				? classes.espera
				: estat?.nom === "Interna"
				? classes.interna
				: classes.cancelada
		);
		setNewLabel(estat?.nom);
	}, [estat]);

	useEffect(() => {
		setStyle(
			newLabel === "Pagada"
				? classes.pagada
				: newLabel === "Completada"
				? classes.completada
				: newLabel === "En espera"
				? classes.espera
				: newLabel === "Interna"
				? classes.interna
				: classes.cancelada
		);
	}, [newLabel]);

	const canvi = async (val) => {
		setLoading(true);
		const { message } = ct ? await changeStateCompraTicket(id, val.target.value) : await changeState(id, val.target.value);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setNewState(val.target.value);
		setNewLabel(val.target.selectedOptions[0].innerHTML);
		setLoading(false);
	};

	return (
		<div>
			{change ? (
				<div>
					<Tooltip title="Clica per canviar d'estat">
						<Box className={clsx(classes.badge, style)} onClick={() => setOpen(true)}>
							{loading ? (
								<Box mx={3} my={0}>
									<CircularProgress size={13} />
								</Box>
							) : (
								<Typography
									variant="body1"
									style={{
										color: "white",
										fontSize: 14,
										fontFamily: "Rokkitt",
									}}
								>
									{newLabel}
								</Typography>
							)}
						</Box>
					</Tooltip>
					<Dialog
						open={open}
						onClose={() => setOpen(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						disableScrollLock
						maxWidth="md"
					>
						<DialogTitle>Canvi d'estat - {ct ? "Compra ticket" : "Reserva"}</DialogTitle>
						<DialogContent>
							<Box>
								<FormControl fullWidth style={{ marginTop: 20, width: 300 }}>
									<InputLabel shrink={true} htmlFor={"estat_id"}>
										{"Nou estat"}
									</InputLabel>
									<Select
										native
										label={"Nou estat"}
										name={"estat_id"}
										value={newState}
										defaultValue={estat.id}
										notched={true}
										onChange={(val) => {
											canvi(val);
											setOpen(false);
										}}
									>
										<option value={1}>Interna</option>
										<option value={2}>Pagada</option>
										<option value={3}>Completada</option>
										<option value={4}>Cancel·lada</option>
										<option value={5}>En espera</option>
									</Select>
								</FormControl>
								<Box my={2} />
							</Box>
						</DialogContent>
					</Dialog>
				</div>
			) : (
				<Box className={clsx(classes.badgeNL, style)}>
					<Typography
						variant="body1"
						style={{
							color: "white",
							fontSize: 14,
							fontFamily: "Rokkitt",
						}}
					>
						{newLabel}
					</Typography>
				</Box>
			)}
		</div>
	);
};

export default Estat;
