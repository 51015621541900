import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment/min/moment-with-locales";
import TRANSLATIONS_CA from "./translations/ca.json";
import TRANSLATIONS_ES from "./translations/es.json";
import TRANSLATIONS_EN from "./translations/en.json";

const resources = {
	ca: {
		translation: TRANSLATIONS_CA,
	},
	es: {
		translation: TRANSLATIONS_ES,
	},
	en: {
		translation: TRANSLATIONS_EN,
	},
};

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: "es",
		fallbackLng: "es",
		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});
i18n.moment = moment;
i18n.moment.locale(i18n.language);
export default i18n;
