import React, { useState } from "react";
import PropTypes from "prop-types";
import navBarStyle from "./NavBarStyle";
import { Typography, MenuItem, Collapse } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

const NavBarItem = ({ className, to, title, child, handleClose, state, hash, ...rest }) => {
	const classes = navBarStyle();
	const [open, setOpen] = useState(false);

	return child === undefined ? (
		<MenuItem
			className={classes.menuItem}
			to={{
				pathname: to,
				state: state,
				hash: hash,
			}}
			state={{ state }}
			underline="none"
			component={RouterLink}
			onClick={handleClose}
			end
		>
			<Typography variant="menu" className={classes.title}>
				{title}
			</Typography>
		</MenuItem>
	) : (
		<MenuItem className={classes.menuItem} underline="none">
			<Typography variant="menu" className={classes.title} onClick={() => setOpen((prev) => !prev)}>
				{title}
			</Typography>
			<Collapse in={open}>
				{child?.map((item) => (
					<NavBarItem to={item.to} key={item.title} title={item.title} handleClose={handleClose} state={item.state} />
				))}
			</Collapse>
		</MenuItem>
	);
};

NavBarItem.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	icon: PropTypes.elementType,
	title: PropTypes.string,
};

export default NavBarItem;
