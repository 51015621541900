import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../../theme";
import CustomButton from "../../../components/elements/CustomButton";
import { refundCompraTicket } from "../../../API/API";
import { useSnackbar } from "notistack";

function DialogRefundCompraTicket({ setUpdate, open, setOpen, reserva }) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setOpen(false);
	};

	const retornar = async () => {
		setLoading(true);
		const { message } = await refundCompraTicket(reserva?.id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate((prev) => prev + 1);
		setOpen(false);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="sm"
		>
			<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
				<Box display="flex" justifyContent={"space-between"}>
					Devolució concepte
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box my={3}>
					<Typography variant="h3" mt={4}>
						Segur que vols fer la devolució?
					</Typography>
					<Typography mb={4}>Els tickets passaran a no tenir un client i estaran disponibles.</Typography>
				</Box>
				<CustomButton onClick={retornar} title={"Retornar"} fullWidth loading={loading} />
			</DialogContent>
		</Dialog>
	);
}

export default DialogRefundCompraTicket;
