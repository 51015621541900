import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Stack,
	TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addElement, updateElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import theme from "../../../theme";

function CartaDialogAdd({ open, setOpen, carta, setUpdate, catId, setCarta }) {
	const { setValue, register, reset, handleSubmit } = useForm();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		reset(carta);
	}, [open]);

	const handleClose = () => {
		setCarta("");
		reset({ titol: "" });
		setOpen(false);
	};

	const guardar = async (values) => {
		setLoading(true);
		const { message } = await updateElement("carta", carta?.id, values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	const crear = async (values) => {
		values.carta_categoria_id = catId;
		// console.log(values)
		setLoading(true);
		const { message } = await addElement("carta", values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Afegir element carta
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit(carta ? guardar : crear)}>
					<Box mt={3}>
						<Grid spacing={2} container>
							<Grid item md={6}>
								<Box mb={3}>
									<TextField
										{...register("titol")}
										fullWidth
										label="Títol"
									/>
								</Box>
								<Box mb={3}>
									<TextField
										{...register("titol_es")}
										fullWidth
										label="Títol castellà"
									/>
								</Box>
								<Box mb={3}>
									<TextField
										{...register("titol_en")}
										fullWidth
										label="Títol anglès"
									/>
								</Box>
							</Grid>
							<Grid item md={6}>
								{" "}
								<Box mb={3}>
									<TextField
										{...register("subtitol")}
										fullWidth
										label="Subtitol"
									/>
								</Box>
								<Box mb={3}>
									<TextField
										{...register("subtitol_es")}
										fullWidth
										label="Subtitol castellà"
									/>
								</Box>
								<Box mb={3}>
									<TextField
										{...register("subtitol_en")}
										fullWidth
										label="Subtitol anglès"
									/>
								</Box>
							</Grid>
						</Grid>

						<Divider />
						<Box mb={3} mt={2}>
							<TextField
								{...register("preu")}
								fullWidth
								label="Preu amb símbol € o €/u"
							/>
						</Box>
						<Stack direction={"row"} spacing={2}>
							<CustomButton
								title="Tancar"
								fullWidth
								danger
								onClick={handleClose}
							/>
							<CustomButton
								type="submit"
								title={carta ? "Guardar" : "Crear"}
								fullWidth
								loading={loading}
							/>
						</Stack>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default CartaDialogAdd;
