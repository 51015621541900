import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "./Page";
import bg from "../../assets/img/fons-2.jpg";
import theme from "../../theme";

export function Error404() {
	const classes = useStyles();
	return (
		<Page title="Error 404">
			<Box className={classes.portada}>
				<Typography
					variant="h1"
					fontSize={150}
					color={theme.color.secondary}
				>
					404
				</Typography>
				<Typography color={theme.color.secondary}>
					La página no existe, vete ya.
				</Typography>
			</Box>
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 250,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 400,
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
}));
