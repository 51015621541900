import { Avatar, Container, createMuiTheme, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { enviarContacte, getElement } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import Loading from "../../../components/layouts/Loading";
import { Cartes } from "../../../components/elements/Cartes";
import img1 from "../../../assets/img/hotel/salo-1.jpg";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import theme from "../../../theme";
import { ReactComponent as Lloc } from "../../../assets/icons/icon-lloc.svg";
import { ReactComponent as Mail } from "../../../assets/icons/icon-mail.svg";
import { ReactComponent as Tel } from "../../../assets/icons/icon-tel.svg";

function Contacte() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { register, reset, handleSubmit } = useForm();

	const enviar = async (values) => {
		setLoading(true);
		const { message } = await enviarContacte(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		reset();
		setLoading(false);
	};

	return (
		<Page title="Insomnia Corporation - Contacto">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Loading loading={loading}>
						<Grid spacing={10} container>
							<Grid item md={6} xs={12}>
								<Box p={4} className={matches ? classes.wrapImg : classes.wrapImgMobile}>
									<img src={img1} alt="Contacto" className={classes.img} />
								</Box>
							</Grid>
							<Grid item md={6} xs={12}>
								<Typography variant="h2" mb={3} color={theme.color.secondary}>
									Información
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
								<Box display="flex" alignItems={"center"} mt={3}>
									<Lloc width={25} />
									<a
										href="https://www.google.com/maps/place/Insomnia+Corporation,+escape+rooms,+Hotel+%26+Restaurant+%26+Bar/@42.1075324,1.8373452,17z/data=!3m1!4b1!4m8!3m7!1s0x12a5082dfbbd5765:0xa87cc03b6ccccc65!5m2!4m1!1i2!8m2!3d42.1075357!4d1.8418421"
										target="_blank"
										rel="noreferrer"
										style={{ textDecoration: "none" }}
									>
										<Typography ml={3} color={theme.color.secondary}>
											Drecera de Queralt, S/N, 08600 Berga, Barcelona
										</Typography>
									</a>
								</Box>
								<Box display="flex" alignItems={"center"} mt={3}>
									<Tel width={30} />
									<a href="tel:+34689153847" style={{ textDecoration: "none" }}>
										<Typography ml={3} color={theme.color.secondary}>
											+34 689 15 38 47
										</Typography>
									</a>
								</Box>
								<Box display="flex" alignItems={"center"} my={3}>
									<Mail width={30} />
									<a href="mailto:info@insomniacorp.com" style={{ textDecoration: "none" }}>
										<Typography ml={3} color={theme.color.secondary}>
											info@insomniacorp.com
										</Typography>
									</a>
								</Box>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
							</Grid>
						</Grid>
					</Loading>
				</Container>
			</Box>
		</Page>
	);
}

export default Contacte;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 280,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 300,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.color.secondary,
	},
}));
