import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../../../theme/TableStyle";
import TableOptions from "../../../components/columns/TableOptions";
import TicketsColumns from "../../../components/columns/TicketsColumns";
import Pill from "../../../components/elements/Pill";
import theme from "../../../theme";
import { Groups, LocalActivity } from "@mui/icons-material";

export default function TicketsAdmin() {
	const [tickets, setTickets] = useState([]);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(0);
	const options = TableOptions();
	const columns = TicketsColumns(tickets);

	useEffect(() => {
		const obtenir = async () => {
			const tickets = await getElements("tickets");
			setTickets(tickets.result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	return (
		<PageAdmin title="Tickets">
			<Loading loading={loading}>
				<Grid container spacing={2} mb={3}>
					<Grid item md={3} xs={12}>
						<Pill title={tickets?.length} icon={<LocalActivity />} label="Tickets totals" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill
							title={tickets?.filter((i) => i.client_id)?.length}
							icon={<LocalActivity />}
							label="Tickets comprats"
							color={theme.color.primary}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill
							title={tickets?.filter((i) => !i.client_id)?.length}
							icon={<LocalActivity />}
							label="Tickets disponibles"
							color={theme.color.primary}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill
							title={
								Object.keys(
									tickets?.reduce((p, c) => {
										var name = c.client?.id;
										if (!p.hasOwnProperty(name)) {
											p[name] = 0;
										}
										p[name]++;
										return p;
									}, {})
								).length - 1
							}
							icon={<Groups />}
							label="Nº de clients"
							color={theme.color.primary}
						/>
					</Grid>
				</Grid>
				<ThemeProvider theme={getMuiTheme()}>
					<MUIDataTable data={tickets} columns={columns} options={options} />
				</ThemeProvider>
			</Loading>
		</PageAdmin>
	);
}
