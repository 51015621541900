import {
	Box,
	Checkbox,
	Collapse,
	FormControlLabel,
	Grid,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { createReservaAdmimn } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import CustomSelect from "../../../components/elements/CustomSelect";
import CustomTextField from "../../../components/elements/CustomTextField";
import { esJoc } from "../../../components/Utils";

const hores = [
	{ id: "16:00:00", nom: "16.00 h" },
	{ id: "18:00:00", nom: "18.00 h" },
	{ id: "20:00:00", nom: "20.00 h" },
	{ id: "22:00:00", nom: "22.00 h" },
];

function AddReserva({
	formReserva,
	productes,
	setLoading,
	setUpdate,
	handleClose,
	loading,
	setOpen,
	dataJoc,
}) {
	const [showHores, setShowHores] = useState(false);
	const [preu, setPreu] = useState("-");
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		formReserva.setValue("joc", esJoc(formReserva.watch("data"), dataJoc));
	}, [formReserva.watch("data")]);

	useEffect(() => {
		const subscription = formReserva.watch((value) => {
			const producte = productes.filter(
				(item) => item.id === value.producte_id
			)[0];

			setShowHores(producte?.tipus_id === "1");

			if (value.quantitat !== "") {
				const preu = producte?.preus?.filter(
					(item) =>
						Number(item?.persones) === Number(value.quantitat) &&
						item?.joc === (value.joc ? "1" : "0")
				)[0]?.preu;

				setPreu(value.quantitat * preu);
			} else {
				setPreu("-");
			}
		});
		return () => subscription.unsubscribe();
	}, [formReserva.watch]);

	useEffect(() => {
		formReserva.setValue("preu", preu);

		const producte = productes.filter(
			(item) => item.id === formReserva.watch("producte_id")
		)[0];
		if (producte?.habitacio) {
			formReserva.setValue(
				"calendari_id",
				producte?.habitacio.calendari_id
			);
			formReserva.setValue("tipus", 2);
		} else {
			formReserva.setValue("joc", false);
			formReserva.setValue(
				"calendari_id",
				producte?.scaperoom.calendari_id
			);
			formReserva.setValue("tipus", 1);
		}
	}, [preu]);

	const renderPersones = () => {
		let result = [];
		const producte = productes.filter(
			(item) => item.id === formReserva.watch("producte_id")
		)[0];
		if (producte?.habitacio) {
			for (
				let index = producte?.habitacio.min_p;
				index <= producte?.habitacio.max_p;
				index++
			) {
				result.push({ id: index, nom: index + " persones" });
			}
		} else {
			for (
				let index = producte?.scaperoom.min_p;
				index <= producte?.scaperoom.max_p;
				index++
			) {
				result.push({ id: index, nom: index + " persones" });
			}
		}
		return result;
	};

	const crear = async (values) => {
		// console.log(values);
		setLoading(true);
		const { message } = await createReservaAdmimn(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		handleClose();
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<form onSubmit={formReserva.handleSubmit(crear)}>
			<Box pb={5}>
				<CustomSelect
					name="producte_id"
					control={formReserva.control}
					register={formReserva.register}
					label="Producte"
					list={productes}
					defaultValue={""}
					required
				/>
				<Collapse in={showHores}>
					<CustomSelect
						name="hora"
						control={formReserva.control}
						register={formReserva.register}
						label="Hora"
						list={hores}
						defaultValue="16:00:00"
					/>
				</Collapse>
				<CustomSelect
					name="quantitat"
					control={formReserva.control}
					register={formReserva.register}
					label="Persones"
					list={renderPersones()}
					defaultValue={""}
					required
				/>
				<Grid spacing={2} container>
					<Grid item md={6} xs={12}>
						<CustomTextField
							name="nom"
							type="text"
							label="Nom i cognoms"
							register={formReserva.register}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<CustomTextField
							name="email"
							type="text"
							label="E-mail"
							register={formReserva.register}
						/>
					</Grid>
				</Grid>
				<Grid spacing={2} container>
					<Grid item md={6} xs={12}>
						<CustomTextField
							name="telefon"
							type="text"
							label="Telèfon"
							register={formReserva.register}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<CustomTextField
							name="poblacio"
							type="text"
							label="Població"
							register={formReserva.register}
						/>
					</Grid>
				</Grid>
				<Collapse in={!showHores}>
					<Controller
						name={"joc"}
						control={formReserva.control}
						render={({ field }) => (
							<Tooltip title={"Joc terror"}>
								<FormControlLabel
									style={{ margin: 0 }}
									label="Amb terror"
									control={
										<Checkbox
											onChange={(e) =>
												field.onChange(e.target.checked)
											}
											size={"small"}
											checked={
												field.value === "1" ||
												field.value === true
											}
										/>
									}
								/>
							</Tooltip>
						)}
					/>
				</Collapse>
				<Box display="flex" justifyContent={"space-between"}>
					<Typography variant="h3" mt={2}>
						Total:
					</Typography>
					<Typography variant="h2" mt={2}>
						{preu !== "-" ? Math.round(preu * 100) / 100 : "-"} €
					</Typography>
				</Box>
			</Box>
			<Stack direction={"row"} spacing={2}>
				<CustomButton
					title="Cancel·lar"
					fullWidth
					danger
					onClick={() => setOpen(false)}
				/>
				<CustomButton
					type="submit"
					title="Crear"
					fullWidth
					loading={loading}
				/>
			</Stack>
		</form>
	);
}

export default AddReserva;
