import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
// import { ReactComponent as Icon } from "../../assets/logo_blanc.svg";
import hotel from "../../../assets/img/hotel.jpg";
import krugger from "../../../assets/img/krugger.png";
import bg from "../../../assets/img/fons-2.jpg";
import theme from "../../../theme";
import { getElements } from "../../../API/API";
import ScapeItemPublic from "./ScapeItemPublic";
import PastillaItem from "../../../components/elements/PastillaItem";
import DialogReserva from "./DialogReserva";
import LoadingPublic from "../../../components/layouts/LoadingPublic";
import DialogReservaHotel from "./DialogReservaHotel";
import DialogReservaHotelKrugger from "./DialogReservaHotelKrugger";

function Reserves() {
	const classes = useStyles();
	const [scapes, setScapes] = useState([]);
	const [select, setSelect] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [openHotel, setOpenHotel] = useState(false);
	const [openHotelKrugger, setOpenHotelKrugger] = useState(false);

	useEffect(() => {
		const obtenir = async () => {
			const scapes = await getElements("scaperooms");
			setScapes(scapes.result);
			setTimeout(() => setLoading(false), 500);
		};
		obtenir();
	}, []);

	return (
		<Page title="Insomnia Corp. - Reserves">
			<Box className={classes.portada}>
				<Container maxWidth={"md"}>
					<Box display={"flex"} justifyContent="center" flexDirection={"column"} alignItems="center">
						<Typography variant="h1" color="secondary" mb={1}>
							Reservas
						</Typography>
						<Divider color={theme.color.secondary} sx={{ width: "60%", textAlign: "center" }} />
						<LoadingPublic loading={loading}>
							<Grid mt={6} spacing={10} container mb={10} display="flex">
								{scapes?.map((scape) => (
									<ScapeItemPublic scape={scape} setOpen={setOpen} setSelect={setSelect} />
								))}
							</Grid>
						</LoadingPublic>
						<Divider color={theme.color.secondary} sx={{ width: "60%", textAlign: "center" }} />
						<Box my={10}>
							<Grid container spacing={10} display="flex" justifyContent={"center"}>
								<PastillaItem
									nom="Insomnia Hotel"
									desc="El único Hotel del mundo en el que cada habitación es una escape Room."
									foto={hotel}
									setOpen={setOpenHotel}
									link={"/habitacions"}
								/>
								<PastillaItem
									nom="El juego de los Krugger"
									desc="El único Hotel del mundo en el que cada habitación es una escape Room."
									foto={krugger}
									setOpen={setOpenHotelKrugger}
									link={"/escapes/secreto-krugger"}
								/>
							</Grid>
						</Box>
					</Box>
				</Container>
			</Box>
			<DialogReserva open={open} setOpen={setOpen} select={select} scapes={scapes} setSelect={setSelect} />
			<DialogReservaHotel open={openHotel} setOpen={setOpenHotel} select={select} />
			<DialogReservaHotelKrugger open={openHotelKrugger} setOpen={setOpenHotelKrugger} select={select} />
		</Page>
	);
}

export default Reserves;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	reserves: {
		color: theme.color.primary,
		fontWeight: 700,
		textDecoration: "none",
	},
	senyal: {
		width: 140,
		marginBottom: 20,
		[theme.breakpoints.down("sm")]: {
			width: 100,
		},
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
}));
