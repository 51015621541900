import { Box, Checkbox, Collapse, FormControlLabel, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { addRestriccio, removeRestriccions } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import CustomSelect from "../../../components/elements/CustomSelect";

const hores = [
	{ id: "16:00:00", nom: "16.00 h" },
	{ id: "18:00:00", nom: "18.00 h" },
	{ id: "20:00:00", nom: "20.00 h" },
	{ id: "22:00:00", nom: "22.00 h" },
];

function AddRestriccio({ formRestriccio, productes, setLoading, setUpdate, handleClose, loading, setOpen }) {
	const [showHores, setShowHores] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const subscription = formRestriccio.watch((value) => {
			const producte = productes.filter((item) => item.id === value.producte_id)[0];
			setShowHores(producte?.tipus_id === "1");
		});
		return () => subscription.unsubscribe();
	}, [formRestriccio.watch, productes]);

	const crear = async (values) => {
		console.log(values);
		setLoading(true);
		if (values.desbloqueig) {
			const { message } = await removeRestriccions(values);
			enqueueSnackbar(message, {
				variant: "success",
			});
		} else {
			const { message } = await addRestriccio(values);
			enqueueSnackbar(message, {
				variant: "success",
			});
		}

		handleClose();
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<form onSubmit={formRestriccio.handleSubmit(crear)}>
			<Box pb={5}>
				<Box display="flex" flexDirection={"column"}>
					<Controller
						name={"sencer"}
						control={formRestriccio.control}
						render={({ field }) => (
							<FormControlLabel
								style={{ margin: 0 }}
								label="Bloquejar tots els productes"
								control={
									<Checkbox
										onChange={(e) => field.onChange(e.target.checked)}
										size={"small"}
										checked={field.value === "1" || field.value === true}
									/>
								}
							/>
						)}
					/>
					<Controller
						name={"hotel"}
						control={formRestriccio.control}
						render={({ field }) => (
							<FormControlLabel
								style={{ margin: 0 }}
								label="Bloquejar només hotel"
								control={
									<Checkbox
										onChange={(e) => field.onChange(e.target.checked)}
										size={"small"}
										checked={field.value === "1" || field.value === true}
									/>
								}
							/>
						)}
					/>

					<Controller
						name={"desbloqueig"}
						control={formRestriccio.control}
						render={({ field }) => (
							<FormControlLabel
								style={{ margin: 0 }}
								label="Desbloquejar tot"
								control={
									<Checkbox
										onChange={(e) => field.onChange(e.target.checked)}
										size={"small"}
										checked={field.value === "2" || field.value === true}
									/>
								}
							/>
						)}
					/>

					<Controller
						name={"desbloqueig_hotel"}
						control={formRestriccio.control}
						render={({ field }) => (
							<FormControlLabel
								style={{ margin: 0 }}
								label="Desbloquejar hotel"
								control={
									<Checkbox
										onChange={(e) => field.onChange(e.target.checked)}
										size={"small"}
										checked={field.value === "1" || field.value === true}
									/>
								}
							/>
						)}
					/>
				</Box>
				<CustomSelect
					name="producte_id"
					control={formRestriccio.control}
					register={formRestriccio.register}
					label="Producte"
					list={productes}
					defaultValue={""}
				/>
				<Collapse in={showHores}>
					<CustomSelect
						name="hora"
						control={formRestriccio.control}
						register={formRestriccio.register}
						label="Hora"
						list={hores}
						defaultValue={[]}
						multiple
					/>
				</Collapse>
			</Box>
			<Stack direction={"row"} spacing={2}>
				<CustomButton title="Canel·lar" fullWidth danger onClick={() => setOpen(false)} />
				<CustomButton type="submit" title="Crear" fullWidth loading={loading} />
			</Stack>
		</form>
	);
}

export default AddRestriccio;
