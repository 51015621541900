import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { animated, interpolate, useSprings } from "react-spring";

export function CartesHotel({ imatges }) {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:1160px)");
	const [scrollY, setScrollY] = useState(window.scrollY);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	const to = (i) => ({
		x: -1000,
		y: 0,
		rot: -10 + Math.random() * 20,
		delay: i * 100,
	});

	const from = (_i) => ({
		x: 0,
		rot: 0,
		y: -1000,
	});

	const [props] = useSprings(imatges.length, (i) => ({
		...to(i),
		from: from(i),
	}));

	const trans = (x, r, s, i) => ` rotateY(${r / 8}deg) rotateZ(${r}deg) translateX(${scrollY > i * 550 - 100 ? 0 : -1500}px)`;

	return (
		<Box>
			{props.map(({ x, y, rot, scale }, i) => (
				<animated.div
					className={classes.wrapImg}
					style={{
						transform: interpolate([x, rot, scale, i], trans),
					}}
				>
					{i * 500}
					<img src={imatges?.[i]?.foto} alt={"Insomnia Corp."} className={matches ? classes.img : classes.imgM} />
				</animated.div>
			))}
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	wrapImg: {
		position: "fixed",
		transition: "0.5s",
		zIndex: 0,
	},
	img: {
		zIndex: 0,
		width: 570,
		border: "4px solid " + theme.color.secondary,
	},
	imgM: {
		zIndex: 0,
		width: 450,
		border: "4px solid " + theme.color.secondary,
	},
}));
