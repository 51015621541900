import { CalendarMonth, CardMembership, Edit, Email, Person } from "@mui/icons-material";
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import Estat from "../../../components/elements/Estat";
import Pill from "../../../components/elements/Pill";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import theme from "../../../theme";
import ClientDialogEdit from "../reserves/ClientDialogEdit";
import DialogRefundCompraTicket from "./DialogRefundCompraTicket";

export default function CompraTicketSingle() {
	const classes = useStyles();
	const [reserva, setReserva] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [clientId, setClientId] = useState("");
	const [valueClient, setValueClient] = useState("");
	const [label, setLabel] = useState("");
	const [name, setName] = useState("");
	const [update, setUpdate] = useState(0);
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("compraTickets", id);
			setReserva(result);
			setLoading(false);
		};
		obtenir();
	}, [id, update]);

	const editClient = (id, label, name, value) => {
		setClientId(id);
		setLabel(label);
		setName(name);
		setValueClient(value);
		setOpenEdit(true);
	};

	const reenviar = async () => {
		setLoading(true);
		const { message } = await getElement("reenviar", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate(true);
		setLoading(false);
	};

	return (
		<PageAdmin title={"Reserva " + (reserva?.client.nom ?? "")}>
			<Loading loading={loading}>
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<Pill title={reserva?.unique_id} icon={<CardMembership />} label="ID" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12} onClick={() => navigate("/admin/clients/" + reserva?.client?.id)} style={{ cursor: "pointer" }}>
						<Pill title={reserva?.client.nom} icon={<Person />} label="Nom" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12} onClick={() => navigate("/admin/clients/" + reserva?.client?.id)} style={{ cursor: "pointer" }}>
						<Pill title={reserva?.client?.email} icon={<Email />} label="E-mail" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill title={moment(reserva?.created_at).format("LLL")} icon={<CalendarMonth />} label="Data creació" color={theme.color.primary} />
					</Grid>
				</Grid>
				<Grid spacing={2} container mt={2}>
					<Grid item md={8} xs={12}>
						<Box className={classes.bloc}>
							<Box display="flex" justifyContent={"space-between"} alignItems="center" mb={2}>
								<Typography variant="h3">Detalls comanda</Typography>
							</Box>
							<Divider />

							<Box m={2}>
								<Grid container spacing={4}>
									{reserva?.tickets?.map((tic) => (
										<Grid item md={2}>
											<Box className={classes.participacio}>
												<Typography variant="body2">Número</Typography>
												<Typography variant="h2">{tic?.numero}</Typography>
												<Typography variant="body2">Ticket</Typography>
											</Box>
										</Grid>
									))}
								</Grid>
							</Box>

							<Box m={2} display="flex" justifyContent={"space-between"}>
								<Box>
									<Typography variant="h3">TOTAL</Typography>
								</Box>
								<Box textAlign={"right"}>
									<Typography ml={2} variant="h2">
										{Number(reserva?.preu)} €
									</Typography>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item md={4} xs={12}>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Informació
							</Typography>
							<Divider />
							<Detall
								title="Nom"
								text={reserva?.client.nom}
								edit
								onClick={() => editClient(reserva?.client.id, "Nom", "nom", reserva?.client.nom)}
							/>
							<Detall
								title="Telèfon"
								text={reserva?.client.telefon}
								edit
								onClick={() => editClient(reserva?.client.id, "Telèfon", "telefon", reserva?.client.telefon)}
							/>
							<Detall
								title="E-mail"
								text={reserva?.client.email}
								edit
								onClick={() => editClient(reserva?.client.id, "E-mail", "email", reserva?.client.email)}
							/>
							<Detall
								title="Població"
								text={reserva?.client.poblacio}
								edit
								onClick={() => editClient(reserva?.client.id, "Població", "poblacio", reserva?.client.poblacio)}
							/>
							<Detall
								title="Adreça"
								text={reserva?.client.direccion}
								edit
								onClick={() => editClient(reserva?.client.id, "Adreça", "direccion", reserva?.client.direccion)}
							/>
							<Detall
								title="Província"
								text={reserva?.client.provincia}
								edit
								onClick={() => editClient(reserva?.client.id, "Província", "provincia", reserva?.client.provincia)}
							/>
							<Detall
								title="Còdi Postal"
								text={reserva?.client.codigo_postal}
								edit
								onClick={() => editClient(reserva?.client.id, "Codi Postal", "codigo_postal", reserva?.client.codigo_postal)}
							/>
							<Detall title="Estat" text={<Estat ct={true} estat={reserva?.estat} change id={reserva?.unique_id} />} />
						</Box>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Accions
							</Typography>
							<Divider />
							<Stack spacing={2} direction={"row"} mt={3}>
								<CustomButton onClick={() => reenviar()} fullWidth title={"Reenviar correus de confirmació"} />
								<CustomButton onClick={() => setOpen(true)} fullWidth title={"Efectuar devolució"} danger />
							</Stack>
						</Box>
					</Grid>
				</Grid>
			</Loading>
			<DialogRefundCompraTicket open={open} setOpen={setOpen} reserva={reserva} setUpdate={setUpdate} />
			<ClientDialogEdit id={clientId} name={name} label={label} setOpen={setOpenEdit} open={openEdit} setUpdate={setUpdate} value={valueClient} />
		</PageAdmin>
	);
}

function Detall({ title, text, edit, onClick }) {
	const classes = useStyles();
	return (
		<Box className={classes.detall} justifyContent={"space-between"}>
			<Box>
				<Typography variant="body1">{title}</Typography>
				<Typography variant="body2" color="black" letterSpacing={0}>
					{text}
				</Typography>
			</Box>
			{edit && (
				<IconButton onClick={onClick}>
					<Edit />
				</IconButton>
			)}
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
	},
	detall: {
		display: "flex",
		alignItems: "center",
		padding: 10,
	},
	linia: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	participacio: {
		backgroundColor: theme.color.primary,
		padding: 10,
		textAlign: "center",
		transition: "0.2s",
		borderRadius: 10,
		"& .MuiTypography-root": {
			color: theme.color.secondary,
			transform: "scale(1.05)",
		},
		"-webkit-mask-image":
			"radial-gradient(circle 8px at 0 50%, transparent 0, transparent 20px, black 21px), radial-gradient(circle 8px at 100% 50%, transparent 0, transparent 20px, black 21px)",
		"-webkit-mask-composite": "destination-in",
	},
}));
