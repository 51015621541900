import { RemoveRedEye } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import Estat from "../elements/Estat";

const ReservesColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "unique_id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "estat",
			label: "Estat",
			options: {
				filter: false,
				sort: false,
				sortOrder: "desc",
				customBodyRender: (value) => <Estat estat={value} />,
			},
		},
		{
			name: "estat.nom",
			label: "Estat",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				display: false,
			},
		},
		{
			name: "client.nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "preu",
			label: "Preu",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value + " €",
			},
		},
		{
			name: "pagat",
			label: "Pagat",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value + " €",
			},
		},
		{
			name: "reserva_elements",
			label: "Productes reservats",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					value?.map((item) =>
						item?.producte?.tipus_id === "1" || item?.producte?.tipus_id === "2" ? (
							<Chip
								label={
									item.quantitat +
									"p - " +
									item.producte.nom +
									" " +
									(item.producte.tipus_id === "1"
										? moment(item?.data).format("DD/MM/YYYY HH:mm") + " h"
										: moment(item?.data).format("DD/MM/YYYY"))
								}
								style={{
									marginRight: 5,
									marginBottom: 5,
									fontFamily: "Rokkitt",
								}}
							/>
						) : (
							<Chip
								label={item.quantitat + "u - " + item.producte.nom}
								style={{
									marginRight: 5,
									marginBottom: 5,
									fontFamily: "Rokkitt",
								}}
							/>
						)
					),
			},
		},
		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(`/admin/reserves/${activitats[dataIndex].unique_id}`, {
									state: activitats[dataIndex],
								})
							}
						>
							<RemoveRedEye />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default ReservesColumns;
