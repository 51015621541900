import { Container, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import theme from "../../../theme";
import { getElements } from "../../../API/API";
import LoadingPublic from "../../../components/layouts/LoadingPublic";
import DialogMaterial from "./DialogMaterial";
import MaterialItemPublic from "./MaterialItemPublic";
import CupoItemPublic from "./CupoItemPublic";
import { useLocation } from "react-router";
import logo from "../../../assets/img/logo-calavera-footer.png";
import bg_melodia from "../../../assets/img/paperh.png";
import Foto from "../../../components/elements/Foto";
import pastilla from "../../../assets/img/paper.png";

export default function Botiga() {
	const classes = useStyles();
	const [materials, setMaterials] = useState([]);
	const [select, setSelect] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const ref = useRef();

	useEffect(() => {
		if (location.hash === "#cupon") {
			if (materials.length > 0) {
				setTimeout(() => {
					ref?.current?.scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
		} else {
			window.scrollTo(0, 0);
		}
	}, [location, materials]);

	useEffect(() => {
		const obtenir = async () => {
			const materials = await getElements("materials");
			setMaterials(materials.result);
			setTimeout(() => setLoading(false), 500);
		};
		obtenir();
	}, []);

	return (
		<Page title="Insomnia Corp. - Tienda">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Box display={"flex"} justifyContent="center" flexDirection={"column"} alignItems="center">
						<Typography variant="h1" color="secondary" mb={1}>
							Tienda
						</Typography>
						<Divider color={theme.color.secondary} sx={{ width: "60%", textAlign: "center" }} />
					</Box>
					<LoadingPublic loading={loading}>
						<Grid mt={5} spacing={4} container pb={15}>
							{materials?.map((material) =>
								!Number(material?.material?.cupo) ? <MaterialItemPublic material={material} setOpen={setOpen} setSelect={setSelect} /> : ""
							)}
							<Grid item md={3} xs={12}>
								<Box className={classes.hab} onClick={() => window.open("https://info235362.wixsite.com/melodiamaldita")}>
									<Box
										className={"inbox"}
										sx={{
											transition: "0.2s",
										}}
										textAlign={"center"}
									>
										<img alt="Logo Insomnia" width={120} src={logo} />
									</Box>
									<Box mt={2}>
										<Typography variant="h6" mt={2} letterSpacing={0}>
											Juega gratis a nuestro Escape Room Online
										</Typography>
										<Typography variant="h2" mt={2} fontWeight={200}>
											0 €
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
						<Box display={"flex"} justifyContent="center" flexDirection={"column"} alignItems="center">
							<Divider color={theme.color.secondary} sx={{ width: "60%", textAlign: "center" }} />
						</Box>
						<Grid mt={5} spacing={4} container pb={15} sx={{ justifyContent: "center" }} id="cupon" ref={ref}>
							{materials?.map((material) =>
								Number(material?.material?.cupo) ? <CupoItemPublic material={material} setOpen={setOpen} setSelect={setSelect} /> : ""
							)}
						</Grid>
					</LoadingPublic>
				</Container>
			</Box>
			<DialogMaterial open={open} setOpen={setOpen} select={select} scapes={materials} setSelect={setSelect} />
			{/* <Box className={classes.melodia} onClick={() => window.open("https://info235362.wixsite.com/melodiamaldita")}>
				<img alt="Logo Insomnia" width={60} src={logo} />
				<Box ml={3}>
					<Typography variant="h3">Melodia maldita</Typography>
					<Typography>Juega gratis a nuestro Escape Room Online</Typography>
				</Box>
			</Box> */}
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
	melodia: {
		zIndex: 1000,
		position: "fixed",
		bottom: 0,
		right: 0,
		margin: 20,
		background: `url(${bg_melodia})`,
		padding: 25,
		backgroundRepeat: "round",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		transition: "0.2s",
		"&:hover": {
			transform: "scale(1.05)",
			color: "red",
		},
	},
	hab: {
		background: `url(${pastilla})`,
		padding: 25,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		cursor: "pointer",
		"& :hover": {
			"&.inbox": {
				transform: "scale(1.1)",
			},
		},
	},
}));
