import axios from "axios";
import icsToJson from "ics-to-json";

const url = "https://api.insomniacorp.com/api/";
const API_KEY = "AIzaSyAl0BBV6NdNM9Y0Bec2bY_f1PcBbmzebCA";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
			}
		});
	return { message };
};

const logout = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "logout", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data;
				message = "400";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getElements = async (element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const updateElement = async (element, key, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + element + "/" + key, values, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			console.log(error);
		});
	return { message };
};

const addElement = async (element, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + element, values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const deleteElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.delete(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const addHabitacio = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("resum", values.resum);
	data.append("dies", values.dies);
	data.append("desc", values.desc);
	data.append("min_p", values.min_p);
	data.append("max_p", values.max_p);
	data.append("dificultat", values.dificultat);
	data.append("calendari_id", values.calendari_id);
	data.append("calendari_booking", values.calendari_booking ?? "");
	data.append("calendari_airbnb", values.calendari_airbnb ?? "");
	data.append("preus", JSON.stringify(values.preus));
	data.append("preu_ps", values.preu_ps);

	if (values?.foto[0]?.name) {
		data.append("foto", values.foto[0]);
	}

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "habitacions", data, config).then((response) => {
		if (response.status === 200) {
			message = "Club actualitzat";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al actualitzar";
		}
	});

	return message;
};

const updateHabitacio = async (key, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("resum", values.resum);
	data.append("dies", values.dies);
	data.append("desc", values.desc);
	data.append("min_p", values.min_p);
	data.append("max_p", values.max_p);
	data.append("dificultat", values.dificultat);
	data.append("calendari_id", values.calendari_id);
	data.append("calendari_booking", values.calendari_booking ?? "");
	data.append("calendari_airbnb", values.calendari_airbnb ?? "");
	data.append("preus", JSON.stringify(values.preus));
	data.append("preu_ps", values.preu_ps);

	if (values?.foto?.[0]?.name) {
		data.append("foto", values.foto[0]);
	}

	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "habitacions/" + key, data, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const addScape = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("desc", values.desc);
	data.append("dies", values.dies);
	data.append("resum", values.resum);
	data.append("min_p", values.min_p);
	data.append("max_p", values.max_p);
	data.append("calendari_id", values.calendari_id);
	data.append("temps", values.temps);
	data.append("dificultat", values.dificultat);
	data.append("terror", values.terror);
	data.append("preus", JSON.stringify(values.preus));
	data.append("preu_ps", values.preu_ps);

	if (values?.foto[0]?.name) {
		data.append("foto", values.foto[0]);
	}

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "scaperooms", data, config).then((response) => {
		if (response.status === 200) {
			message = "Club actualitzat";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al actualitzar";
		}
	});

	return message;
};

const updateScape = async (key, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("desc", values.desc);
	data.append("dies", values.dies);
	data.append("resum", values.resum);
	data.append("min_p", values.min_p);
	data.append("max_p", values.max_p);
	data.append("calendari_id", values.calendari_id);
	data.append("temps", values.temps);
	data.append("dificultat", values.dificultat);
	data.append("terror", values.terror);
	data.append("preus", JSON.stringify(values.preus));
	data.append("preu_ps", values.preu_ps);
	data.append("pagament_complet", values.pagament_complet ? 1 : 0);

	if (values?.foto?.[0]?.name) {
		data.append("foto", values.foto[0]);
	}

	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "scaperooms/" + key, data, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const createReserva = async (values) => {
	let message;
	let frame;
	let id;
	let variant;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "createReserva", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				frame = response.data.data;
				id = response.data.id;
				variant = response.data.variant;
			} else {
				variant = response.data.variant;
				message = response.data.message;
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			frame = error.response.data.data;
			variant = error.response.data.variant;
			console.log(error);
		});
	return { message, frame, variant, id };
};

const createReservaAdmimn = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "createReservaAdmin", values, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const changeState = async (id, value) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "changeState/" + id, { estat_id: value }, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const changeStateCompraTicket = async (id, value) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "changeStateCompraTicket/" + id, { estat_id: value }, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const addRestriccio = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "restriccions", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const removeRestriccions = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "removeRestriccions", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const addDataJoc = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "datajoc", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const refund = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "refund/" + id, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
				result = response.data.data;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, result };
};

const addCistella = async (values) => {
	let message;
	let code;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "addCard", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				code = response.data.code;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, code };
};

const removeCistella = async (values) => {
	let message;
	let code;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "removeCard", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				code = response.data.code;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, code };
};

const calendarisBooking = async (calendari) => {
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			"response-type": "blob",
		},
	};
	let calendaris = "";
	await axios
		.get(url + "getBooking/" + calendari, config)
		.then((response) => {
			calendaris = response.data;
		})
		.catch((error) => {});
	return icsToJson(calendaris);
};

const calendaris = async (calendari) => {
	console.log(calendari);
	let calendaris;
	let url_cal = `https://www.googleapis.com/calendar/v3/calendars/${calendari}/events?key=${API_KEY}&timeMin=${new Date().toISOString()}`;
	await axios
		.get(url_cal)
		.then((response) => {
			calendaris = response.data.items;
		})
		.catch((error) => {});
	return calendaris;
};

const enviarContacte = async (values) => {
	let message;
	let code;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "enviarContacte", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.success;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, code };
};

const addMaterial = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("desc", values?.desc ?? "");
	data.append("variant", values?.variant ?? "");
	data.append("talles", values.talles ? 1 : 0);
	data.append("cupo", values.cupo ? 1 : 0);
	data.append("preu", values.preu);

	if (values?.foto[0]?.name) {
		data.append("foto", values.foto[0]);
	}

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "materials", data, config).then((response) => {
		if (response.status === 200) {
			message = "Club actualitzat";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al actualitzar";
		}
	});

	return message;
};

const updateMaterial = async (key, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("desc", values?.desc ?? "");
	data.append("variant", values?.variant ?? "");
	data.append("talles", values.talles ?? 0);
	data.append("cupo", values.cupo ?? 0);
	data.append("preu", values.preu);

	if (values?.foto?.[0]?.name) {
		data.append("foto", values.foto[0]);
	}

	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "materials/" + key, data, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const createCompraTicket = async (values) => {
	let message;
	let frame;
	let id;
	let variant;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "compraTickets", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				frame = response.data.data;
				id = response.data.id;
				variant = response.data.variant;
			} else {
				variant = response.data.variant;
				message = response.data.message;
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			frame = error.response.data.data;
			variant = error.response.data.variant;
			console.log(error);
		});
	return { message, frame, variant, id };
};

const refundCompraTicket = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "refundCompraTickets/" + id, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
				result = response.data.data;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, result };
};

export {
	Login,
	getUser,
	logout,
	getElements,
	getElement,
	updateElement,
	addElement,
	deleteElement,
	addHabitacio,
	updateHabitacio,
	updateScape,
	addScape,
	createReserva,
	changeState,
	addRestriccio,
	createReservaAdmimn,
	addDataJoc,
	refund,
	addCistella,
	calendarisBooking,
	calendaris,
	removeCistella,
	enviarContacte,
	removeRestriccions,
	addMaterial,
	updateMaterial,
	createCompraTicket,
	refundCompraTicket,
	changeStateCompraTicket,
};
