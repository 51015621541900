import { Add } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import HabitacioAddDialog from "./HabitacioAddDialog";
import HabitacioItem from "./HabitacioItem";

export default function HabitacionsAdmin() {
	const [habitacions, setHabitacions] = useState();
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [update, setUpdate] = useState(0);
	const [habitacio, setHabitacio] = useState();

	useEffect(() => {
		const obtenir = async () => {
			const habitacions = await getElements("habitacions");
			setHabitacions(habitacions.result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	const obrir = () => {
		setHabitacio([]);
		setOpen(true);
	};

	return (
		<PageAdmin
			title="Habitacions"
			button={
				<Tooltip title="Afegir habitació">
					<IconButton onClick={obrir}>
						<Add />
					</IconButton>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<Grid container spacing={2}>
					{habitacions?.map((hab) => (
						<HabitacioItem
							habitacio={hab}
							setHabitacio={setHabitacio}
							setOpen={setOpen}
						/>
					))}
				</Grid>
			</Loading>
			<HabitacioAddDialog
				open={open}
				setOpen={setOpen}
				setUpdate={setUpdate}
				habitacio={habitacio}
				setLoading={setLoading}
				loading={loading}
			/>
		</PageAdmin>
	);
}
