import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useNavigate } from "react-router";
import CustomButton from "../../../../components/elements/CustomButton";
import { esJoc } from "../../../../components/Utils";
import theme from "../../../../theme";

function DialogReservaHotelPreu({ data, habitacio, jugadors, preu, dataJoc, addCard, admin, error, loading }) {
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();
	return (
		<Grid spacing={5} container>
			<Grid item md={7} my={1} xs={12} textAlign={matches ? "left" : "center"}>
				<Typography>
					{data ? moment(data).locale("es").format("LL") : "-"} a {habitacio?.nom ?? "-"} para {jugadors ?? "-"} personas
				</Typography>

				<Box display={matches ? "flex" : "block"}>
					<Typography variant="h2" fontWeight={700} my={matches ? 0 : 3}>
						{preu !== "-" && data ? Math.round(preu * 100) / 100 : "-"} €<span style={{ fontSize: 16 }}>/persona</span>
					</Typography>
					{/* <Typography
						variant="caption"
						color="primary"
						component="span"
						ml={2}
					>
						{esJoc(data, dataJoc)
							? "Con juego de terror"
							: "Sin juego de terror"}
					</Typography> */}
				</Box>
				{admin ? (
					<Typography variant="body1" fontWeight={700}>
						Paga y señal del producto: {habitacio?.habitacio.preu_ps} €
					</Typography>
				) : (
					<Typography variant="body1" fontWeight={700}>
						Precio a pagar: {habitacio?.habitacio.preu_ps} €
					</Typography>
				)}
				{!admin && (
					<Typography variant="caption" color={theme.color.background}>
						El precio a pagar corresponde a una paga y señal. El resto deberà abonarse el dia de la actividad.
					</Typography>
				)}
			</Grid>

			{!admin && (
				<Grid item md={5} my={2} xs={12}>
					<Stack direction={"row"} spacing={2}>
						<CustomButton
							title="Añadir al carro y comprar más"
							fullWidth
							onClick={() => addCard(false)}
							disabled={!preu || !data || !jugadors || loading}
						/>
						<CustomButton
							type="submit"
							title="Reservar ahora"
							fullWidth
							onClick={() => addCard(true)}
							disabled={!preu || !data || !jugadors || loading}
						/>
					</Stack>
					{error && (
						<Typography variant="error">
							Hay un cliente que tiene esta reserva en su cesta. Si no confirma la reserva, esta hora estarà disponible dentro de 10 minutos.
						</Typography>
					)}
				</Grid>
			)}
		</Grid>
	);
}

export default DialogReservaHotelPreu;
