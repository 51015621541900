import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../../theme";
import CustomButton from "../../../components/elements/CustomButton";
import { getElement, getElements, updateElement } from "../../../API/API";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import Loading from "../../../components/layouts/Loading";
import CustomSelect from "../../../components/elements/CustomSelect";
import moment from "moment";
import { esJoc } from "../../../components/Utils";

const hores = [
	{ id: "16:00:00", nom: "16.00 h" },
	{ id: "18:00:00", nom: "18.00 h" },
	{ id: "20:00:00", nom: "20.00 h" },
	{ id: "22:00:00", nom: "22.00 h" },
];

function ReservaDialogEdit({ setUpdate, open, setOpen, reserva, id }) {
	const [reservaE, setReserva] = useState("");
	const [tipus, setTipus] = useState();
	const [loading, setLoading] = useState(false);
	const [loadingInit, setLoadingInit] = useState(true);
	const [productes, setProductes] = useState([]);
	const [dataJoc, setDataJoc] = useState([]);
	const [preu, setPreu] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, register, watch, reset, control, setValue } = useForm();

	useEffect(() => {
		const obtenir = async () => {
			setLoadingInit(true);
			const productes = await getElements("productes");
			const { result } = await getElement("reservesElement", id);
			const dataJoc = await getElements("datajoc");
			setDataJoc(dataJoc.result);
			setProductes(productes.result);
			setReserva(result);
			reset(result);
			setTipus(result.producte.tipus_id);
			setLoadingInit(false);
			setLoading(false);
		};
		open && obtenir();
	}, [open, id]);

	useEffect(() => {
		if (reservaE?.id) {
			setValue("producte_id", reservaE?.producte?.id);
			setValue("hora", moment(reservaE?.data).format("HH:mm:ss"));
			setValue("data", moment(reservaE?.data).format("YYYY-MM-DD"));
			setPreu(calcularPreu(reservaE, watch, dataJoc));
		}
	}, [reservaE, setValue]);

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (name === "quantitat" || name === "data") {
				setPreu(calcularPreu(reservaE, watch, dataJoc));
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, reservaE]);

	const crear = async (values) => {
		setLoading(true);
		values.preu = preu * Number(watch("quantitat"));
		values.data = watch("data") + " " + watch("hora");
		const { message } = await updateElement("reservesElement", values.id, values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
				<Box display="flex" justifyContent={"space-between"}>
					Editar concepte
				</Box>
			</DialogTitle>
			<DialogContent>
				<Loading loading={loadingInit}>
					ID:{reservaE.id}
					<form onSubmit={handleSubmit(crear)}>
						<Box my={3}>
							<Box mb={3}>
								<CustomSelect
									label="Producte"
									list={productes}
									name="producte_id"
									register={register}
									defaultValue={Number(reservaE?.producte?.id)}
									control={control}
									disabled
								/>
								<Grid spacing={2} container mt={3}>
									<Grid md={6} item>
										<TextField
											{...register("quantitat")}
											label="Persones"
											type="number"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid md={6} item>
										<TextField
											{...register("pagat")}
											label="Quantitat pagada"
											type={"number"}
											fullWidth
											InputLabelProps={{ shrink: true }}
											inputProps={{
												step: "0.01",
											}}
										/>
									</Grid>
								</Grid>
							</Box>

							{tipus === "1" ? (
								<Grid spacing={2} container>
									<Grid item md={6} mt={3}>
										<TextField
											name="data"
											{...register("data")}
											type="date"
											label="Data"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item md={6}>
										<CustomSelect
											label="Hora"
											list={hores}
											name="hora"
											register={register}
											defaultValue={moment(reservaE.data).format("HH:mm:ss")}
											control={control}
										/>
									</Grid>
								</Grid>
							) : (
								<TextField
									name="data"
									{...register("data")}
									type="date"
									label="Data"
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						</Box>
						<Typography variant="h3" mb={3}>
							{preu} €/persona. TOTAL: ({preu * watch("quantitat")} €)
						</Typography>
						<Stack spacing={2} direction="row">
							<CustomButton title={"Tancar"} danger fullWidth onClick={() => setOpen(false)} />
							<CustomButton type="submit" title={"Guardar"} fullWidth loading={loading} />
						</Stack>
					</form>
				</Loading>
			</DialogContent>
		</Dialog>
	);
}

const calcularPreu = (reservaE, watch, dataJoc) => {
	let preu = "-";
	if (reservaE?.producte?.tipus_id === "1") {
		preu = reservaE?.producte?.preus?.filter((item) => Number(item?.persones) === Number(watch("quantitat")))[0]?.preu;
	} else {
		preu = reservaE?.producte?.preus?.filter(
			(item) => Number(item?.persones) === Number(watch("quantitat")) && item?.joc === (esJoc(watch("data"), dataJoc) ? "1" : "0")
		)[0]?.preu;
	}
	return preu;
};

export default ReservaDialogEdit;
