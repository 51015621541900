import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import premi1 from "../../../assets/premis/premi-01.png";
import premi2 from "../../../assets/premis/premi-02.png";
import premi3 from "../../../assets/premis/premi-03.png";
import premi4 from "../../../assets/premis/premi-04.png";
import premi5 from "../../../assets/premis/premi-05.png";
import premi6 from "../../../assets/premis/premi-06.png";
import premi7 from "../../../assets/premis/premi-07.png";
import premi8 from "../../../assets/premis/premi-08.png";
import premi9 from "../../../assets/premis/premi-09.png";
import premi10 from "../../../assets/premis/premi-10.png";
import premi11 from "../../../assets/premis/premi-11.png";
import premi12 from "../../../assets/premis/premi-12.png";
import premi13 from "../../../assets/premis/premi-13.png";
import premi14 from "../../../assets/premis/premi-14.png";
import premi15 from "../../../assets/premis/premi-15.png";
import premi16 from "../../../assets/premis/premi-16.png";
import premi17 from "../../../assets/premis/premi-17.png";
import premi18 from "../../../assets/premis/premi-18.png";
import premi19 from "../../../assets/premis/premi-19.png";
import premi20 from "../../../assets/premis/premi-20.png";
import premi21 from "../../../assets/premis/premi-21.png";

import { useNavigate } from "react-router";
import { Container } from "@mui/system";

const listTrapeca = [premi1, premi2, premi3, premi4, premi5];

const listHorror = [premi6, premi7, premi8];

const listER = [premi9, premi10, premi11, premi12, premi13];

const listTaty = [premi14, premi15, premi16, premi17];

const listGiba = [premi18, premi19, premi20, premi21];

export default function PremisSobre() {
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<Box>
			<Container maxWidth={"md"}>
				<Grid spacing={2} container justifyContent={"center"}>
					{listTrapeca.map((foto) => (
						<Grid
							item
							sm={4}
							xs={6}
							style={{ cursor: "pointer" }}
							display="flex"
						>
							<img
								src={foto}
								alt={"Premios insomnia corp"}
								style={{ width: "100%" }}
								className={classes.premi}
							/>
						</Grid>
					))}
				</Grid>
			</Container>
			<Box my={10} />
			<Container maxWidth={"md"}>
				<Grid spacing={2} container justifyContent={"center"}>
					{listHorror.map((foto) => (
						<Grid
							item
							sm={3}
							xs={6}
							style={{ cursor: "pointer" }}
							display="flex"
						>
							<img
								src={foto}
								alt={"Premios insomnia corp"}
								style={{ width: "100%" }}
								className={classes.premi}
							/>
						</Grid>
					))}
				</Grid>
			</Container>

			<Box my={10} />
			<Container maxWidth={"md"}>
				<Grid spacing={2} container justifyContent={"center"}>
					{listER.map((foto) => (
						<Grid
							item
							sm={2.4}
							xs={6}
							style={{ cursor: "pointer" }}
							display="flex"
						>
							<img
								src={foto}
								alt={"Premios insomnia corp"}
								style={{ width: "100%" }}
								className={classes.premi}
							/>
						</Grid>
					))}
				</Grid>
			</Container>

			<Box my={5} />
			<Container maxWidth={"md"}>
				<Grid spacing={2} container justifyContent={"center"}>
					{listGiba.map((foto) => (
						<Grid
							item
							sm={2.4}
							xs={6}
							style={{ cursor: "pointer" }}
							display="flex"
						>
							<img
								src={foto}
								alt={"Premios insomnia corp"}
								style={{ width: "100%" }}
								className={classes.premi}
							/>
						</Grid>
					))}
				</Grid>
			</Container>
			<Box my={5} />
			<Container maxWidth={"md"}>
				<Grid spacing={2} container justifyContent={"center"}>
					{listTaty.map((foto) => (
						<Grid
							item
							sm={2.4}
							xs={6}
							style={{ cursor: "pointer" }}
							display="flex"
						>
							<img
								src={foto}
								alt={"Premios insomnia corp"}
								style={{ width: "100%" }}
								className={classes.premi}
							/>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	premi: {
		transition: "0.2s",
		"&:hover": {
			"-webkit-filter": "drop-shadow(0px 5px 20px #ffce6680)",
			filter: "drop-shadow(0px 5px 9px #ffce6680)",
			transform: "scale(1.1)",
		},
	},
}));
