import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller } from "react-hook-form";
import { addElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";

export function AddPleRestaurant({
	formPleRestaurant,
	setLoading,
	setUpdate,
	handleClose,
	loading,
	setOpen,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const crear = async (values) => {
		console.log(values);
		setLoading(true);
		const { message } = await addElement("ple_restaurant", values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		handleClose();
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<form onSubmit={formPleRestaurant.handleSubmit(crear)}>
			<Box pb={5}>
				<Box display="flex" flexDirection={"column"}>
					<Controller
						name={"ple"}
						control={formPleRestaurant.control}
						render={({ field }) => (
							<FormControlLabel
								style={{ margin: 0 }}
								label="Marcar restaurant com a ple"
								control={
									<Checkbox
										onChange={(e) =>
											field.onChange(e.target.checked)
										}
										size={"small"}
										checked={
											field.value === "1" ||
											field.value === true
										}
									/>
								}
							/>
						)}
					/>
				</Box>
			</Box>
			<Stack direction={"row"} spacing={2}>
				<CustomButton
					title="Canel·lar"
					fullWidth
					danger
					onClick={() => setOpen(false)}
				/>
				<CustomButton
					type="submit"
					title="Crear"
					fullWidth
					loading={loading}
				/>
			</Stack>
		</form>
	);
}
