import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import Loading from "../../../components/layouts/Loading";
import theme from "../../../theme";
import { ReactComponent as Dificultat } from "../../../assets/icons/dificultat.svg";
import { ReactComponent as Persones } from "../../../assets/icons/persones.svg";
import { ReactComponent as Temps } from "../../../assets/icons/temps.svg";
import { ReactComponent as Terror } from "../../../assets/icons/terror.svg";
import CustomButton from "../../../components/elements/CustomButton";
import { FAQS } from "../../../components/elements/FAQS";
import hotel from "../../../assets/img/krugger.png";
import DialogReservaHotelKrugger from "../reserves/DialogReservaHotelKrugger";

export default function ScapeKrugger() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	return (
		<Page title="Insomnia Corporation - El Secreto de los Krugger">
			<Box className={classes.portada}>
				<Container>
					<Grid spacing={6} container>
						<Grid item md={6} xs={12} mb={5}>
							<Box p={4} className={matches ? classes.wrapImg : classes.wrapImgMobile}>
								<img src={hotel} alt={"El Secreto de los Krugger"} className={matches ? classes.img : classes.imgMobile} />
							</Box>
						</Grid>
						<Grid item md={6} xs={12}>
							<CustomButton title={"Reservar Secreto de los Krugger"} light onClick={() => setOpen(true)} fullWidth={!matches} />
							<Box my={2} />
							<Divider sx={{ borderColor: theme.color.secondary }} />
							<Typography pt={3} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								<strong>Hora</strong>: de 18:00 a 11:30 del día siguiente.
							</Typography>
							<Typography py={3} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								Sumérgete en una experiencia inmersiva y escalofriante con "El Secreto de los Krugger" en Insomnia Hotel. Esta aventura
								exclusiva te llevará a descubrir los oscuros y misteriosos secretos de la infame familia Krugger. Desde enigmas desafiantes
								hasta una cena espectáculo y un terrorífico escape room, esta experiencia promete ser inolvidable.
							</Typography>
							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"} fontWeight={800}>
								Itinerario de la Experiencia:
							</Typography>

							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								<strong>18:00</strong> - Escape Room del Check-Inn: A tu llegada, te adentrarás en el primer desafío. Cada habitación del hotel
								está tematizada según un miembro de la familia Krugger y oculta enigmas que debes resolver para acceder a las comodidades
								básicas. La familia no quiere que los huéspedes duerman en sus habitaciones, así que han puesto a prueba tu ingenio. Este escape
								room se juega por parejas, y cada pareja deberá resolver los enigmas de la habitación en la que pasarán la noche.
							</Typography>

							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								<strong>20:00</strong> - Cena Menú degustación: Después de enfrentar los primeros desafíos, disfrutarás de una cena espectáculo
								que ofrece una experiencia gastronómica de alto nivel. La cena estará llena de sorpresas y actuaciones que te mantendrán al
								borde de tu asiento mientras degustas platos exquisitos preparados por nuestros chefs.
							</Typography>

							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								<strong>22:00</strong> - El Secreto de los Krugger: Una vez terminada la cena, comienza la verdadera prueba. Este escape room de
								terror te llevará por todo el hotel, donde enfrentarás desafíos aún más oscuros y perturbadores. Descubre los secretos más
								ocultos de los Krugger mientras intentas sobrevivir a la noche.
							</Typography>
							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								<strong>24:00 a 11:30</strong> (sin terror) Descanso Nocturno: Al finalizar el escape room, podrás finalmente descansar en tu
								habitación temática. Cada habitación ofrece una atmósfera única y espeluznante, haciendo que tu estancia sea completamente
								inolvidable.
							</Typography>

							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								<strong>Reserva Ahora</strong>: No pierdas la oportunidad de vivir esta experiencia única y aterradora. Las plazas son
								limitadas, así que asegúrate de reservar con anticipación para garantizar tu participación en "El Secreto de los Krugger".
							</Typography>

							<Typography py={3} color={theme.color.secondary} variant="body1" textAlign={"left"} fontWeight={800}>
								¡Te esperamos para una noche de misterio, terror y emoción en Insomnia Hotel!
							</Typography>

							<Divider sx={{ borderColor: theme.color.secondary }} />
							<Box display={"flex"} justifyContent={"space-between"} my={3} alignItems={"center"}>
								<Typography variant="h4" color={theme.color.secondary}>
									Precio
								</Typography>

								<Typography variant="body1" color={theme.color.secondary}>
									<span style={{ fontSize: 30 }}>170 </span>€ / persona
								</Typography>
							</Box>
							<Divider sx={{ borderColor: theme.color.secondary }} />
							<Params />
							<Divider sx={{ borderColor: theme.color.secondary, marginBottom: 3 }} />

							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								<strong>Experiencia Exclusiva para grupos grandes</strong>
							</Typography>
							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								Para grupos de <strong>9 a 20 personas</strong>, ofrecemos una experiencia única que combina la cena con menú degustación con el
								escape room de terror "El Secreto de los Krugger" en Insomnia Hotel.
							</Typography>
							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								Este pack de 4 horas incluye:
							</Typography>
							<ul>
								<li style={{ color: "white" }}>
									<Typography pt={1} color={theme.color.secondary} variant="body1" textAlign={"left"}>
										<strong>Cena con menú degustación</strong>: Disfruta del menú degustación con experiencia gastronómica del Secreto de
										los Krugger.
									</Typography>
								</li>
								<li style={{ color: "white" }}>
									<Typography pt={1} color={theme.color.secondary} variant="body1" textAlign={"left"}>
										<strong>Escape room de terror</strong>: El Secreto de los Krugger: Sumérgete en una experiencia inmersiva de terror por
										todo Insomnia Hotel.
									</Typography>
								</li>
							</ul>
							<Divider sx={{ borderColor: theme.color.secondary }} />
							<Box display={"flex"} justifyContent={"space-between"} my={3} alignItems={"center"}>
								<Typography variant="h4" color={theme.color.secondary}>
									Precio grupos grandes
								</Typography>

								<Typography variant="body1" color={theme.color.secondary}>
									<span style={{ fontSize: 30 }}>95 </span>€ / persona
								</Typography>
							</Box>
							<Divider sx={{ borderColor: theme.color.secondary }} />
							<Typography pt={2} color={theme.color.secondary} variant="body1" textAlign={"left"}>
								Para reservar esta experiencia exclusiva, contacta con nosotros en el teléfono{" "}
								<a style={{ color: "white" }} href="tel:+34689153847">
									689 153 847
								</a>{" "}
								(todos los días, excepto viernes y sábados).
							</Typography>
							<Typography py={3} color={theme.color.secondary} variant="body1" textAlign={"left"} fontWeight={800}>
								¡No te pierdas la oportunidad de vivir una noche inolvidable con tu grupo!
							</Typography>
							<Divider sx={{ borderColor: theme.color.secondary, marginBottom: 3 }} />
							<CustomButton title={"Reservar Secreto de los Krugger"} light onClick={() => setOpen(true)} fullWidth={!matches} />
							<Typography variant="h4" pt={4} pb={2}>
								FAQS
							</Typography>

							<FAQS />
						</Grid>
					</Grid>
				</Container>
			</Box>
			<DialogReservaHotelKrugger open={open} setOpen={setOpen} />
			{/* <DialogReserva open={open} setOpen={setOpen} select={scape} /> */}
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	wrapImg: {
		transform: "rotate(-10deg)",
		position: "fixed",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
	},
	img: {
		zIndex: 0,
		width: 450,
		border: "4px solid " + theme.color.secondary,
	},
	wrapImgMobile: {
		transform: "rotate(-6deg)",
		transition: "0.2s",
		paddingBottom: 50,
		zIndex: 0,
		"&:hover": {
			transform: "rotate(2deg)",
		},
	},
	imgMobile: {
		zIndex: 0,
		width: "100%",
		border: "4px solid " + theme.color.secondary,
	},
}));

function Params() {
	return (
		<Grid container spacing={2} mb={2} mt={1}>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Persones width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						2-9 jugadores
					</Typography>
				</Box>
			</Grid>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Temps width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						17:30 horas
					</Typography>
				</Box>
			</Grid>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Dificultat width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						Dificultad 3/5
					</Typography>
				</Box>
			</Grid>
			<Grid item md={6} xs={12}>
				<Box display="flex" alignItems={"center"}>
					<Terror width={40} fill={theme.color.secondary} />{" "}
					<Typography variant="body1" ml={2} color={"secondary"}>
						Terror 5/5
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
}
