import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import premi1 from "../../../assets/premis/premi-casa-01.png";
import premi2 from "../../../assets/premis/premi-casa-02.png";
import premi3 from "../../../assets/premis/premi-casa-03.png";
import premi4 from "../../../assets/premis/premi-casa-04.png";
import premi5 from "../../../assets/premis/premi-casa-05.png";
import premi6 from "../../../assets/premis/premi-casa-06.png";
import premi7 from "../../../assets/premis/premi-casa-07.png";
import premi8 from "../../../assets/premis/premi-casa-08.png";
import premi9 from "../../../assets/premis/premi-casa-09.png";
import premi10 from "../../../assets/premis/premi-casa-10.png";
import premi11 from "../../../assets/premis/premi-casa-11.png";
import premi12 from "../../../assets/premis/premi-casa-12.png";

import premi1c from "../../../assets/premis/premi-coctel-1.png";
import premi2c from "../../../assets/premis/premi-coctel-2.png";
import premi3c from "../../../assets/premis/premi-coctel-3.png";

import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
	premi: {
		transition: "0.2s",
		"&:hover": {
			"-webkit-filter": "drop-shadow(0px 5px 20px #c7bdb080)",
			filter: "drop-shadow(0px 5px 9px #c7bdb080)",
			transform: "scale(1.1)",
		},
	},
}));

const listLaCasa = [
	premi1,
	premi2,
	premi3,
	premi4,
	premi5,
	premi6,
	premi7,
	premi8,
	premi9,
	premi10,
	premi11,
	premi12,
];

const listCoctel = [premi1c, premi2c, premi3c];

export default function Premis({ scape }) {
	const navigate = useNavigate();
	const classes = useStyles();

	const renderPremis = () => {
		console.log(scape?.id);
		switch (scape?.id) {
			case 6:
				return (
					<Grid spacing={5} container>
						{listLaCasa.map((foto) => (
							<Grid
								item
								sm={4}
								xs={6}
								onClick={() =>
									navigate("/sobre-la-corporacion")
								}
								style={{ cursor: "pointer" }}
							>
								<img
									src={foto}
									alt={scape.nom}
									style={{ width: "100%" }}
									className={classes.premi}
								/>
							</Grid>
						))}
					</Grid>
				);
			case 8:
				return (
					<Grid spacing={2} container>
						{listCoctel.map((foto) => (
							<Grid
								item
								sm={4}
								xs={6}
								onClick={() =>
									navigate("/sobre-la-corporacion")
								}
								style={{ cursor: "pointer" }}
							>
								<img
									src={foto}
									alt={scape.nom}
									style={{ width: "100%" }}
									className={classes.premi}
								/>
							</Grid>
						))}
					</Grid>
				);

			default:
				break;
		}
	};
	return <Box my={3}>{renderPremis()}</Box>;
}
