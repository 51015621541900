import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Container } from "@mui/system";
import bg from "../../assets/img/fons-2.jpg";
import logo from "../../assets/img/logo-calavera-footer.png";
import { useLocation, useNavigate } from "react-router";

const Footer = () => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Box className={location.pathname === "/" ? classes.rootHome : classes.root} displayPrint="none">
			<Container>
				<Grid container spacing={3} className={classes.main}>
					<Grid mb={2} item md={4} xs={12} textAlign={matches ? "left" : "center"}>
						<Typography variant="footer">Cuanto antes te enfrentes a tus miedos, antes dejarán de perseguirte.</Typography>
						<br></br>
						<Typography variant="footer" p={1}>
							-Insomnia Corp.
						</Typography>
					</Grid>
					<Grid item md={4} xs={12} textAlign={"center"}>
						<Box className={classes.header} mb={3}>
							<a href="/">
								<img alt="Insomnia Corp. Logo" src={logo} width={80} className={classes.logo} />
							</a>
						</Box>
					</Grid>
					<Grid item md={4} xs={12} textAlign={matches ? "right" : "center"}>
						<Box display="flex" flexDirection={"column"} mb={2}>
							<Box
								onClick={() => navigate("/legal/avis-legal")}
								style={{
									textDecoration: "none",
									cursor: "pointer",
								}}
							>
								<Typography variant="footer">Aviso Legal</Typography>
							</Box>

							<Box
								onClick={() => navigate("/legal/politica-privacitat")}
								style={{
									textDecoration: "none",
									cursor: "pointer",
								}}
							>
								<Typography variant="footer">Política de Privacidad</Typography>
							</Box>
							<a
								href={"https://la-padrina.cat"}
								style={{
									textDecoration: "none",
									cursor: "pointer",
								}}
								target="_blank"
								rel="noreferrer"
							>
								<Typography variant="footer">Diseño y programación: La Padrina</Typography>
							</a>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;

const useStyles = makeStyles((theme) => ({
	root: {
		borderTop: "1px solid " + theme.color.secondary,
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		zIndex: 100,
		position: "relative",
	},
	rootHome: {
		borderTop: "1px solid " + theme.color.secondary,
		backgroundColor: "#0e0d0c",
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		zIndex: 100,
		position: "relative",
	},
	main: {
		paddingTop: 25,
		display: "flex",
		alignItems: "center",
	},
	header: {
		textAlign: "center",
	},
	logo: {
		fill: theme.color.background,
	},
	icon: {
		width: 60,
		height: 60,
		border: "1px solid " + theme.color.background,
		borderRadius: 50,
		padding: 10,
		color: theme.color.background,
	},
	iconSVG: {
		width: 25,
		height: 25,
		border: "1px solid " + theme.color.background,
		borderRadius: 50,
		padding: 10,
		fill: theme.color.background,
		fontSize: 20,
	},
	iconWrap: {
		"&.MuiButtonBase-root": {
			borderRadius: 50,
		},
	},
}));
