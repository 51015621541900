import React, { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../../theme";
import CustomButton from "../../../components/elements/CustomButton";
import { deleteElement } from "../../../API/API";
import { useSnackbar } from "notistack";

function ReservaDialogDelete({ setUpdate, open, setOpen, reserva_element }) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setOpen(false);
	};

	const eliminar = async () => {
		setLoading(true);
		const { message } = await deleteElement(
			"reservesElement",
			reserva_element
		);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate(true);
		setLoading(false);
		setOpen(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="sm"
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Eliminar concepte
				</Box>
			</DialogTitle>
			<DialogContent>
				<Typography variant="h3" mt={4}>
					Segur que vols eliminar el concepte?
				</Typography>
				<Typography mb={4}>
					S'eliminarà del Google Calendar corresponent, però no
					s'efecturarà la devolució de l'import.
				</Typography>
				<Stack spacing={2} direction="row">
					<CustomButton
						title={"Canel·lar"}
						danger
						fullWidth
						onClick={() => setOpen(false)}
					/>
					<CustomButton
						onClick={eliminar}
						title={"Eliminar"}
						fullWidth
						loading={loading}
					/>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

export default ReservaDialogDelete;
