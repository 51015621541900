import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import bg from "../../../assets/img/fons-2.jpg";
import Loading from "../../../components/layouts/Loading";
import theme from "../../../theme";
import { CartesHab } from "../../../components/elements/CartesHab";
import { ReactComponent as Dificultat } from "../../../assets/icons/dificultat.svg";
import { ReactComponent as Persones } from "../../../assets/icons/persones.svg";
import CustomButton from "../../../components/elements/CustomButton";
import DialogReservaHotel from "../reserves/DialogReservaHotel";
import sanefa from "../../../assets/img/habitacions/sanefa.svg";
import { ReactComponent as SimbolAradia } from "../../../assets/img/habitacions/simbol-aradia.svg";
import { ReactComponent as SimbolDoctor } from "../../../assets/img/habitacions/simbol-doctor.svg";
import { ReactComponent as SimbolSrKrugger } from "../../../assets/img/habitacions/simbol-srkrugger.svg";
import { ReactComponent as SimbolMadre } from "../../../assets/img/habitacions/simbol-madre.svg";
import { FAQS } from "../../../components/elements/FAQS";

function Habitacions() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [habitacions, setHabitacions] = useState([]);
	const [imatges, setImatges] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElements("habitacions");
			setHabitacions(result);
			setImatges(
				result.map((item) => {
					return {
						foto: "https://api.insomniacorp.com/storage/" + item?.foto,
					};
				})
			);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<Page title="Insomnia Corporation - Habitaciones">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					{matches && <CartesHab imatges={imatges} />}

					<Loading loading={loading}>
						<Grid spacing={6} container>
							<Grid item md={6} xs={12} mb={5} zIndex={10}>
								<Box display={"flex"} position="absolute" flexDirection={"column"}>
									<Typography variant="h1" pt={4} fontSize={45} color={theme.color.secondary}>
										Habitaciones
									</Typography>
									<Divider
										sx={{
											borderColor: theme.color.secondary,
										}}
									/>
								</Box>
							</Grid>

							<Grid item md={6} xs={12}>
								<Typography pt={3} variant="body1" color={theme.color.secondary}>
									En Insomnia Hotel actualmente disponemos de 4 habitaciones temáticas con un escape room diferente en cada una de ellas.
									Nuestras habitaciones ofrecen:
								</Typography>
								<Typography variant="body1" color={theme.color.secondary}>
									- Hotel Escape Room (1 hora aprox.) - sin terror
								</Typography>
								<Typography variant="body1" color={theme.color.secondary}>
									- Alojamiento
								</Typography>
								<Typography pt={2} variant="body1" fontSize={12} color={theme.color.secondary}>
									*Recordad, no hay personajes de terror en el hotel.
								</Typography>
								<Typography variant="body1" fontSize={12} color={theme.color.secondary}>
									*Para cenar en nuestro restaurante, llamar para reservar. No incluido en el alojamiento.
								</Typography>
							</Grid>
						</Grid>
						<Box my={10}>
							<Divider
								sx={{
									background: `url(${sanefa})`,
									borderColor: "transparent",
									height: 15,
									backgroundRepeat: "repeat-x",
								}}
							/>
						</Box>
						<Grid spacing={0} container>
							<Grid item md={6} xs={12}></Grid>
							<Grid item md={6} xs={12} zIndex={10}>
								{habitacions?.map((hab, index) => (
									<HabitacioItemPublic hab={hab} setOpen={setOpen} max={habitacions?.length} index={index} />
								))}
							</Grid>
						</Grid>
					</Loading>
				</Container>
			</Box>
			<DialogReservaHotel open={open} setOpen={setOpen} />
		</Page>
	);
}

export default Habitacions;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.color.secondary,
	},
}));

function Params({ select }) {
	return (
		<Box display="flex" justifyContent={"flex-start"} pt={3} pb={2}>
			<Box display="flex" alignItems="center" mb={1} mr={5}>
				<Persones width={40} fill={theme.color.secondary} />{" "}
				<Typography variant="body1" color={theme.color.secondary} ml={2}>
					{select?.habitacio.min_p}-{select?.habitacio.max_p} huéspedes
				</Typography>
			</Box>
			<Box display="flex" alignItems="center" mb={1}>
				<Dificultat width={40} fill={theme.color.secondary} />{" "}
				<Typography variant="body1" color={theme.color.secondary} ml={2}>
					Dificultad {select?.habitacio.dificultat}/5
				</Typography>
			</Box>
		</Box>
	);
}

function HabitacioItemPublic({ hab, setOpen, index, max }) {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();

	const renderIcon = () => {
		switch (hab?.nom) {
			case "Aradia":
				return <SimbolAradia width={200} fill={theme.color.secondary} />;
			case "Doctor":
				return <SimbolDoctor width={200} fill={theme.color.secondary} />;
			case "Sr. Krugger":
				return <SimbolSrKrugger width={200} fill={theme.color.secondary} />;
			case "Madre":
				return <SimbolMadre width={200} fill={theme.color.secondary} />;
			default:
				break;
		}
	};

	return (
		<Box mb={8}>
			{!matches && (
				<Box className={classes.wrapImg} my={4}>
					<img src={"https://api.insomniacorp.com/storage/" + hab?.foto} alt={"Insomnia habitación " + hab?.nom} className={classes.img} />
				</Box>
			)}
			<Box textAlign={"center"} pb={4}>
				<Typography variant="h2" fontSize={60} mb={2} color={theme.color.secondary}>
					{hab.nom}
				</Typography>
				{renderIcon()}
			</Box>

			<Divider
				sx={{
					borderColor: theme.color.secondary,
				}}
			/>
			<Typography my={3} color={theme.color.secondary}>
				{hab.desc}
			</Typography>
			<Divider
				sx={{
					borderColor: theme.color.secondary,
				}}
			/>
			<Params select={hab} />

			<Divider
				sx={{
					borderColor: theme.color.secondary,
				}}
			/>
			<Box mt={4} mb={10}>
				<CustomButton light title="Reserva" onClick={() => setOpen(true)} fullWidth={!matches} />
			</Box>
			{index !== max - 1 && (
				<Divider
					sx={{
						background: `url(${sanefa})`,
						borderColor: "transparent",
						height: 15,
						backgroundRepeat: "repeat-x",
					}}
				/>
			)}
		</Box>
	);
}
