import { Avatar, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "../../../components/elements/CustomButton";

export default function MaterialItem({ material, setMaterial, setOpen }) {
	const classes = useStyles();

	const editar = () => {
		setMaterial(material);
		setOpen(true);
	};

	return (
		<Grid item md={2} xs={12}>
			<Box className={classes.hab}>
				<Box>
					<Avatar src={"https://api.insomniacorp.com/storage/" + material?.foto} sx={{ width: "auto", height: 200 }} variant="square" />

					<Box mt={2}>
						<Typography variant="h3">{material.nom}</Typography>
						<Typography variant="body2">{material.desc.substring(0, 80)}...</Typography>
						<Typography variant="body1">
							<strong>Preu:</strong> {material?.material.preu} €
						</Typography>
					</Box>
				</Box>
				<Box mt={3}>
					<Stack direction={"row"} spacing={2}>
						<CustomButton title="Editar" onClick={editar} />
					</Stack>
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	hab: {
		backgroundColor: "white",
		padding: 20,
	},
	pastilla: {
		border: "1px solid black",
		backgroundColor: theme.color.secondary,
		display: "table",
		paddingLeft: 5,
		paddingRight: 5,
	},
}));
