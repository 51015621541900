import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "../../../components/elements/CustomButton";
import { ReactComponent as Persones } from "../../../assets/icons/persones.svg";
import { ReactComponent as Dificultat } from "../../../assets/icons/dificultat.svg";

export default function HabitacioItem({ habitacio, setHabitacio, setOpen }) {
	const classes = useStyles();

	const editar = () => {
		setHabitacio(habitacio);
		setOpen(true);
	};

	return (
		<Grid item md={3} xs={12}>
			<Box className={classes.hab}>
				<Box>
					<Avatar
						src={
							"https://api.insomniacorp.com/storage/" +
							habitacio?.foto
						}
						sx={{ width: "auto", height: 200 }}
						variant="square"
					/>
					<Box mt={2}>
						<Typography variant="h3">{habitacio.nom}</Typography>
						<Typography variant="body2">
							{habitacio.desc.substring(0, 80)}...
						</Typography>
						<Typography variant="body2">
							<strong>Paga i senyal:</strong>{" "}
							{habitacio?.habitacio.preu_ps} €
						</Typography>
						<Box
							display="flex"
							justifyContent={"space-around"}
							spacing={2}
							m={2}
						>
							<Box
								display="flex"
								justifyContent={"flex-start"}
								alignItems="center"
							>
								<Persones width={40} />{" "}
								<Typography variant="body2" ml={2}>
									{habitacio?.habitacio.min_p}-
									{habitacio?.habitacio.max_p}
								</Typography>
							</Box>
							<Box
								display="flex"
								justifyContent={"flex-start"}
								alignItems="center"
							>
								<Dificultat width={40} />{" "}
								<Typography variant="body2" ml={2}>
									{habitacio?.habitacio.dificultat}/5
								</Typography>
							</Box>
						</Box>
						<Typography>Preus: </Typography>
						<Box display={"flex"} flexWrap="wrap">
							{habitacio?.preus?.map((preu) => (
								<Box className={classes.pastilla} mr={1} mb={1}>
									<Typography
										variant="caption"
										color="primary"
									>
										{preu.persones}p: {preu.preu} €
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
				<Box mt={3}>
					<Stack direction={"row"} spacing={2}>
						<CustomButton title="Editar" onClick={editar} />
					</Stack>
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	hab: {
		backgroundColor: "white",
		padding: 20,
	},
	pastilla: {
		border: "1px solid black",
		backgroundColor: theme.color.secondary,
		display: "table",
		paddingLeft: 5,
		paddingRight: 5,
	},
}));
