import { Box, CircularProgress, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
	loading: {
		height: 500,
		width: "100%",

		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 0,
	},
}));

export default function Loading({ loading, children }) {
	const classes = useStyles();
	return (
		<Box style={{ width: "100%", height: "100%" }}>
			{!loading ? (
				<Fade in={!loading}>
					<div style={{ width: "100%", height: "100%" }}>
						{children}
					</div>
				</Fade>
			) : (
				<Box className={classes.loading}>
					<Fade in={loading}>
						<CircularProgress sx={{ color: theme.color.primary }} />
					</Fade>
				</Box>
			)}
		</Box>
	);
}
