import { useEffect, useState } from "react";

export default function CalendariFilter({
	setFilteredReserves,
	reserves,
	ocultar,
	booking,
}) {
	useEffect(() => {
		if (ocultar) {
			if (booking) {
				setFilteredReserves(
					reserves.filter(
						(item) =>
							item.overlap !== "block" &&
							item.overlap !== "booking"
					)
				);
			} else {
				setFilteredReserves(
					reserves.filter((item) => item.overlap !== "block")
				);
			}
		} else {
			if (booking) {
				setFilteredReserves(
					reserves.filter((item) => item.overlap !== "booking")
				);
			} else {
				setFilteredReserves(reserves);
			}
		}
	}, [ocultar, booking]);
}
