import { Add, Delete, DeleteForever, DeleteForeverOutlined, DeleteOutline, Edit } from "@mui/icons-material";
import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Fab,
	FormControlLabel,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { addHabitacio, deleteElement, updateElement, updateHabitacio } from "../../../API/API";
import Badge from "../../../components/elements/Badge";
import CustomButton from "../../../components/elements/CustomButton";
import CustomTextField from "../../../components/elements/CustomTextField";
import ImageInput from "../../../components/elements/ImageInput";
import Thumb from "../../../components/elements/Thumb";
import Loading from "../../../components/layouts/Loading";
import theme from "../../../theme";

const diesWeek = [0, 1, 2, 3, 4, 5, 6];

function HabitacioAddDialog({ habitacio, setUpdate, open, setOpen, setLoading, loading }) {
	const { enqueueSnackbar } = useSnackbar();
	const [dies, setDies] = useState([]);
	const [inLoading, setInLoading] = useState(true);
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		trigger,
		control,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			preus: [{ persones: "", preu: "" }],
		},
	});

	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: "preus",
	});

	useEffect(() => {
		setInLoading(true);
		if (habitacio?.id) {
			setTimeout(() => {
				reset(habitacio);
				setValue("min_p", habitacio?.habitacio?.min_p);
				setValue("max_p", habitacio?.habitacio?.max_p);
				setValue("dificultat", habitacio?.habitacio?.dificultat);
				setValue("calendari_id", habitacio?.habitacio?.calendari_id);
				setValue("calendari_booking", habitacio?.habitacio?.calendari_booking);
				setValue("calendari_airbnb", habitacio?.habitacio?.calendari_airbnb);
				setValue("dies", habitacio?.habitacio.dies);
				setValue("preu_ps", habitacio?.habitacio.preu_ps);
				setDies(habitacio?.habitacio.dies?.split(",").map((n) => parseInt(n, 10)) ?? []);
				setInLoading(false);
			}, 400);
		} else {
			setTimeout(() => {
				reset({ preus: [{ persones: "", preu: "" }] });
				replace([{ persones: "", preu: "" }]);
				setInLoading(false);
			}, 400);
		}
	}, [habitacio]);

	const handleClose = () => {
		setOpen(false);
	};

	const guardar = async (values) => {
		setLoading(true);
		const { message } = await updateHabitacio(habitacio.id, values);

		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	const crear = async (values) => {
		setLoading(true);
		const { message } = await addHabitacio(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	const eliminar = async () => {
		setLoading(true);
		const { message } = await deleteElement("habitacions", habitacio.id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	const changeDies = (value) => {
		if (dies?.some((dia) => dia === value)) setDies(dies?.filter((dia) => dia !== value));
		else setDies([...dies, value]);
	};

	useEffect(() => {
		setValue("dies", dies?.toString());
	}, [dies]);

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
		>
			{habitacio?.id ? (
				<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
					<Box display="flex" justifyContent={"space-between"}>
						Modificar {habitacio.nom}
						{/* <Fab onClick={eliminar} color="error" size="small">
							<Delete />
						</Fab> */}
					</Box>
				</DialogTitle>
			) : (
				<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
					<Box display="flex" justifyContent={"space-between"}>
						Crear habitacio
					</Box>
				</DialogTitle>
			)}
			<DialogContent>
				<form onSubmit={handleSubmit(habitacio?.id ? guardar : crear)}>
					<Loading loading={inLoading}>
						<Box py={2}>
							<Grid spacing={3} container>
								<Grid item md={6}>
									<CustomTextField name={"nom"} register={register} label="Nom habitacio" required />
									<CustomTextField name={"desc"} register={register} label="Descripcio" rows={3} multiline required />
									<CustomTextField name={"dificultat"} register={register} label="Dificultat (1-5)" max={5} required type="number" />
									<Grid container spacing={3}>
										<Grid item md={6} xs={12}>
											<CustomTextField name={"min_p"} register={register} label="Persones mínimes" required type="number" />
										</Grid>
										<Grid item md={6} xs={12}>
											{" "}
											<CustomTextField name={"max_p"} register={register} label="Persones màximes" required type="number" />
										</Grid>
									</Grid>
								</Grid>
								<Grid item md={6} mt={3}>
									<Thumb file={getValues("foto")} />
									<ImageInput trigger={trigger} register={register} name="foto" text={"Carregar imatge"} />
								</Grid>
							</Grid>
							<Box mb={3}>
								<CustomTextField name={"calendari_id"} register={register} label="Enllaç Google Calendar" required />
							</Box>
							<Box mb={3}>
								<CustomTextField name={"calendari_booking"} register={register} label="Enllaç Calendari Booking" />
							</Box>
							<Box mb={3}>
								<CustomTextField name={"calendari_airbnb"} register={register} label="Enllaç Calendari AirBnB" />
							</Box>
							<Divider />
							<Typography variant={"h4"} mt={2} mb={2}>
								Dies disponibilitat
							</Typography>
							<Grid spacing={2} container>
								{diesWeek.map((item) => (
									<Grid item sm>
										<Badge
											text={moment(item, "e").locale("ca").format("dddd")}
											set={changeDies}
											index={item}
											select={dies?.filter((dia) => dia === item)[0]}
										/>
									</Grid>
								))}
							</Grid>
							<Divider />
							<Typography variant={"h4"} mt={2} mb={2}>
								Preus
							</Typography>
							<TextField
								label="Paga i senyal"
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
								}}
								{...register("preu_ps")}
							/>
							<Box my={3} />
							{fields.map((item, index) => (
								<Stack key={item.id} mb={2} spacing={2} direction="row">
									<TextField label="Persones" type="number" {...register(`preus.${index}.persones`)} />
									<TextField
										label="Preu"
										type="number"
										inputProps={{
											step: "0.01",
										}}
										InputProps={{
											endAdornment: <InputAdornment position="end">€</InputAdornment>,
										}}
										{...register(`preus.${index}.preu`)}
									/>
									<Controller
										name={`preus.${index}.joc`}
										control={control}
										render={({ field }) => (
											<Tooltip title={"Joc terror"}>
												<FormControlLabel
													style={{ margin: 0 }}
													control={
														<Checkbox
															onChange={(e) => field.onChange(e.target.checked)}
															size={"small"}
															checked={field.value === "1" || field.value === true}
														/>
													}
												/>
											</Tooltip>
										)}
									/>
									<IconButton size="small" onClick={() => remove(index)}>
										<DeleteOutline size={15} />
									</IconButton>
								</Stack>
							))}
							<Box textAlign="center">
								<IconButton
									onClick={() =>
										append({
											persones: null,
											preu: null,
											joc: 0,
										})
									}
									color="primary"
								>
									<Add />
								</IconButton>
								<IconButton onClick={() => replace([])}>
									<DeleteForever />
								</IconButton>
							</Box>
						</Box>
					</Loading>

					<Stack direction={"row"} spacing={2}>
						<CustomButton title="Tancar" fullWidth danger onClick={() => setOpen(false)} />
						<CustomButton type="submit" title="Guardar" fullWidth loading={loading} />
					</Stack>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default HabitacioAddDialog;
