import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, Divider, Fab, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/elements/CustomButton";
import paper from "../../../assets/img/paper-popup.png";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import "moment/locale/es";
import "moment/min/moment-with-locales";
import { addCistella, getElements } from "../../../API/API";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router";
import DialogReservaHotelInfo from "./componentsH/DialogReservaHotelInfo";
import { esJoc } from "../../../components/Utils";
import DialogReservaCalendariHotel from "./componentsH/DialogReservaHotelCalendari";
import DialogReservaHotelPreu from "./componentsH/DialogReservaHotelPreu";
import DialogReservaHotelKruggerInfo from "./componentHK/DialogReservaHotelKruggerInfo";
import DialogReservaCalendariHotelKrugger from "./componentHK/DialogReservaHotelCalendariKrugger";

export default function DialogReservaHotelKrugger({ select, open, setOpen }) {
	const [data, setData] = useState();
	const [jugadors, setJugadors] = useState();
	const [habitacio, setHabitacio] = useState();
	const [preu, setPreu] = useState("-");
	const [habitacions, setHabitacions] = useState([]);
	const [cart, setCart] = useLocalStorage("cart", []);
	const [loading, setLoading] = useState(true);
	const [dataJoc, setDataJoc] = useState([]);
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [error, setError] = useState(false);

	const handleClose = () => {
		setJugadors();
		setData();
		setHabitacio();
		setPreu();
		setOpen(false);
	};

	useEffect(() => {
		const obtenir = async () => {
			const habitacions = await getElements("habitacions");
			const dataJoc = await getElements("datajoc");
			setHabitacions(habitacions.result);
			setDataJoc(dataJoc.result);
			setLoading(false);
		};
		obtenir();
	}, []);

	const addCard = async (nav) => {
		setLoading(true);
		const { message, code } = await addCistella({
			data: moment(data).format("YYYY-MM-DD") + " 00:00:00",
			producte_id: habitacio.id,
		});

		if (code === 200) {
			let result;
			result = [...cart];
			result.push({
				index: result.length + 1,
				quantitat: jugadors,
				data: moment(data).format("YYYY-MM-DD"),
				producte_id: habitacio.id,
				preu: preu * jugadors,
				producte: habitacio,
				hora: "00:00:00",
				pagat: Number(habitacio?.habitacio.preu_ps),
				joc: esJoc(data, dataJoc),
				created_at: moment(),
			});
			setCart(result);
			enqueueSnackbar("Añadido a la cesta", {
				variant: "success",
			});
			setError(false);
			if (nav) {
				navigate("/reserves/checkout");
			}
			handleClose();
		} else {
			enqueueSnackbar(message, {
				variant: "error",
			});
			setError(true);
		}
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
					background: `url(${paper})`,
					backgroundRepeat: "round",
					backgroundSize: "cover",
					padding: matches ? 30 : 0,
				},
			}}
			maxWidth="lg"
			fullScreen={!matches}
		>
			<DialogContent>
				<Box className={classes.close}>
					<Fab classesName={classes.closeButton} size="small" color="secondary" onClick={handleClose}>
						<Close sx={{ fontSize: 30 }} />
					</Fab>
				</Box>
				<DialogReservaHotelKruggerInfo />
				<Divider sx={{ border: 1 }} />
				<DialogReservaCalendariHotelKrugger
					data={data}
					jugadors={jugadors}
					loading={loading}
					select={select}
					setData={setData}
					setJugadors={setJugadors}
					habitacions={habitacions}
					habitacio={habitacio}
					dataJoc={dataJoc}
					setHabitacio={setHabitacio}
					setPreu={setPreu}
					setLoading={setLoading}
				/>
				<Divider
					sx={{
						border: 1,
						marginBottom: matches ? 1 : 3,
						marginTop: matches ? 1 : 3,
					}}
				/>
				<DialogReservaHotelPreu
					addCard={addCard}
					data={data}
					dataJoc={dataJoc}
					habitacio={habitacio}
					jugadors={jugadors}
					preu={preu}
					error={error}
					loading={loading}
				/>
			</DialogContent>
		</Dialog>
	);
}

const useStyles = makeStyles((theme) => ({
	img: {
		width: 50,
		height: 50,
	},
	close: {
		position: "absolute",
		top: 20,
		right: 20,
		zIndex: 100,
	},
	closeButton: {
		backgroundColor: theme.color.secondary,
	},
}));
