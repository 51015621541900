import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../../theme";
import CustomButton from "../../../components/elements/CustomButton";
import { refund } from "../../../API/API";
import { useSnackbar } from "notistack";

function ReservaDialogRefund({ setUpdate, open, setOpen, reserva }) {
	const [producte, setProducte] = useState();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleChange = (event) => {
		setProducte(event.target.value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const retornar = async () => {
		setLoading(true);
		const { message } = await refund(producte);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setUpdate((prev) => prev + 1);
		setOpen(false);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="sm"
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Devolució concepte
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box my={3}>
					<Typography variant="h3" mt={4}>
						Segur que vols fer la devolució?
					</Typography>
					<Typography mb={4}>
						S'eliminarà del Google Calendar corresponent i quedarà
						la data lliure. A més, es retornarà l'import especificat
						al client.
					</Typography>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">
							Sel·lecciona el producte
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={producte}
							label="Sel·lecciona el producte"
							onChange={handleChange}
						>
							{reserva?.reserva_elements?.map(
								(item) =>
									item?.pagat !== "0" && (
										<MenuItem
											value={item?.id}
											key={item?.id}
										>
											{item?.producte.nom} - {item?.pagat}{" "}
											€
										</MenuItem>
									)
							)}
						</Select>
					</FormControl>
				</Box>
				<CustomButton
					onClick={retornar}
					title={"Retornar"}
					fullWidth
					loading={loading}
				/>
			</DialogContent>
		</Dialog>
	);
}

export default ReservaDialogRefund;
