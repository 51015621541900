import { Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import ReactPlayer from "react-player";
import video from "../../assets/video/bg-insomnia.mp4";
import bg_mobil_gif from "../../assets/img/bg-mobil.gif";

function Home() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();

	return (
		<Page title="Insomnia Corporation">
			<Box>
				{matches ? (
					<Box className={classes.portada}>
						<ReactPlayer playing={true} loop url={[{ src: video }]} height="100%" width="100%" className={classes.player} controls={false} muted />
					</Box>
				) : (
					<Box className={classes.portadaMobil} />
				)}
			</Box>
		</Page>
	);
}

export default Home;

const useStyles = makeStyles((theme) => ({
	portada: {
		position: "relative",
		paddingTop: "56.25%",
		overflow: "hidden",
	},
	portadaMobil: {
		background: `url(${bg_mobil_gif})`,
		height: "100vh",
		backgroundSize: "contain",
		backgroundPosition: "center top",
		backgroundRepeat: "no-repeat",
	},
	player: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
}));
