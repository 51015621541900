import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { addDataJoc, getElements } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import Loading from "../../../components/layouts/Loading";
import { esJoc, quinJoc } from "../../../components/Utils";

function AddDataJoc({
	setLoading,
	setUpdate,
	handleClose,
	loading,
	setOpen,
	dateSel,
	dataJoc,
}) {
	const [joc, setJoc] = useState(0);
	const [jocs, setJocs] = useState([]);
	const [loadingIn, setLoadingIn] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("jocs");
			setJocs(result);
			setLoadingIn(false);
		};
		obtenir();
	}, []);

	useEffect(() => {
		const jocAct = quinJoc(dateSel, dataJoc);
		setJoc(jocAct ? jocAct?.id : 0);
	}, [dateSel, dataJoc, jocs]);

	const crear = async () => {
		const values = { data: dateSel, joc_id: joc ? joc : 0 };
		setLoading(true);
		const { message } = await addDataJoc(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		handleClose();
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<Box>
			<Box pb={5}>
				<Loading loading={loadingIn}>
					<FormControl>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue={joc}
							name="joc_id"
						>
							{jocs?.map((item) => (
								<FormControlLabel
									value={item.id}
									control={<Radio />}
									label={item.nom}
									onChange={() => setJoc(item.id)}
								/>
							))}
							<FormControlLabel
								value={0}
								control={<Radio />}
								label={"Res"}
								onChange={() => setJoc()}
							/>
						</RadioGroup>
					</FormControl>
				</Loading>
			</Box>
			<Stack direction={"row"} spacing={2}>
				<CustomButton
					title="Canel·lar"
					fullWidth
					danger
					onClick={() => setOpen(false)}
				/>
				<CustomButton
					onClick={() => crear()}
					title="Guardar"
					fullWidth
					loading={loading}
				/>
			</Stack>
		</Box>
	);
}

export default AddDataJoc;
