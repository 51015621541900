import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, Divider, Fab, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/elements/CustomButton";
import paper from "../../../assets/img/paper-popup.png";
import { makeStyles } from "@mui/styles";
import "../../../theme/calStyle.css";
import moment from "moment";
import "moment/locale/es";
import "moment/min/moment-with-locales";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router";
import { addCistella, addElement, getElement } from "../../../API/API";
import DialogReservaInfo from "./componentsSR/DialogReservaInfo";
import DialogReservaCalendari from "./componentsSR/DialogReservaCalendari";
import DialogReservaPreu from "./componentsSR/DialogReservaPreu";

function DialogReserva({ select, open, setOpen, scapes = [], setSelect = false }) {
	const [data, setData] = useState("");
	const [jugadors, setJugadors] = useState();
	const [hora, setHora] = useState();
	const [preu, setPreu] = useState(0);
	const [cart, setCart] = useLocalStorage("cart", []);
	const [exclude, setExclude] = useState([]);
	const [reserves, setReserves] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const matches = useMediaQuery("(min-width:960px)");
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const navigate = useNavigate();

	const handleClose = () => {
		setJugadors();
		setData();
		setHora();
		setPreu();
		setOpen(false);
	};

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("getOcupatsScapes", select?.id);
			setReserves(result);
			let ex = [];
			Object.entries(result).forEach(([key, value]) => {
				const dataKey = moment(key);
				const noDisp = JSON.parse(select?.scaperoom.dies).filter((item) => String(item.dia) === dataKey.format("e"))[0]?.hores;

				if (value.length >= noDisp?.length) ex.push(dataKey.toDate());
			});
			setExclude(ex);
			setLoading(false);
		};
		if (select?.id) obtenir();
	}, [select]);

	useEffect(() => {
		setHora();
	}, [data]);

	const addCard = async (nav) => {
		setLoading(true);
		const { message, code } = await addCistella({
			data: moment(data).format("YYYY-MM-DD") + " " + hora,
			producte_id: select.id,
		});

		let result;
		if (code === 200) {
			result = [...cart];
			result.push({
				index: result.length + 1,
				quantitat: jugadors,
				data: moment(data).format("YYYY-MM-DD"),
				producte_id: select.id,
				preu: preu * jugadors,
				producte: select,
				hora: hora,
				pagat: Number(select.pagament_complet) ? preu * jugadors : Number(select?.scaperoom.preu_ps),
				joc: 0,
				created_at: moment(),
			});
			setCart(result);
			enqueueSnackbar(message, {
				variant: "success",
			});
			setError(false);
			if (nav) {
				navigate("/reserves/checkout");
			}
			handleClose();
		} else {
			enqueueSnackbar(message, {
				variant: "error",
			});
			setError(true);
		}
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
					background: `url(${paper})`,
					backgroundRepeat: "round",
					backgroundSize: "cover",
					padding: matches ? 30 : 0,
				},
			}}
			maxWidth="lg"
			fullScreen={!matches}
		>
			<DialogContent>
				<Box className={classes.close}>
					<Fab classesName={classes.closeButton} size="small" color="secondary" onClick={handleClose}>
						<Close sx={{ fontSize: 30 }} />
					</Fab>
				</Box>
				<DialogReservaInfo select={select} scapes={scapes} setSelect={setSelect} />
				<Divider sx={{ border: 1 }} />
				<DialogReservaCalendari
					data={data}
					exclude={exclude}
					hora={hora}
					jugadors={jugadors}
					loading={loading}
					reserves={reserves}
					select={select}
					setData={setData}
					setHora={setHora}
					setJugadors={setJugadors}
					setPreu={setPreu}
				/>
				<Divider sx={{ border: 1, marginBottom: 2 }} />
				<DialogReservaPreu addCard={addCard} data={data} hora={hora} jugadors={jugadors} preu={preu} select={select} error={error} loading={loading} />
			</DialogContent>
		</Dialog>
	);
}

export default DialogReserva;

const useStyles = makeStyles((theme) => ({
	img: {
		width: 50,
		height: 50,
	},
	close: {
		position: "absolute",
		top: 20,
		right: 20,
		zIndex: 100,
	},
	closeButton: {
		backgroundColor: theme.color.secondary,
	},
}));
