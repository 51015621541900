import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import CustomButton from "../../../../components/elements/CustomButton";
import theme from "../../../../theme";

function DialogReservaPreu({ hora, data, jugadors, preu, addCard, select, admin, error, loading }) {
	const matches = useMediaQuery("(min-width:960px)");
	return (
		<Grid spacing={5} container>
			<Grid item md={7} my={1} xs={12} textAlign={matches ? "left" : "center"}>
				<Typography>
					{data ? moment(data).locale("es").format("LL") : " - "} a las {hora ? moment(hora, "HH:mm:ss").format("HH:mm") + " h" : "-"} para{" "}
					{jugadors ?? "-"} personas
				</Typography>
				<Typography variant="h2" fontWeight={700}>
					{preu !== "-" ? Math.round(preu * 100) / 100 : "-"} €<span style={{ fontSize: 16 }}>/persona</span>
				</Typography>
				{Number(select?.pagament_complet) ? (
					<Typography variant="body1" fontWeight={700}>
						Precio a pagar: {jugadors ? preu * jugadors : "-"} €
					</Typography>
				) : (
					<Typography variant="body1" fontWeight={700}>
						Precio a pagar: {select?.scaperoom.preu_ps} €
					</Typography>
				)}
				{!admin &&
					(Number(select?.pagament_complet) ? (
						<Typography variant="caption" color={theme.color.background}>
							Esta actividad tiene que ser abonada al completo.
						</Typography>
					) : (
						<Typography variant="caption" color={theme.color.background}>
							El precio a pagar corresponde a una paga y señal. El resto deberà abonarse el dia de la actividad.
						</Typography>
					))}
			</Grid>
			{!admin && (
				<Grid item md={5} my={2} xs={12}>
					<Stack direction={"row"} spacing={2} mb={1}>
						<CustomButton
							title="Añadir al carro y comprar más"
							fullWidth
							onClick={() => addCard(false)}
							disabled={!preu || !hora || !data || !jugadors || loading}
						/>
						<CustomButton
							type="submit"
							title="Reservar ahora"
							fullWidth
							onClick={() => {
								addCard(true);
							}}
							disabled={!preu || !hora || !data || !jugadors || loading}
						/>
					</Stack>
					{error && (
						<Typography variant="error">
							Hay un cliente que tiene esta reserva en su cesta. Si no confirma la reserva, esta hora estarà disponible dentro de 10 minutos.
						</Typography>
					)}
				</Grid>
			)}
		</Grid>
	);
}

export default DialogReservaPreu;
