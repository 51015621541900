import { Add, Close, Delete, Edit } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CartaItem from "./CartaItem";
import { deleteElement } from "../../../API/API";
import { useSnackbar } from "notistack";

function CartaCategoriaItem({ item, setSelect, setOpen, setUpdate, setOpenCarta, setSelectCarta }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const handleEdit = () => {
		setSelect(item);
		setOpen(true);
	};

	const handleCarta = () => {
		setSelectCarta("");
		setOpenCarta(true);
		setSelect(item);
	};

	const handleDelete = async () => {
		const { message } = await deleteElement("carta_categories", item.id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpenCarta(false);
		setUpdate((prev) => prev + 1);
	};

	return (
		<Grid item md={4}>
			<Box className={classes.item} textAlign="center">
				<Typography variant="h3">{item.nom}</Typography>
				{item?.cartes?.map((carta) => (
					<CartaItem carta={carta} setOpenCarta={setOpenCarta} setSelectCarta={setSelectCarta} setUpdate={setUpdate} />
				))}
				<Box>
					<IconButton onClick={handleCarta}>
						<Add />
					</IconButton>
				</Box>
				<Box className={classes.edit}>
					<IconButton onClick={handleEdit}>
						<Edit />
					</IconButton>
					<IconButton size="small" onClick={handleDelete}>
						<Close />
					</IconButton>
				</Box>
			</Box>
		</Grid>
	);
}

export default CartaCategoriaItem;

const useStyles = makeStyles((theme) => ({
	item: {
		backgroundColor: "white",
		padding: 20,
		position: "relative",
	},
	edit: {
		position: "absolute",
		top: 10,
		right: 10,
	},
}));
