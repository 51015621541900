import { Circle } from "@mui/icons-material";
import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";

const renderEventContent = (event) => {
	return (
		<Tooltip
			title={event.event.textColor + " - " + event.event.title}
			style={{ cursor: "pointer" }}
		>
			<Box
				overflow={"hidden"}
				display="flex"
				alignItems={"center"}
				cursor="pointer"
			>
				{event.event.groupId === "1" && (
					<Circle
						sx={{
							color: event.backgroundColor,
							width: 12,
							marginRight: 0.3,
						}}
					/>
				)}

				<Typography
					variant="caption"
					color={
						event.event.overlap === "booking"
							? "secondary"
							: "primary"
					}
					fontSize={13}
					letterSpacing={-0.4}
					lineHeight={"inherit"}
				>
					{event.event.groupId === "1" && <b>{event.timeText} -</b>}
					{"  "}
					{event.event.title}
				</Typography>
			</Box>
		</Tooltip>
	);
};

export default renderEventContent;
