import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import theme from "../../theme";

const BootstrapButton = styled(Button)({
	boxShadow: "none",
	textTransform: "none",
	fontSize: 18,
	padding: "10px 20px",
	borderRadius: 0,
	lineHeight: 1,
	color: theme.color.secondary,
	fontFamily: "Rokkitt",
	background: theme.color.background,
	border: "2px solid " + theme.color.background,
	width: 150,
	"&:hover": {
		// backgroundColor: "white",
		border: "2px solid " + theme.color.background,
		color: "black",
		boxShadow: "none",
		background: "transparent",
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: theme.color.background,
		borderColor: theme.color.background,
	},
	"&:focus": {
		boxShadow: "0 0 0 0.2rem " + theme.color.background,
	},
});

const BootstrapButtonLight = styled(Button)({
	boxShadow: "none",
	textTransform: "none",
	fontSize: 18,
	padding: "10px 20px",
	borderRadius: 0,
	lineHeight: 1,
	color: theme.color.background,
	fontFamily: "Rokkitt",
	background: theme.color.secondary,
	border: "2px solid " + theme.color.secondary,
	width: 150,
	"&:hover": {
		// backgroundColor: "white",
		border: "2px solid " + theme.color.secondary,
		color: theme.color.secondary,
		boxShadow: "none",
		background: "transparent",
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: theme.color.secondary,
		borderColor: theme.color.secondary,
	},
	"&:focus": {
		boxShadow: "0 0 0 0.2rem " + theme.color.secondary,
	},
});

const BootstrapButtonDanger = styled(Button)({
	boxShadow: "none",
	textTransform: "none",
	fontSize: 18,
	padding: "10px 20px",
	borderRadius: 0,
	lineHeight: 1,
	color: theme.color.secondary,
	// border: "1px solid white",
	fontFamily: "Rokkitt",
	background: theme.color.primary,
	width: 150,
	"&:hover": {
		// backgroundColor: "white",
		color: "black",
		borderColor: theme.color.background,
		boxShadow: "none",
		background: theme.color.secondary,
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: theme.color.background,
		borderColor: theme.color.background,
	},
	"&:focus": {
		boxShadow: "0 0 0 0.2rem " + theme.color.background,
	},
});

const CustomButton = ({
	title,
	danger,
	loading,
	fullWidth,
	light,
	...rest
}) => {
	return danger ? (
		<BootstrapButtonDanger
			style={{ width: fullWidth ? "100%" : "auto" }}
			{...rest}
			variant="contained"
		>
			{loading ? <CircularProgress size={18} color="info" /> : title}
		</BootstrapButtonDanger>
	) : light ? (
		<BootstrapButtonLight
			style={{ width: fullWidth ? "100%" : "auto" }}
			{...rest}
			variant="contained"
		>
			{loading ? <CircularProgress size={18} color="info" /> : title}
		</BootstrapButtonLight>
	) : (
		<BootstrapButton
			style={{ width: fullWidth ? "100%" : "auto" }}
			{...rest}
			variant="contained"
		>
			{loading ? <CircularProgress size={18} color="info" /> : title}
		</BootstrapButton>
	);
};

CustomButton.propTypes = {
	danger: PropTypes.bool,
	loading: PropTypes.bool,
	title: PropTypes.string,
};

export default CustomButton;
