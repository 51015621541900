import { Avatar, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function Pill({ title, icon, label, color }) {
	const classes = useStyles();
	return (
		<Box className={classes.pill} style={{ backgroundColor: color + "90" }}>
			<Avatar sx={{ bgcolor: color }} variant="rounded">
				{icon}
			</Avatar>
			<Box>
				<Typography variant="body1" color="white" ml={1}>
					{label}
				</Typography>
				<Typography
					variant="caption"
					color="white"
					ml={1}
					letterSpacing={0}
				>
					{title}
				</Typography>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	pill: {
		display: "flex",
		alignItems: "center",
		padding: 10,
	},
}));
