import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Page from "../../../components/layouts/Page";
import theme from "../../../theme";
import sanefa from "../../../assets/img/habitacions/sanefa.svg";
import bg from "../../../assets/img/fons-2.jpg";
import { CartesHotel } from "../../../components/elements/CartesHotel";
import hotelImg from "../../../assets/img/entradas.jpg";
import saloImg from "../../../assets/img/hotel/salo-1.jpg";
import paper from "../../../assets/img/paper-popup.png";
import DialogComprarTicket from "./DialogComprarTicket";

const imatges = [{ foto: hotelImg }, { foto: saloImg }];

export default function Tickets() {
	const [open, setOpen] = useState();
	const [selected, setSelected] = useState();
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();

	const selectTicket = (sel) => {
		setOpen(true);
		setSelected(sel);
	};

	return (
		<Page title="Insomnia Corporation - Tickets Sorteo">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Grid spacing={8} container>
						<Grid item md={6} xs={12} mb={5}>
							{matches && <CartesHotel imatges={imatges} />}
						</Grid>

						<Grid item md={6} xs={12} zIndex={10}>
							{!matches && (
								<Box className={classes.wrapImg} my={4}>
									<img src={hotelImg} alt={"Insomnia Hotel"} className={classes.img} />
								</Box>
							)}
							<Box>
								<Typography variant="h3" pb={0} color={theme.color.secondary}>
									¡Participa en el Sorteo de Reyes de Insomnia Hotel
								</Typography>
								<Typography variant="h2" pb={3} color={theme.color.secondary}>
									y gana una increíble panera valorada en más de <strong>1000€</strong>!
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
							</Box>
							<Typography pt={3} variant="body1" color={theme.color.secondary}>
								Este año, Insomnia Hotel celebra el Sorteo del Niño con una oportunidad única para nuestros seguidores. Participa en nuestro
								sorteo y podrías llevarte una panera valorada en más de 1000€ en experiencias y productos exclusivos de Insomnia. 🎉
							</Typography>
							<Typography pt={5} variant="h3" color={theme.color.secondary}>
								¿Qué incluye la panera?
							</Typography>

							<Typography py={0} variant="body1" color={theme.color.secondary}>
								<ul>
									<li>El Secreto de los Krugger en habitación doble: 340€</li>
									<li>Noche en Insomnia Hotel para dos personas con cena incluida: 210€</li>
									<li>El Cóctel del Doctor escape room para dos personas: 80€</li>
									<li>La Casa escape room para dos: 90€</li>
									<li>Camiseta logo Insomnia: 15€</li>
									<li>Camiseta "I Survived" del Secreto de los Krugger: 15€</li>
									<li>Camiseta logo Insomnia Hotel: 15€</li>
									<li>Camiseta 10º aniversario del Cóctel del Doctor: 18€</li>
									<li>Sudadera logo Insomnia: 30€</li>
									<li>Taza logo Insomnia: 10€</li>
									<li>Taza logo Insomnia Hotel: 10€</li>
									<li>Taza "I Survived": 10€</li>
									<li>Fantástica Panera de Navidad: 200€</li>
								</ul>
							</Typography>
							<Box my={10}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>

							<Typography py={2} variant="h3" color={theme.color.secondary}>
								¿Cómo participar?
							</Typography>
							<Typography pt={3} variant="body1" color={theme.color.secondary}>
								Elige uno de los siguientes paquetes para obtener tus participaciones:
							</Typography>
							<Grid container spacing={4} mt={2}>
								<Grid item md={4}>
									<Box className={classes.participacio} onClick={() => selectTicket(1)}>
										<Typography variant="body1" color={theme.color.secondary}>
											Comprar
										</Typography>
										<Typography variant="h1" color={theme.color.secondary}>
											1€
										</Typography>
										<Typography variant="body2" color={theme.color.secondary}>
											2 participaciones
										</Typography>
									</Box>
								</Grid>
								<Grid item md={4}>
									<Box className={classes.participacio} onClick={() => selectTicket(3)}>
										<Typography variant="body1" color={theme.color.secondary}>
											Comprar
										</Typography>
										<Typography variant="h1" color={theme.color.secondary}>
											3€
										</Typography>
										<Typography variant="body2" color={theme.color.secondary}>
											7 participaciones
										</Typography>
									</Box>
								</Grid>
								<Grid item md={4}>
									<Box className={classes.participacio} onClick={() => selectTicket(5)}>
										<Typography variant="body1">Comprar</Typography>
										<Typography variant="h1">5€</Typography>
										<Typography variant="body2">12 participaciones</Typography>
									</Box>
								</Grid>
							</Grid>
							<Typography pt={3} variant="body1" color={theme.color.secondary}>
								El ganador será aquel cuyo boleto coincida con los últimos cuatro dígitos del número premiado en el Sorteo del Niño. ¡Así de
								simple!
							</Typography>

							<Box my={10}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Typography py={2} variant="h3" color={theme.color.secondary}>
								Bases Legales del Sorteo
							</Typography>
							<Typography py={2} variant="h5" color={theme.color.secondary}>
								Organización
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								Este sorteo es organizado por Insomnia Hotel y no está asociado, patrocinado, ni administrado por ninguna red social u otra
								plataforma externa.
							</Typography>
							<Typography py={2} variant="h5" color={theme.color.secondary}>
								Premio
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								El premio consiste en una panera valorada en más de 1000€, que incluye experiencias y productos exclusivos de Insomnia
								Corporation y muchas sorpresas.
							</Typography>
							<Typography py={2} variant="h5" color={theme.color.secondary}>
								Participación
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								<ul>
									<li>
										Los participantes pueden adquirir participaciones en formato digital a través de nuestra página web o puntos
										autorizados.
									</li>
									<li>
										Cada participación tendrá un valor según el paquete seleccionado (2 participaciones por 1€, 7 participaciones por 3€, o
										12 participaciones por 5€).
									</li>
									<li>La adquisición de participaciones estará abierta hasta el 5 de enero a las 23:59.</li>
								</ul>
							</Typography>
							<Typography py={2} variant="h5" color={theme.color.secondary}>
								Mecánica del Sorteo y Elección del Ganador
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								El ganador será determinado el 6 de enero, tomando como referencia los últimos cuatro dígitos del primer premio del Sorteo del
								Niño.
							</Typography>
							<Typography py={2} variant="h5" color={theme.color.secondary}>
								Normas y condiciones
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								<ol>
									<li>
										<strong>Requisitos de Edad</strong>: Solo podrán participar personas mayores de 18 años.
									</li>
									<li>
										<strong>Intransferibilidad</strong>: El premio no es transferible ni canjeable por su valor en metálico ni por otros
										bienes o servicios distintos a los indicados.
									</li>
									<li>
										<strong>Plazo de Reclamo y Disfrute del Premio</strong>
										<ol>
											<li>
												<strong>Plazo de Reclamo</strong>: El ganador deberá ponerse en contacto en un plazo de 15 días naturales tras
												el anuncio oficial. En caso contrario, se seleccionará a un nuevo ganador.
											</li>
											<li>
												<strong>Disfrute del Premio</strong>: El ganador dispondrá de un periodo de seis meses para disfrutar y canjear
												todos los premios incluidos en la panera.
											</li>
											<li>
												<strong>Recogida de la Panera</strong>: El ganador/a del sorteo deberá acudir al Insomnia Hotel para recoger la
												panera y presentar el justificante premiado como comprobante.
											</li>
										</ol>
									</li>
									<li>
										<strong>Modificaciones y Cancelaciones</strong>: Insomnia Hotel se reserva el derecho de modificar o cancelar el sorteo
										en caso de fuerza mayor, así como de cambiar las fechas de participación o anuncio si fuese necesario.
									</li>

									<li>
										<strong>Descalificación</strong>: Cualquier intento de fraude o conducta inapropiada en el proceso de participación será
										motivo de descalificación inmediata.
									</li>

									<li>
										<strong>Privacidad y Datos Personales</strong>: Los datos personales de los participantes se gestionarán de acuerdo con
										la normativa vigente sobre protección de datos y serán utilizados exclusivamente para la gestión del sorteo.
									</li>
								</ol>
							</Typography>

							<Typography py={2} variant="h5" color={theme.color.secondary}>
								Responsabilidades
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								Insomnia Hotel no se responsabiliza por problemas técnicos, fallos de comunicación, errores tipográficos, o información
								incorrecta proporcionada por los participantes que imposibiliten el contacto con el ganador.
							</Typography>
							<Typography py={2} variant="body1" color={theme.color.secondary}>
								<strong>¡No te quedes fuera de esta experiencia única!</strong> Aprovecha esta oportunidad y comienza el año de la mejor manera
								con una increíble panera de Insomnia Hotel valorada en <strong>más de 1000€</strong>.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<DialogComprarTicket select={selected} open={open} setOpen={setOpen} />
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 40,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(5deg)",
		},
		textAlign: "center",
	},

	wrapImg2: {
		transform: "rotate(5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.color.secondary,
	},
	calendari: {
		background: `url(${paper})`,
		padding: 40,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		marginBottom: 100,
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			padding: 10,
		},
	},
	participacio: {
		backgroundColor: theme.color.primary,
		padding: 10,
		textAlign: "center",
		transition: "0.2s",
		cursor: "pointer",
		borderRadius: 10,
		"& .MuiTypography-root": {
			color: theme.color.secondary,
			transform: "scale(1.05)",
		},
		"&:hover": {
			transform: "scale(1.05)",
			backgroundColor: theme.color.secondary,
			"& .MuiTypography-root": {
				color: theme.color.primary,
			},
		},
		"-webkit-mask-image":
			"radial-gradient(circle 10px at 0 50%, transparent 0, transparent 20px, black 21px), radial-gradient(circle 10px at 100% 50%, transparent 0, transparent 20px, black 21px)",
		"-webkit-mask-composite": "destination-in",
	},
}));
