import { Check, Close, Email, RemoveRedEye } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";

const CuponsColumns = (cupons, setOpen, setCupo, setOpenMail) => {
	const navigate = useNavigate();

	const columns = [
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "preu",
			label: "Preu",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value + " €",
			},
		},
		{
			name: "codi",
			label: "Codi",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "gastat",
			label: "Gastat",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (Number(value) ? <Check color="success" /> : <Close color="red" />),
			},
		},

		{
			name: "reserva_element.reserva",
			label: "Reserva compra",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value && <Chip onClick={() => navigate("/admin/reserves/" + value?.unique_id)} label={value?.unique_id} />,
			},
		},
		{
			name: "reserva",
			label: "Reserva asignada",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value && <Chip onClick={() => navigate("/admin/reserves/" + value?.unique_id)} label={value?.unique_id} />,
			},
		},
		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<>
							<IconButton
								onClick={() => {
									setCupo(cupons[dataIndex]);
									setOpen(true);
								}}
							>
								<RemoveRedEye />
							</IconButton>
							<IconButton
								onClick={() => {
									setCupo(cupons[dataIndex]);
									setOpenMail(true);
								}}
							>
								<Email />
							</IconButton>
						</>
					);
				},
			},
		},
	];
	return columns;
};

export default CuponsColumns;
