import { ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../../../theme/TableStyle";
import TableOptions from "../../../components/columns/TableOptions";
import CompraTicketsColumns from "../../../components/columns/CompraTicketsColumns";

export default function CompraTicketsAdmin() {
	const [tickets, setTickets] = useState([]);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(0);
	const options = TableOptions();
	const columns = CompraTicketsColumns(tickets);

	useEffect(() => {
		const obtenir = async () => {
			const tickets = await getElements("compraTickets");
			setTickets(tickets.result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	return (
		<PageAdmin title="Compra de tickets">
			<Loading loading={loading}>
				<ThemeProvider theme={getMuiTheme()}>
					<MUIDataTable data={tickets} columns={columns} options={options} />
				</ThemeProvider>
			</Loading>
		</PageAdmin>
	);
}
