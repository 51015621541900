import { Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { addElement, updateElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import theme from "../../../theme";

function CupoDialogMail({ open, setOpen, setUpdate, cupo, setCupo }) {
	const { register, reset, handleSubmit, control } = useForm();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		cupo && open && reset(cupo);
	}, [cupo, open]);

	const handleClose = () => {
		reset({ nom: "", preu: "" });
		setCupo("");
		setOpen(false);
	};

	const crear = async (values) => {
		setLoading(true);
		const { message } = await updateElement("cuponsMail", cupo.id, values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
		>
			<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
				<Box display="flex" justifyContent={"space-between"}>
					Enviar Cupó per E-mail
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit(crear)}>
					<Box mt={3}>
						<Box mb={3}>
							<TextField {...register("email")} fullWidth label="E-mail" />
						</Box>

						<Divider />
						<Stack direction={"row"} spacing={2}>
							<CustomButton title="Tancar" fullWidth danger onClick={handleClose} />
							<CustomButton type="submit" title={"Enviar"} fullWidth loading={loading} />
						</Stack>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default CupoDialogMail;
