import { Add, Close } from "@mui/icons-material";
import { Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import theme from "../../theme";
import CustomButton from "./CustomButton";

export function FAQS() {
	const [collapse, setCollapse] = useState(false);

	return (
		<Box mb={4}>
			<Typography variant="body1" color={theme.color.secondary}>
				¿Qué es un Escape Room?
			</Typography>
			<Typography pb={2} variant="body1" color={theme.color.primary}>
				- Un Escape Room es un juego en el que un grupo de personas deberéis resolver enigmas para poder cumplir el objetivo antes de que se agote
				tiempo. Siempre seréis monitorizados por un Game Master el cual os ayudará ofreciendo pistas siempre que lo necesitéis.
			</Typography>
			<Typography variant="body1" color={theme.color.secondary}>
				¿Cuántas personas pueden jugar?
			</Typography>
			<Typography pb={2} variant="body1" color={theme.color.primary}>
				- Nuestros juegos están diseñados para grupos de 2 a 6 personas, siendo 4 personas el grupo aconsejado.
			</Typography>

			<Collapse in={collapse}>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Hay límite de edad?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- El juego está recomendado para personas mayores de 18 años, pueden acudir a partir de 16 años siempre acompañados por un adulto. Queda
					totalmente prohibida la entrada a menores de edad sin supervisión de un adulto.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Dan mucho miedo?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Nuestros Escape Room están diseñados para ofrecer terror psicológico, jamás os van a tocar o mucho menos agarrar, pero son de terror y no
					serían aconsejables para gente que no tolere el miedo.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					Si tengo mucho miedo ¿puedo abandonar?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Por supuesto. Siempre estaréis monitorizados por nuestro Game Master y con la posibilidad de salir siempre que queráis.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Qué pasa si tengo claustrofobia, problemas cardíacos, epilepsia, etc.?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Aconsejamos llamarnos al 689153847 para poder resolver tus dudas. En nuestros Escape rooms siempre hay una persona vigilando que podrá
					poner fin al juego si alguien lo necesita.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Puede realizar la actividad una persona en silla de ruedas?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- No, debido a que nuestros escapes están ubicados en masías centenarias y transcurren en salas con diferentes alturas.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Se puede pagar con tarjeta?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Sí, aceptamos pago con tarjeta y en efectivo.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Qué pasa si quiero cancelar mi reserva y recuperar mi dinero?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- BAJO NINGÚN CONCEPTO SE REALIZARÁ NINGUNA DEVOLUCIÓN DE DINERO. En el momento de aceptar la reserva se acepta la condición de que en
					ningún caso se realizaran devoluciones de dinero.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Si la persona que ha realizado la reserva no viene, pueden jugar otras personas en su lugar?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Sí, pueden jugar otras personas, pero es importante que nos lo comuniquéis por e-mail o teléfono para poder cambiar el nombre, e-mail y
					teléfono de la reserva.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Se puede usar el WC?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- En nuestros Escape Rooms tenemos WC al acabar las experiencias.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Qué pasa si llegamos tarde o no nos presentamos?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- La puntualidad es muy importante, en caso de llegar tarde, podéis avisarnos e intentaremos buscar una solución. En el caso de no haber
					solución, lamentablemente tendremos que cancelar la sesión sin derecho a cambio de día/hora ni reembolso de la paga y señal.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Qué ropa debemos llevar?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Ropa cómoda/normal, pensad que puede darse el caso de que os manchéis. Está prohibido jugar con tacones.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Podéis negarnos la entrada o detener el juego una vez iniciado?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Sí, el Game Master puede negar la entrada o detener el juego si lo ve necesario en todas estas situaciones. Si esto ocurre, se prohibirá
					el acceso sin derecho a devolución del dinero de la paga y señal ni cambio de día.
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Alguno o todos los miembros del grupo está o están bajo los efectos del alcohol u otras sustancias.
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Se detecta una conducta violenta por parte de alguna persona o de todo el grupo. Queda totalmente prohibido tocar al Game Master u otros
					trabajadores.
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Tratar elementos de la sala de forma poco respetuosa.
				</Typography>
				<Typography variant="body1" color={theme.color.secondary}>
					¿Me pueden cancelar/aplazar la reserva?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Sí, tal y como se indica en el mismo momento en que se va a realizar la reserva, la misma puede ser modificada de día por motivos de
					fuerza mayor, ya sean médicos/salud o por rotura/funcionamiento que impida realizar la sesión con normalidad.
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					En estos casos, se avisará al grupo lo antes posible para ofrecer un cambio de día manteniendo el dinero de la paga y señal.
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					En el caso de que finalmente el grupo no pueda venir otro día, se procederá a realizar el reembolso de la paga y señal.
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					EN NINGÚN CASO, Haunted House SL nunca se hará cargo de ningún coste o compensación económica de posibles gastos que le haya ocasionado al
					jugador.
				</Typography>

				<Typography variant="body1" color={theme.color.secondary}>
					¿Se puede usar la fuerza?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- NO, bajo ningún concepto.
				</Typography>

				<Typography variant="body1" color={theme.color.secondary}>
					¿Se puede modificar el día/hora de la reserva?
				</Typography>
				<Typography pb={2} variant="body1" color={theme.color.primary}>
					- Sí, siempre y cuando nos avisen con al menos 4 días de antelación respecto a la reserva y con un costo de 5€. Después de este plazo, no
					aceptamos cambios ni devoluciones.
				</Typography>
			</Collapse>
			<Box onClick={() => setCollapse((prev) => !prev)} display="flex" alignItems={"center"} style={{ cursor: "pointer" }}>
				{collapse ? <Close style={{ color: theme.color.secondary }} /> : <Add style={{ color: theme.color.secondary }} />}
				<Typography variant="body1" color={theme.color.secondary}>
					{collapse ? "Cerrar" : "Màs información"}
				</Typography>
			</Box>
			{/* <CustomButton
				size="small"
				fullWidth
				title={collapse ? "Cerrar" : "Màs información"}
			/> */}
		</Box>
	);
}
