import React from "react";
import { Navigate } from "react-router-dom";
import { Error404 } from "./components/layouts/404";
import AdminLayout from "./layouts/admin";
import PublicLayout from "./layouts/public";
import CalendariAdmin from "./views/admin/calendari/CalendariAdmin";
import CartaAdmin from "./views/admin/carta/CartaAdmin";
import Clients from "./views/admin/clients/Clients";
import ClientSingle from "./views/admin/clients/ClientSingle";
import HabitacionsAdmin from "./views/admin/habitacions/HabitacionsAdmin";
import HomeAdmin from "./views/admin/home/HomeAdmin";
import JocsAdmin from "./views/admin/jocs/JocsAdmin";
import LoginView from "./views/admin/login/LoginView";
import ReservaSingle from "./views/admin/reserves/ReservaSingle";
import ReservesAdmin from "./views/admin/reserves/ReservesAdmin";
import ScaperoomsAdmin from "./views/admin/scapes/ScaperoomsAdmin";
import Checkout from "./views/public/checkout/Checkout";
import UrlKo from "./views/public/checkout/retorn/UrlKo";
import UrlOk from "./views/public/checkout/retorn/UrlOk";
import Contacte from "./views/public/contacte/Contacte";
import ScapeSingle from "./views/public/escapes/ScapeSingle";
import Habitacions from "./views/public/habitacions/Habitacions";
import Home from "./views/public/Home";
import Carta from "./views/public/hotel/carta/Carta";
import Destruct from "./views/public/hotel/carta/Destruct";
import Hotel from "./views/public/hotel/Hotel";
import AvisLegal from "./views/public/legal/AvisLegal";
import PoliticaPrivacidad from "./views/public/legal/PoliticaPrivacitat";
import Reserves from "./views/public/reserves/Reserves";
import Restaurant from "./views/public/restaurant/Restaurant";
import { Sobre } from "./views/public/sobre/Sobre";
import CartaTerrassaAdmin from "./views/admin/carta/CartaTerrassaAdmin";
import CartaTerrassa from "./views/public/terrassa/CartaTerrassa";
import CuponsAdmin from "./views/admin/cupons/CuponsAdmin";
import ProductesAdmin from "./views/admin/productes/ProductesAdmin";
import Botiga from "./views/public/botiga/Botiga";
import ScapeKrugger from "./views/public/escapes/ScapeKrugger";
import Grups from "./views/public/grups/Grups";
import Franquicia from "./views/public/franquicia/Franquicia";
import TicketsAdmin from "./views/admin/tickets/TicketsAdmin";
import Tickets from "./views/public/tickets/Tickets";
import CompraTicketsAdmin from "./views/admin/tickets/CompraTicketsAdmin";
import CompraTicketSingle from "./views/admin/tickets/CompraTicketSingle";
import UrlOkTickets from "./views/public/tickets/UrlOk";

const routes = (logged) => [
	{
		path: "/admin",
		element: logged ? <AdminLayout /> : <Navigate to="/login" />,
		children: [
			{
				path: "/admin/dash",
				element: <HomeAdmin />,
			},
			{
				path: "/admin/reserves",
				element: <ReservesAdmin />,
			},
			{
				path: "/admin/reserves/:id",
				element: <ReservaSingle />,
			},
			{
				path: "/admin/scaperooms",
				element: <ScaperoomsAdmin />,
			},
			{
				path: "/admin/hotel",
				element: <HabitacionsAdmin />,
			},
			{
				path: "/admin/productes",
				element: <ProductesAdmin />,
			},
			{
				path: "/admin/calendari",
				element: <CalendariAdmin />,
			},
			{
				path: "/admin/clients",
				element: <Clients />,
			},
			{
				path: "/admin/clients/:id",
				element: <ClientSingle />,
			},
			{
				path: "/admin/esdeveniments",
				element: <JocsAdmin />,
			},
			{
				path: "/admin/carta",
				element: <CartaAdmin />,
			},
			{
				path: "/admin/cartaTerrassa",
				element: <CartaTerrassaAdmin />,
			},
			{
				path: "/admin/cupons",
				element: <CuponsAdmin />,
			},
			{
				path: "/admin/tickets",
				element: <TicketsAdmin />,
			},
			{
				path: "/admin/compra-tickets",
				element: <CompraTicketsAdmin />,
			},
			{
				path: "/admin/compra-tickets/:id",
				element: <CompraTicketSingle />,
			},
		],
	},

	{
		path: "/",
		element: <PublicLayout />,
		children: [
			{ path: "/reserves", element: <Reserves /> },
			{ path: "/tienda", element: <Botiga /> },
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/login",
				element: !logged ? <LoginView /> : <Navigate to="/admin/dash" />,
			},
			{ path: "/escapes/:id", element: <ScapeSingle /> },
			{ path: "/escapes/secreto-krugger", element: <ScapeKrugger /> },
			{ path: "/hotel", element: <Hotel /> },
			{ path: "/tickets", element: <Tickets /> },
			{ path: "/habitacions", element: <Habitacions /> },
			{ path: "/restaurant", element: <Restaurant /> },
			{ path: "/contacte", element: <Contacte /> },
			{ path: "/grupos-grandes", element: <Grups /> },
			{ path: "/franquicia", element: <Franquicia /> },
			{ path: "/sobre-la-corporacion", element: <Sobre /> },
			{ path: "/reserves/checkout", element: <Checkout /> },
			{ path: "/reserves/urlOk", element: <UrlOk /> },
			{ path: "/reserves/urlKo", element: <UrlKo /> },
			{ path: "/compra-tickets/urlOk", element: <UrlOkTickets /> },
			{ path: "/legal/avis-legal", element: <AvisLegal /> },
			{
				path: "/legal/politica-privacitat",
				element: <PoliticaPrivacidad />,
			},
			{ path: "*", element: <Error404 /> },
		],
	},
	{ path: "/hotel/carta", element: <Carta /> },
	{ path: "/terrassa/carta", element: <CartaTerrassa /> },
	{ path: "/hotel/self-destruct", element: <Destruct /> },
];

export default routes;
