import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import pastilla from "../../../assets/img/paper.png";
import Foto from "../../../components/elements/Foto";

export default function MaterialItemPublic({ material, setOpen, setSelect }) {
	const classes = useStyles();

	const select = () => {
		setSelect(material);
		setOpen(true);
	};

	return (
		<Grid item md={3} xs={12}>
			<Box className={classes.hab} onClick={select}>
				<Box
					className={"inbox"}
					sx={{
						transition: "0.2s",
					}}
				>
					{material?.foto && <Foto small src={"https://api.insomniacorp.com/storage/" + material?.foto} sx={{ width: "100%", height: "100%" }} />}
				</Box>
				<Box mt={2}>
					<Typography variant="h6" mt={2} letterSpacing={0}>
						{material?.nom}
					</Typography>
					<Typography variant="h2" mt={2} fontWeight={200}>
						{material?.material?.preu + " €"}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	hab: {
		background: `url(${pastilla})`,
		padding: 25,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		cursor: "pointer",
		"& :hover": {
			"&.inbox": {
				transform: "scale(1.1)",
			},
		},
	},
	pastilla: {
		border: "1px solid black",
		backgroundColor: theme.color.secondary,
		display: "table",
		paddingLeft: 5,
		paddingRight: 5,
	},
}));
