import { Avatar, Box, CircularProgress, Fade, useMediaQuery } from "@mui/material";
import useImagePreloader from "../layouts/useImagePreloader";

function Foto({ src, small, ...rest }) {
	const { imagesPreloaded } = useImagePreloader([src]);
	const matches = useMediaQuery("(min-width:960px)");

	return imagesPreloaded ? (
		<Box
			sx={{
				width: "100%",
				height: small ? (matches ? 250 : 350) : 336,
			}}
		>
			<Fade in={imagesPreloaded}>
				<Avatar src={src} variant="square" {...rest} />
			</Fade>
		</Box>
	) : (
		<Box
			sx={{
				width: "100%",
				height: small ? 250 : 336,
			}}
			display="flex"
			justifyContent={"center"}
			alignItems="center"
		>
			<CircularProgress color="secondary" />
		</Box>
	);
}

export default Foto;
