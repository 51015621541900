import { ThemeProvider, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import ClientsColumns from "../../../components/columns/ClientsColumns";
import TableOptions from "../../../components/columns/TableOptions";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import getMuiTheme from "../../../theme/TableStyle";

function Clients() {
	const [reserves, setReserves] = useState([]);
	const options = TableOptions();
	const columns = ClientsColumns(reserves);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("clients");
			setReserves(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<PageAdmin title="Clients">
			<Loading loading={loading}>
				<ThemeProvider theme={getMuiTheme()}>
					<MUIDataTable
						data={reserves}
						columns={columns}
						options={options}
					/>
				</ThemeProvider>
			</Loading>
		</PageAdmin>
	);
}

export default Clients;
