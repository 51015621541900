import { ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import ReservesColumns from "../../../components/columns/ReservesColumns";
import TableOptions from "../../../components/columns/TableOptions";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import getMuiTheme from "../../../theme/TableStyle";

function ReservesAdmin() {
	const [reserves, setReserves] = useState([]);
	const options = TableOptions();
	const columns = ReservesColumns(reserves);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("reserves");
			setReserves(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<PageAdmin title="Reserves">
			<Loading loading={loading}>
				<ThemeProvider theme={getMuiTheme()}>
					<MUIDataTable
						data={reserves}
						columns={columns}
						options={options}
					/>
				</ThemeProvider>
			</Loading>
		</PageAdmin>
	);
}

export default ReservesAdmin;
