import { Box, Typography } from "@mui/material";
import theme from "../../theme";
import { IconJoc } from "./JocLlegenda";

export function JocItemPublic({ joc }) {
	const Icon = IconJoc(joc?.icon);

	return (
		<Box mb={8}>
			<Box pb={2}>
				<div
					style={{
						backgroundColor: theme.color.secondary,
						borderRadius: 50,
						display: "inline-flex",
					}}
				>
					<Icon fill={joc?.color} width={70} />
				</div>
				<Typography
					mt={2}
					variant="h3"
					fontSize={30}
					color={theme.color.secondary}
				>
					{joc?.nom}
				</Typography>
			</Box>
			<Typography mb={2} color={theme.color.secondary}>
				{joc?.desc}
			</Typography>
		</Box>
	);
}
