import React from "react";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Page from "../../../../components/layouts/Page";
import bg from "../../../../assets/img/fons-2.jpg";
import { useNavigate } from "react-router";
import CustomButton from "../../../../components/elements/CustomButton";

function UrlKo() {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Page title="Insomnia Corp. - Finalizado correctamente">
			<Box className={classes.portada}>
				<Container maxWidth={"md"}>
					<Box
						display={"flex"}
						justifyContent="center"
						flexDirection={"column"}
						alignItems="center"
					>
						<Typography
							variant="h1"
							color="secondary"
							mb={8}
							textAlign="center"
						>
							Reserva fallida
						</Typography>
						<Typography color="secondary" mb={5}>
							Algo no ha ido bien... Vuelve a intentarlo más
							tarde.
						</Typography>
						<Box textAlign={"center"} mt={2}>
							<CustomButton
								title="Volver"
								danger
								onClick={() => navigate("/")}
							/>
						</Box>
					</Box>
				</Container>
			</Box>
		</Page>
	);
}

export default UrlKo;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "contain",
		backgroundAttachment: "fixed",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
		paddingBottom: 100,
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
}));
