import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Tab,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getElements } from "../../../API/API";
import theme from "../../../theme";
import AddDataJoc from "./AddDataJoc";
import { AddPleRestaurant } from "./AddPleRestaurant";
import AddReserva from "./AddReserva";
import AddRestriccio from "./AddRestriccio";

function CalendarAddDialog({
	setUpdate,
	open,
	setOpen,
	setLoading,
	loading,
	dateSel,
	dataJoc,
}) {
	const [tab, setTab] = useState("1");
	const [productes, setProductes] = useState([]);
	const formReserva = useForm();
	const formRestriccio = useForm({
		defaultValues: { hora: "16:00:00", sencer: false },
	});
	const formPleRestaurant = useForm();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("productes");
			setProductes(result);
		};
		if (open) obtenir();
	}, [open]);

	useEffect(() => {
		formReserva.setValue("data", dateSel);
		formRestriccio.setValue("data", dateSel);
		formPleRestaurant.setValue("data", dateSel);
	}, [dateSel, open]);

	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	const handleClose = () => {
		formRestriccio.reset();
		formReserva.reset();
		formPleRestaurant.reset();
		setOpen(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
		>
			<DialogTitle
				sx={{ background: theme.color.primary, color: "white" }}
			>
				<Box display="flex" justifyContent={"space-between"}>
					Afegir esdeveniment
				</Box>
			</DialogTitle>
			<DialogContent>
				<Typography variant="h4" textAlign={"center"} my={3}>
					{moment(dateSel).locale("ca").format("LL")}
				</Typography>
				<TabContext value={tab}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<TabList
							onChange={handleChange}
							aria-label="lab API tabs example"
						>
							<Tab label="Nova restricció" value="1" />
							<Tab label="Nova reserva" value="2" />
							<Tab label="Joc nit" value="3" />
							<Tab label="Restaurant ple" value="4" />
						</TabList>
					</Box>

					<TabPanel value="1">
						<AddRestriccio
							formRestriccio={formRestriccio}
							handleClose={handleClose}
							loading={loading}
							productes={productes}
							setLoading={setLoading}
							setOpen={setOpen}
							setUpdate={setUpdate}
						/>
					</TabPanel>
					<TabPanel value="2">
						<AddReserva
							formReserva={formReserva}
							handleClose={handleClose}
							loading={loading}
							productes={productes}
							setLoading={setLoading}
							setOpen={setOpen}
							setUpdate={setUpdate}
							dataJoc={dataJoc}
						/>
					</TabPanel>
					<TabPanel value="3">
						<AddDataJoc
							handleClose={handleClose}
							loading={loading}
							setLoading={setLoading}
							setOpen={setOpen}
							setUpdate={setUpdate}
							dateSel={dateSel}
							dataJoc={dataJoc}
						/>
					</TabPanel>
					<TabPanel value="4">
						<AddPleRestaurant
							handleClose={handleClose}
							loading={loading}
							setLoading={setLoading}
							setOpen={setOpen}
							setUpdate={setUpdate}
							dateSel={dateSel}
							formPleRestaurant={formPleRestaurant}
						/>
					</TabPanel>
				</TabContext>
			</DialogContent>
		</Dialog>
	);
}

export default CalendarAddDialog;
