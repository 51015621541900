import { Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { addElement, updateElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import theme from "../../../theme";

function CupoDialogAdd({ open, setOpen, setUpdate, cupo, setCupo }) {
	const { register, reset, handleSubmit, control } = useForm();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		cupo && open && reset(cupo);
	}, [cupo, open]);

	const handleClose = () => {
		reset({ nom: "", preu: "" });
		setCupo("");
		setOpen(false);
	};

	const crear = async (values) => {
		setLoading(true);
		const { message } = cupo ? await updateElement("cupons", cupo.id, values) : await addElement("cupons", values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
		>
			<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
				<Box display="flex" justifyContent={"space-between"}>
					{cupo ? "Editar cupó" : "Afegir cupó"}
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit(crear)}>
					<Box mt={3}>
						<Grid spacing={2} container>
							<Grid item md={6}>
								<Box mb={3}>
									<TextField {...register("nom")} fullWidth label="Nom" />
								</Box>
							</Grid>
							<Grid item md={6}>
								<Box mb={3}>
									<TextField {...register("preu")} fullWidth label="Quantitat de descompte" />
								</Box>
							</Grid>
						</Grid>

						<Controller
							name={"gastat"}
							control={control}
							render={({ field }) => (
								<FormControlLabel
									style={{ margin: 0 }}
									label="Cupó gastat"
									control={
										<Checkbox
											onChange={(e) => field.onChange(e.target.checked)}
											size={"small"}
											checked={field.value === "1" || field.value === true}
										/>
									}
								/>
							)}
						/>

						<Divider />
						<Stack direction={"row"} spacing={2}>
							<CustomButton title="Tancar" fullWidth danger onClick={handleClose} />
							<CustomButton type="submit" title={cupo ? "Guardar" : "Crear"} fullWidth loading={loading} />
						</Stack>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default CupoDialogAdd;
