import { RemoveRedEye } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import Estat from "../elements/Estat";

const CompraTicketsColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "unique_id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "estat",
			label: "Estat",
			options: {
				filter: false,
				sort: false,
				sortOrder: "desc",
				customBodyRender: (value) => <Estat estat={value} />,
			},
		},
		{
			name: "estat.nom",
			label: "Estat",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				display: false,
			},
		},
		{
			name: "client.nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "preu",
			label: "Preu",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value + " €",
			},
		},
		{
			name: "tickets_count",
			label: "Tickets",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(`/admin/compra-tickets/${activitats[dataIndex].unique_id}`, {
									state: activitats[dataIndex],
								})
							}
						>
							<RemoveRedEye />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default CompraTicketsColumns;
