import {
	CalendarMonth,
	DoorBack,
	Group,
	Hotel,
	Link,
} from "@mui/icons-material";
import { Avatar, Divider, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import Pill from "../../../components/elements/Pill";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import theme from "../../../theme";
import "moment/locale/ca";
import "moment/min/moment-with-locales";
import Estat from "../../../components/elements/Estat";
import { useNavigate } from "react-router";

function HomeAdmin() {
	const [loading, setLoading] = useState(true);
	const [dades, setDades] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("getDadesHome");
			setDades(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<PageAdmin title="Insomnia Corp - Administració">
			<Loading loading={loading}>
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<Pill
							color={theme.color.primary}
							icon={<CalendarMonth />}
							label={dades?.counts?.reserves_count}
							title={
								"Reserves " +
								moment().locale("ca").format("MMMM")
							}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill
							color={"#47a935"}
							icon={<DoorBack />}
							label={dades?.counts?.scapes_count}
							title="Reserves Escapes"
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill
							color={"#a9a435"}
							icon={<Hotel />}
							label={dades?.counts?.hotel_count}
							title="Reserves Hotel"
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill
							color={"#442de9"}
							icon={<Group />}
							label={dades?.counts?.clients_count}
							title="Usuaris"
						/>
					</Grid>
				</Grid>
				<Grid spacing={2} container mt={2}>
					<Grid item md={6} xs={12}>
						<Box className={classes.bloc}>
							<Typography variant="h3" mb={2}>
								Reserves escapes
							</Typography>
							<Divider />
							{dades?.reserves_scapes?.map((item, index) => (
								<Detall item={item} key={index} />
							))}
						</Box>
					</Grid>
					<Grid item md={6} xs={12}>
						<Box className={classes.bloc}>
							<Typography variant="h3" mb={2}>
								Reserves Hotel
							</Typography>
							<Divider />
							{dades?.reserves_hotel?.map((item, index) => (
								<Detall item={item} key={index} />
							))}
						</Box>
					</Grid>
				</Grid>
			</Loading>
		</PageAdmin>
	);
}

function Detall({ item }) {
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<Box className={classes.linia}>
			<Box display={"flex"} alignItems="center">
				<Avatar
					src={
						"https://api.insomniacorp.com/storage/" +
						item?.producte.foto
					}
					sx={{ width: 70, height: 70 }}
					variant="rounded"
				/>
				<Box>
					<Typography ml={2} textAlign="left">
						{item.reserva.client.nom}
					</Typography>

					<Typography ml={2} variant="body2" color="black">
						{moment(item.data).format("LLL")}
					</Typography>
					<Typography ml={2} variant="body2" color="black">
						{moment(item.data).fromNow()}
					</Typography>
				</Box>
			</Box>
			<Box>
				<Typography ml={2}>{item.quantitat} persones</Typography>
			</Box>
			<Estat estat={item.reserva.estat} />
			<Box>
				<IconButton
					onClick={() =>
						navigate("/admin/reserves/" + item.reserva.unique_id)
					}
				>
					<Link />
				</IconButton>
			</Box>
		</Box>
	);
}

export default HomeAdmin;

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
	},
	detall: {
		display: "flex",
		alignItems: "center",
		padding: 10,
	},
	linia: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
}));
