import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/layouts/Page";
import theme from "../../../theme";
import sanefa from "../../../assets/img/habitacions/sanefa.svg";
import bg from "../../../assets/img/fons-2.jpg";
import { CartesHotel } from "../../../components/elements/CartesHotel";
import hotelImg from "../../../assets/img/hotel/hotel-logo.jpg";
import barImg from "../../../assets/img/hotel/bar-1.jpg";
import restaurantImg from "../../../assets/img/restaurant/Restaurant_1_v2.jpg";
import saloImg from "../../../assets/img/hotel/salo-1.jpg";
import habitacionsImg from "../../../assets/img/hotel/habitacions-1.jpg";
import paper from "../../../assets/img/paper-popup.png";
import img2 from "../../../assets/img/restaurant/Restaurant_2_v1.jpg";

const imatges = [{ foto: hotelImg }, { foto: img2 }, { foto: barImg }, { foto: restaurantImg }, { foto: img2 }, { foto: saloImg }];

export default function Grups() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();

	return (
		<Page title="Insomnia Corporation - Grupos grandes">
			<Box className={classes.portada}>
				<Container maxWidth="xl">
					<Grid spacing={8} container>
						<Grid item md={6} xs={12} mb={5}>
							{matches && <CartesHotel imatges={imatges} />}
						</Grid>

						<Grid item md={6} xs={12} zIndex={10}>
							{!matches && (
								<Box className={classes.wrapImg} my={4}>
									<img src={hotelImg} alt={"Insomnia Hotel"} className={classes.img} />
								</Box>
							)}
							<Box>
								<Typography variant="h1" pb={0} fontSize={40} color={theme.color.secondary}>
									Experiencia Exclusiva
								</Typography>
								<Typography variant="h3" pb={3} color={theme.color.secondary}>
									para Grupos Grandes y Empresas
								</Typography>
								<Divider
									sx={{
										borderColor: theme.color.secondary,
									}}
								/>
							</Box>
							<Typography pt={3} variant="body1" color={theme.color.secondary}>
								En Insomnia Hotel, ofrecemos actividades especialmente diseñadas para grupos grandes y empresas, con una experiencia única que
								combina cena y aventura en nuestro escape room de terror "El Secreto de los Krugger".
							</Typography>
							<Typography py={2} variant="h3" color={theme.color.secondary}>
								Pack Exclusivo (95€ por persona):
							</Typography>

							<Typography py={0} variant="body1" color={theme.color.secondary}>
								<ul>
									<li>Grupos de 9 a 20 personas.</li>
									<li>Duración: 4 horas.</li>
								</ul>
							</Typography>
							<Box my={10}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Typography py={2} variant="h3" color={theme.color.secondary}>
								Incluye:
							</Typography>
							<Typography py={0} variant="body1" color={theme.color.secondary}>
								<ul>
									<li>
										<strong>Cena con Menú Degustación</strong>: Disfruta de un menú exclusivo, creado especialmente para estos días, que
										combina sabores únicos y experiencias gastronómicas diseñadas para sumergirte en la atmósfera de misterio. Además, se
										incluye una bebida por persona para acompañar el menú.
									</li>
									<li>
										<strong>Escape Room de Terror</strong>: "El Secreto de los Krugger": Todos los participantes deberán intentar sobrevivir
										mientras resuelven los enigmas que rodean a la familia Krugger. La experiencia se desarrolla en todo el hotel, donde
										enfrentarán desafíos aterradores y pruebas que los mantendrán en tensión constante.
									</li>
								</ul>
							</Typography>
							<Box my={10}>
								<Divider
									sx={{
										background: `url(${sanefa})`,
										borderColor: "transparent",
										height: 15,
										backgroundRepeat: "repeat-x",
									}}
								/>
							</Box>
							<Typography py={2} variant="h3" color={theme.color.secondary}>
								Reservas:
							</Typography>
							<Typography py={0} variant="body1" color={theme.color.secondary}>
								Para reservar esta experiencia exclusiva, contacta con nosotros en el teléfono{" "}
								<a style={{ color: theme.palette.secondary.main }} href="tel:+34689153847">
									689 153 847
								</a>
								.
							</Typography>
							<Typography py={0} variant="body1" color={theme.color.secondary}>
								Disponible todos los días excepto viernes y sábados.
							</Typography>
							<Typography py={3} variant="h4" color={theme.color.secondary}>
								¡No te pierdas la oportunidad de vivir una noche inolvidable con tu equipo o grupo de amigos!
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 230,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingBottom: 230,
		[theme.breakpoints.down("sm")]: {
			paddingTop: 40,
		},
	},
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(5deg)",
		},
		textAlign: "center",
	},

	wrapImg2: {
		transform: "rotate(5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.color.secondary,
	},
	calendari: {
		background: `url(${paper})`,
		padding: 40,
		backgroundRepeat: "round",
		display: "flex",
		flexDirection: "column",
		marginBottom: 100,
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			padding: 10,
		},
	},
}));
