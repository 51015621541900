import { useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { Box, InputBase, MenuItem, Select } from "@mui/material";
import { Language } from "@mui/icons-material";
import i18n from "../lang";

const BootstrapInput = withStyles((theme) => ({
	input: {
		borderRadius: 4,
		position: "relative",
		color: "white !important",
		fontSize: 13,
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		fontFamily: "Open Sans !important",
		"&:focus": {
			borderRadius: 4,
			color: "white",
			boxShadow: "0",
			backgroundColor: "transparent",
		},
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	menuItem: {
		fontSize: "15px !important",
		fontWeight: 400,
		color: "white",
		marginRight: 10,
	},
	arrow: {
		fill: "white !important",
	},
	lang: {
		border: "1px solid white",
		borderRadius: 10,
		paddingLeft: 10,
	},
}));

const LangSelector = () => {
	const classes = useStyles();
	const [lang, setLang] = useState(i18n.language);

	const changeLang = (lang) => {
		i18n.changeLanguage(lang);
		setLang(lang);
	};

	return (
		<Box display="flex" alignItems={"center"} className={classes.lang}>
			<Select
				labelId="demo-customized-select-label"
				id="demo-customized-select"
				variant="outlined"
				value={lang}
				input={<BootstrapInput />}
				inputProps={{
					classes: {
						icon: classes.arrow,
						text: classes.menuItem,
					},
				}}
				startAdornment={
					<Language
						fill="white"
						size={13}
						style={{
							marginRight: 8,
							fill: "white",
						}}
					/>
				}
				MenuProps={{ disableScrollLock: true }}
			>
				<MenuItem
					className={classes.menuItem}
					value={"ca"}
					onClick={() => changeLang("ca")}
				>
					Ca
				</MenuItem>
				<MenuItem
					className={classes.menuItem}
					value={"es"}
					onClick={() => changeLang("es")}
				>
					Es
				</MenuItem>

				<MenuItem
					className={classes.menuItem}
					value={"en"}
					onClick={() => changeLang("en")}
				>
					En
				</MenuItem>
			</Select>
		</Box>
	);
};

export default LangSelector;
