import FullCalendar from "@fullcalendar/react";
import { Tooltip, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { getElements } from "../../../API/API";
import moment from "moment";
import { NoMeals, Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarAddDialog from "./CalendarAddDialog";
import LoadingOverlay from "../../../components/layouts/LoadingOverlay";
import CalendarDeleteDialog from "./CalendarDeleteDialog";
import { quinJoc, teJoc, tePleRestaurant } from "../../../components/Utils";
import { IconJoc } from "../../../components/elements/JocLlegenda";
import CalendariFilter from "./calendariElements/CalendariFilter";
import renderEventContent from "./calendariElements/renderEventContent";

function CalendariAdmin() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [reserves, setReserves] = useState([]);
	const [filteredReserves, setFilteredReserves] = useState([]);
	const [open, setOpen] = useState(false);
	const [dateSel, setDateSel] = useState();
	const [update, setUpdate] = useState(0);
	const [openDelete, setOpenDelete] = useState(false);
	const [restriccio, setRestriccio] = useState();
	const [dataJoc, setDataJoc] = useState([]);
	const [pleRestaruant, setPleRestaruant] = useState([]);
	const navigate = useNavigate();
	const [ocultar, setOcultar] = useState(false);
	const [booking, setBooking] = useState(false);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			let events = [];
			const { result } = await getElements("calendari");
			setDataJoc(result.dataJoc);
			setPleRestaruant(result.pleRestaruant);

			await result.habitacions.map((b) => {
				if (result.booking[b.nom]) {
					Object.values(result.booking[b.nom]).map((item) => {
						if (item["BEGIN"] === "VEVENT\r") {
							events.push({
								title: item["SUMMARY"].replace("CLOSED", "BOOKING") + " - " + b.nom,
								start: moment(item["DTSTART;VALUE=DATE"], "YYYYMMDD").format("YYYY-MM-DD"),
								end: moment(item["DTEND;VALUE=DATE"], "YYYYMMDD").format("YYYY-MM-DD"),
								color: "#003580",
								overlap: "booking",
							});
						}
					});
				}
			});

			await result.reserves.map(
				(item) =>
					item.reserva.estat_id !== "4" &&
					item.reserva.estat_id !== "5" &&
					events.push({
						title: item.reserva?.client?.nom + " - " + item?.reserva?.id,
						start: item?.producte?.tipus_id === "1" ? item.data : moment(item.data).format("YYYY-MM-DD"),
						end: item?.producte?.tipus_id === "1" ? "null" : moment(item.data).add(1, "day"),
						color: item?.producte?.color,
						id: item?.reserva.unique_id,
						groupId: item?.producte?.tipus_id,
						textColor: item?.producte.nom,
					})
			);

			await result.restriccions
				.filter((i) => Number(i.bloqueig) === 1)
				.map((item) =>
					events.push({
						title: "Bloquejat",
						start: item?.producte?.tipus_id === "1" ? item.data : moment(item.data).format("YYYY-MM-DD"),
						end: item?.producte?.tipus_id === "1" ? "null" : moment(item.data).add(1, "day"),
						color: item?.producte?.color,
						id: item?.id,
						groupId: item?.producte?.tipus_id,
						textColor: item?.producte?.nom,
						overlap: "block",
					})
				);

			await result.restriccions
				.filter((i) => Number(i.bloqueig) === 0)
				.map((item) =>
					events.push({
						title: "OBERT",
						start: item?.producte?.tipus_id === "1" ? item.data : moment(item.data).format("YYYY-MM-DD"),
						end: item?.producte?.tipus_id === "1" ? "null" : moment(item.data).add(1, "day"),
						color: item?.producte?.color,
						id: item?.id,
						groupId: item?.producte?.tipus_id,
						textColor: item?.producte?.nom,
						overlap: "block",
					})
				);
			setTimeout(() => {
				setReserves(events);

				setFilteredReserves(events);
				setLoading(false);
			}, 1000);
		};
		obtenir();
	}, [update]);

	function onClickEvent(e) {
		if (e.event.overlap === "block") {
			setOpenDelete(true);
			setRestriccio(e.event);
		} else {
			navigate("/admin/reserves/" + e.event.id);
		}
	}

	function dateClick(e) {
		setDateSel(e["dateStr"]);
		setOpen(true);
	}

	const renderDayContent = (day) => {
		let add = false;
		let icon = false;
		let res = false;
		let iconRes = false;
		if (teJoc(moment(day.date), dataJoc)) {
			add = true;
			const joc = quinJoc(moment(day.date), dataJoc);
			const Icon = IconJoc(joc.icon);
			icon = <Icon fill={joc.color} className={classes.iconCalendar} />;
		}
		if (tePleRestaurant(moment(day.date), pleRestaruant)) {
			console.log(day.date);
			res = true;
			iconRes = <NoMeals className={classes.iconCalendarRes} sx={{ width: 18 }} />;
		}
		return (
			<Tooltip title={add ? "Amb joc" : "Sense joc"}>
				<Box>
					<Box zIndex={10}>
						<Typography fontSize={14}>{day.dayNumberText}</Typography>
					</Box>
					{add && icon}
					{res && iconRes}
				</Box>
			</Tooltip>
		);
	};

	return (
		<PageAdmin title="Calendari">
			<LoadingOverlay loading={loading}>
				<div className={classes.calendar}>
					<FullCalendar
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						headerToolbar={{
							left: matches ? "prev,next today" : "prev,next",
							center: "title",
							right: matches ? "dayGridMonth,timeGridWeek,timeGridDay refresh,block,booking" : "dayGridMonth,timeGridWeek,timeGridDay",
						}}
						initialView={matches ? "dayGridMonth" : "timeGridDay"}
						events={filteredReserves}
						locale={"ca"}
						firstDay={1}
						buttonText={{
							today: "avui",
							month: "mes",
							week: "setmana",
							day: "dia",
						}}
						allDayText="Tot el dia"
						slotMinTime={"14:00"}
						height={window.innerHeight - 240}
						eventContent={renderEventContent}
						eventTimeFormat={{
							hour: "numeric",
							minute: "2-digit",
							hour12: false,
						}}
						eventClick={onClickEvent}
						dateClick={dateClick}
						dayCellContent={renderDayContent}
						dayMaxEvents={5}
						themeSystem="bootstrap"
						customButtons={{
							refresh: {
								text: (
									<Refresh
										fontSize="small"
										style={{
											position: "absolute",
											top: 8,
											left: 2,
										}}
									/>
								),
								click: () => {
									setUpdate((prev) => prev + 1);
									setOcultar(false);
									setBooking(false);
								},
							},
							block: {
								text: ocultar ? "Mostrar bloquejos" : "Ocultar bloquejos",
								click: () => setOcultar((prev) => !prev),
							},
							booking: {
								text: booking ? "Mostrar Booking" : "Ocultar Booking",
								click: () => setBooking((prev) => !prev),
							},
						}}
					/>
					<CalendariFilter reserves={reserves} setFilteredReserves={setFilteredReserves} ocultar={ocultar} booking={booking} />
				</div>
				<CalendarAddDialog
					open={open}
					setOpen={setOpen}
					loading={loading}
					dateSel={dateSel}
					setLoading={setLoading}
					setUpdate={setUpdate}
					dataJoc={dataJoc}
				/>
				<CalendarDeleteDialog
					open={openDelete}
					setOpen={setOpenDelete}
					restriccio={restriccio}
					loading={loading}
					setLoading={setLoading}
					setUpdate={setUpdate}
				/>
			</LoadingOverlay>
		</PageAdmin>
	);
}

export default CalendariAdmin;

const useStyles = makeStyles((theme) => ({
	calendar: {
		fontFamily: "Rokkitt",
		color: theme.palette.text.primary,
		"& .fc-button-primary": {
			backgroundColor: theme.palette.primary.main,
			borderColor: theme.palette.primary.main,
			"&:hover": {
				backgroundColor: theme.palette.primary.hover,
			},
			"&:focus": {
				boxShadow: "none",
			},
		},
		"& .fc-button-active": {
			backgroundColor: "#A13633 !important",
			borderColor: "#A13633 !important",
			"&:hover": {
				backgroundColor: theme.palette.primary.hover,
				borderColor: "#A13633",
			},
		},
		"& td": {
			borderColor: "#9b9b9b",
			cursor: "pointer",
		},
		"& .fc-scrollgrid": {
			borderColor: "#9b9b9b",
		},
		"& th": {
			borderColor: "#9b9b9b",
		},
		"& a": {
			color: theme.color.background,
		},
		"& .fc-daygrid-dot-event": {
			padding: 0,
			margin: 0,
			height: 17,
		},
		"& .fc-daygrid-event": {
			padding: 0,
			margin: 0,
			height: 17,
		},
		"& .fc-timegrid-event": {
			boxShadow: "none",
		},
		[theme.breakpoints.down("sm")]: {
			position: "relative",
			paddingTop: 40,
			"& .fc-toolbar-title": {
				position: "absolute",
				top: -5,
				left: 0,
			},
		},
	},
	iconCalendar: {
		position: "absolute",
		top: 2,
		right: 20,
		width: 20,
		height: 20,
	},
	iconCalendarRes: {
		position: "absolute",
		top: 0,
		right: 45,
	},
}));
