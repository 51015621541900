import { CardMembership, Edit, Email, EventAvailable, Person } from "@mui/icons-material";
import { Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import Estat from "../../../components/elements/Estat";
import Pill from "../../../components/elements/Pill";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import theme from "../../../theme";
import ClientDialogEdit from "../reserves/ClientDialogEdit";

export default function ClientSingle() {
	const classes = useStyles();
	const [client, setClient] = useState();
	const [loading, setLoading] = useState(true);
	const [openEdit, setOpenEdit] = useState(false);
	const [clientId, setClientId] = useState("");
	const [valueClient, setValueClient] = useState("");
	const [label, setLabel] = useState("");
	const [name, setName] = useState("");
	const [update, setUpdate] = useState(0);
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("clients", id);
			console.log(result);
			setClient(result);
			setLoading(false);
		};
		obtenir();
	}, [id, update]);

	const editClient = (id, label, name, value) => {
		setClientId(id);
		setLabel(label);
		setName(name);
		setValueClient(value);
		setOpenEdit(true);
	};

	return (
		<PageAdmin title={"Client " + (client?.nom ?? "")}>
			<Loading loading={loading}>
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<Pill title={client?.id} icon={<CardMembership />} label="ID" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill title={client?.nom} icon={<Person />} label="Nom" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill title={client?.email} icon={<Email />} label="E-mail" color={theme.color.primary} />
					</Grid>
					<Grid item md={3} xs={12}>
						<Pill title={client?.reserves?.length} icon={<EventAvailable />} label="Nº reserves" color={theme.color.primary} />
					</Grid>
				</Grid>
				<Grid spacing={2} container mt={2}>
					<Grid item md={4} xs={12}>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Informació
							</Typography>
							<Divider />
							<Detall title="Nom" text={client?.nom} edit onClick={() => editClient(client?.id, "Nom", "nom", client?.nom)} />
							<Detall title="Telèfon" text={client?.telefon} edit onClick={() => editClient(client?.id, "Telèfon", "telefon", client?.telefon)} />
							<Detall title="E-mail" text={client?.email} edit onClick={() => editClient(client?.id, "E-mail", "email", client?.email)} />
							<Detall
								title="Població"
								text={client?.poblacio}
								edit
								onClick={() => editClient(client?.id, "Població", "poblacio", client?.poblacio)}
							/>
						</Box>
					</Grid>
					<Grid item md={8}>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Reserves
							</Typography>
							<Divider />
							{client?.reserves.map((reserva) => (
								<Box>
									<Box p={2} display="flex" justifyContent={"space-between"} alignItems="center">
										<Box>
											<Typography variant="body1">Data creació: {moment(reserva?.created_at).format("DD/MM/YYYY")}</Typography>
											<Typography variant="caption">ID: {reserva?.unique_id}</Typography>
											<Box>
												{reserva?.reserva_elements?.map((item) => (
													<Chip
														label={
															item.quantitat +
															"p - " +
															item.producte.nom +
															" " +
															(item.producte.tipus_id === "1"
																? moment(item?.data).format("DD/MM/YYYY HH:mm") + " h"
																: moment(item?.data).format("DD/MM/YYYY"))
														}
														style={{
															marginRight: 5,
															marginBottom: 5,
															fontFamily: "Rokkitt",
														}}
													/>
												))}
											</Box>
										</Box>
										<Estat estat={reserva?.estat} change id={reserva?.unique_id} />
										<CustomButton title={"Veure reserva"} onClick={() => navigate("/admin/reserves/" + reserva?.unique_id)} />
									</Box>
									<Divider />
								</Box>
							))}
						</Box>
						<Box className={classes.bloc} mb={3}>
							<Typography variant="h3" mb={2}>
								Compres tickets
							</Typography>
							<Divider />
							{client?.compra_tickets.map((reserva) => (
								<Box>
									<Box p={2} display="flex" justifyContent={"space-between"} alignItems="center">
										<Box>
											<Typography variant="body1">Data creació: {moment(reserva?.created_at).format("DD/MM/YYYY")}</Typography>
											<Typography variant="caption">ID: {reserva?.unique_id}</Typography>
											<Box>
												{reserva?.tickets?.map((item) => (
													<Chip
														size="small"
														label={item?.numero}
														style={{
															marginRight: 5,
															marginBottom: 5,
															fontFamily: "Rokkitt",
														}}
													/>
												))}
											</Box>
										</Box>
										<Estat estat={reserva?.estat} change id={reserva?.unique_id} />
										<CustomButton title={"Veure compra"} onClick={() => navigate("/admin/compra-tickets/" + reserva?.unique_id)} />
									</Box>
									<Divider />
								</Box>
							))}
						</Box>
					</Grid>
				</Grid>
			</Loading>
			<ClientDialogEdit id={clientId} name={name} label={label} setOpen={setOpenEdit} open={openEdit} setUpdate={setUpdate} value={valueClient} />
		</PageAdmin>
	);
}

function Detall({ title, text, edit, onClick }) {
	const classes = useStyles();
	return (
		<Box className={classes.detall} justifyContent={"space-between"}>
			<Box>
				<Typography variant="body1">{title}</Typography>
				<Typography variant="body2" color="black" letterSpacing={0}>
					{text}
				</Typography>
			</Box>
			{edit && (
				<IconButton onClick={onClick}>
					<Edit />
				</IconButton>
			)}
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	bloc: {
		backgroundColor: "white",
		padding: 20,
	},
	detall: {
		display: "flex",
		alignItems: "center",
		padding: 10,
	},
	linia: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
}));
