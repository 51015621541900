import { Avatar, Divider, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as Dificultat } from "../../../../assets/icons/dificultat.svg";
import { ReactComponent as Persones } from "../../../../assets/icons/persones.svg";
import { ReactComponent as Temps } from "../../../../assets/icons/temps.svg";
import { ReactComponent as Terror } from "../../../../assets/icons/terror.svg";
import CustomButton from "../../../../components/elements/CustomButton";

export default function DialogReservaInfo({ select, scapes, setSelect }) {
	return (
		<Box py={1}>
			<Grid spacing={4} container mb={3}>
				<Grid item md={3} xs={12}>
					<Avatar
						src={"https://api.insomniacorp.com/storage/" + select?.foto}
						variant="square"
						sx={{
							fontSize: "90px",
							width: "auto",
							height: "auto",
						}}
					/>
				</Grid>
				<Grid item md={9} xs={12}>
					<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
						<Typography variant="h6" mb={2} fontSize={35}>
							{select?.nom}
						</Typography>
						<Stack direction={"row"} spacing={2}>
							{scapes?.map(
								(s) => select?.id !== s?.id && <CustomButton title={s?.nom} disabled={select?.id === s?.id} onClick={() => setSelect(s)} />
							)}
						</Stack>
					</Box>
					<Divider sx={{ border: 1 }} />
					<Grid container spacing={2} mt={1}>
						<Grid item md={8}>
							<Typography style={{ whiteSpace: "pre-line" }}>{select?.resum}</Typography>
						</Grid>
						<Grid item md={4}>
							<Params select={select} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

function Params({ select }) {
	return (
		<Box display="flex" flexDirection={"column"} justifyContent={"space-between"} spacing={2}>
			<Box display="flex" alignItems="center" mb={1}>
				<Persones width={40} />{" "}
				<Typography variant="body1" ml={2}>
					{select?.scaperoom.min_p}-{select?.scaperoom.max_p} jugadores
				</Typography>
			</Box>
			<Box display="flex" alignItems="center" mb={1}>
				<Temps width={40} />{" "}
				<Typography variant="body1" ml={2}>
					{select?.scaperoom.temps} minutos
				</Typography>
			</Box>
			<Box display="flex" alignItems="center" mb={1}>
				<Dificultat width={40} />{" "}
				<Typography variant="body1" ml={2}>
					Dificultad {select?.scaperoom.dificultat}/5
				</Typography>
			</Box>
			<Box display="flex" alignItems="center" mb={1}>
				<Terror width={40} />{" "}
				<Typography variant="body1" ml={2}>
					Terror {select?.scaperoom.terror}/5
				</Typography>
			</Box>
		</Box>
	);
}
