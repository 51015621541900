import ReactDatePicker from "react-datepicker";
import { es } from "date-fns/locale";
import LoadingOverlay from "../../../../components/layouts/LoadingOverlay";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Badge from "../../../../components/elements/Badge";
import { useEffect, useState } from "react";
import moment from "moment";

const hores = [
	{ value: "16:00:00", text: "16.00 h" },
	{ value: "18:00:00", text: "18.00 h" },
	{ value: "20:00:00", text: "20.00 h" },
	{ value: "22:00:00", text: "22.00 h" },
];

function DialogReservaCalendari({
	loading,
	setData,
	data,
	exclude,
	select,
	setHora,
	hora,
	setJugadors,
	jugadors,
	reserves,
	setPreu,
	admin,
}) {
	const matches = useMediaQuery("(min-width:960px)");
	const [hExclude, setHExclude] = useState([]);

	useEffect(() => {
		reserves &&
			setHExclude(reserves[moment(data).format("YYYY-MM-DD")] ?? []);
	}, [data, reserves]);

	useEffect(() => {
		if (jugadors) {
			const preu = select?.preus?.filter(
				(item) => Number(item?.persones) === jugadors
			)[0]?.preu;
			setPreu(preu);
		} else {
			setPreu("-");
		}
	}, [jugadors, select]);

	const renderJugadors = () => {
		let result = [];
		for (
			let index = select?.scaperoom.min_p;
			index <= select?.scaperoom.max_p;
			index++
		) {
			result.push({ persones: index, text: index + " personas" });
		}
		return result;
	};

	function disabledDate(current) {
		const horaris = JSON.parse(select?.scaperoom?.dies);
		return horaris.some(
			(dia) =>
				(dia.dia + 1 === 7 ? 0 : dia.dia + 1) === moment(current).day()
		);
	}

	return (
		<LoadingOverlay loading={loading}>
			<Grid spacing={matches ? (admin ? 1 : 10) : 0} container mb={2}>
				<Grid item md={7} my={matches ? 2 : 0} xs={12}>
					<ReactDatePicker
						onChange={(date) => setData(date)}
						selected={data ?? ""}
						locale={es}
						dateFormat="dd/MM/yyyy"
						minDate={
							admin
								? moment().toDate()
								: moment().add(3, "days").toDate()
						}
						maxDate={!admin && moment().add(2, "months").toDate()}
						inline
						excludeDates={exclude}
						filterDate={!admin && disabledDate}
					/>
				</Grid>
				<Grid item md={5} my={3} xs={12}>
					<Grid spacing={0} container>
						<Grid item xs={6} mt={4}>
							<Typography variant="h6" mb={1.9} fontSize={22}>
								Hora
							</Typography>
							<Box
								display="flex"
								flexDirection={"column"}
								width={90}
							>
								{hores.map((item, index) => (
									<Badge
										text={item.text}
										index={item.value}
										set={setHora}
										select={hora}
										disabled={
											!admin &&
											exist(
												hExclude,
												item,
												JSON.parse(
													select?.scaperoom?.dies
												),
												data
											)
										}
									/>
								))}
							</Box>
						</Grid>
						<Grid item xs={6} mt={4}>
							<Typography variant="h6" mb={1.9} fontSize={22}>
								Jugadores
							</Typography>
							<Box
								display="flex"
								flexDirection={"column"}
								width={120}
							>
								{renderJugadors().map((item) => (
									<Badge
										text={item.text}
										index={Number(item.persones)}
										set={setJugadors}
										select={jugadors}
									/>
								))}
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</LoadingOverlay>
	);
}

export default DialogReservaCalendari;

function exist(array, item, dies, data) {
	const hores = dies?.filter(
		(dia) => String(dia.dia) === moment(data).format("e")
	)[0]?.hores;

	const ocupat = array.some(
		(h) => moment(h, "HH:mm").format("HH:mm:ss") === item.value
	);
	const nDisponible = hores?.some((hora) => hora === item.value);

	return !nDisponible || ocupat;
}
