import { Add, Delete, DeleteForever, DeleteForeverOutlined, DeleteOutline, Edit } from "@mui/icons-material";
import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Fab,
	FormControlLabel,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { addMaterial, deleteElement, updateElement, updateMaterial } from "../../../API/API";
import Badge from "../../../components/elements/Badge";
import CustomButton from "../../../components/elements/CustomButton";
import CustomTextField from "../../../components/elements/CustomTextField";
import ImageInput from "../../../components/elements/ImageInput";
import Thumb from "../../../components/elements/Thumb";
import Loading from "../../../components/layouts/Loading";
import theme from "../../../theme";

const diesWeek = [0, 1, 2, 3, 4, 5, 6];

function MaterialAddDialog({ material, setUpdate, open, setOpen, setLoading, loading }) {
	const { enqueueSnackbar } = useSnackbar();
	const [dies, setDies] = useState([]);
	const [inLoading, setInLoading] = useState(true);
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		trigger,
		control,
		setValue,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		setInLoading(true);
		if (material?.id) {
			setTimeout(() => {
				reset(material);
				setValue("variant", material?.material?.variant);
				setValue("talles", material?.material?.talles);
				setValue("preu", material?.material?.preu);
				setValue("cupo", material?.material?.cupo);
				setInLoading(false);
			}, 400);
		} else {
			setTimeout(() => {
				reset();
				setInLoading(false);
			}, 400);
		}
	}, [material]);

	const handleClose = () => {
		setOpen(false);
	};

	const guardar = async (values) => {
		setLoading(true);
		const { message } = await updateMaterial(material.id, values);

		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	const crear = async (values) => {
		setLoading(true);
		const { message } = await addMaterial(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
		>
			{material?.id ? (
				<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
					<Box display="flex" justifyContent={"space-between"}>
						Modificar {material.nom}
						{/* <Fab onClick={eliminar} color="error" size="small">
							<Delete />
						</Fab> */}
					</Box>
				</DialogTitle>
			) : (
				<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
					<Box display="flex" justifyContent={"space-between"}>
						Crear producte
					</Box>
				</DialogTitle>
			)}
			<DialogContent>
				<form onSubmit={handleSubmit(material?.id ? guardar : crear)}>
					<Loading loading={inLoading}>
						<Box py={2}>
							<Grid spacing={3} container>
								<Grid item md={6}>
									<CustomTextField name={"nom"} register={register} label="Nom producte" required />
									<CustomTextField name={"desc"} register={register} label="Descripció" rows={3} multiline required />
									<Box my={3} />
									<TextField
										label="Preu"
										type="number"
										fullWidth
										InputProps={{
											endAdornment: <InputAdornment position="end">€</InputAdornment>,
										}}
										{...register("preu")}
									/>
									<Box mt={1}>
										<Controller
											name={"talles"}
											control={control}
											render={({ field }) => (
												<FormControlLabel
													style={{ margin: 0 }}
													label={"Conté talles?"}
													control={
														<Checkbox
															onChange={(e) => field.onChange(e.target.checked)}
															size={"small"}
															checked={field.value === "1" || field.value === true}
														/>
													}
												/>
											)}
										/>
									</Box>
									<Box>
										<Controller
											name={"cupo"}
											control={control}
											render={({ field }) => (
												<FormControlLabel
													style={{ margin: 0 }}
													label={"Té preu variable que entra l'usuari? (cupons regal)"}
													control={
														<Checkbox
															onChange={(e) => field.onChange(e.target.checked)}
															size={"small"}
															checked={field.value === "1" || field.value === true}
														/>
													}
												/>
											)}
										/>
									</Box>
								</Grid>
								<Grid item md={6} mt={3}>
									<Thumb file={getValues("foto")} />
									<ImageInput trigger={trigger} register={register} name="foto" text={"Carregar imatge"} />
								</Grid>
							</Grid>
						</Box>
					</Loading>

					<Stack direction={"row"} spacing={2}>
						<CustomButton title="Tancar" fullWidth danger onClick={() => setOpen(false)} />
						<CustomButton type="submit" title="Guardar" fullWidth loading={loading} />
					</Stack>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default MaterialAddDialog;
