import { Dialog, DialogContent, DialogTitle, Divider, Grid, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addElement, updateElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import theme from "../../../theme";

function CartaCategoriaDialogAdd({ open, setOpen, cat, setUpdate, ubicacio }) {
	const { setValue, register, reset, handleSubmit } = useForm();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		cat ? reset(cat) : reset({});
	}, [cat]);

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	const guardar = async (values) => {
		setLoading(true);
		values.ubicacio = ubicacio;
		const { message } = await updateElement("carta_categories", cat?.id, values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	const crear = async (values) => {
		setLoading(true);
		values.ubicacio = ubicacio;
		console.log(values);
		const { message } = await addElement("carta_categories", values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setOpen(false);
		setUpdate((prev) => prev + 1);
		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
		>
			<DialogTitle sx={{ background: theme.color.primary, color: "white" }}>
				<Box display="flex" justifyContent={"space-between"}>
					Afegir categoria de la carta
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit(cat ? guardar : crear)}>
					<Box mt={3}>
						<Grid spacing={2} container>
							<Grid item md={4}>
								<Box mb={3}>
									<TextField {...register("nom")} fullWidth label="Titol" />
								</Box>
								<Box mb={3}>
									<TextField {...register("nom_es")} fullWidth label="Titol castellà" />
								</Box>
								<Box mb={3}>
									<TextField {...register("nom_en")} fullWidth label="Titol anglès" />
								</Box>
							</Grid>
							<Grid item md={4}>
								{" "}
								<Box mb={3}>
									<TextField {...register("text_prev")} fullWidth label="Text a l'inici de la categoria" />
								</Box>
								<Box mb={3}>
									<TextField {...register("text_prev_es")} fullWidth label="Text a l'inici castellà" />
								</Box>
								<Box mb={3}>
									<TextField {...register("text_prev_en")} fullWidth label="Text a l'inici anglès" />
								</Box>
							</Grid>
							<Grid item md={4}>
								{" "}
								<Box mb={3}>
									<TextField {...register("text_post")} fullWidth label="Text al final de la categoria" />
								</Box>
								<Box mb={3}>
									<TextField {...register("text_post_es")} fullWidth label="Text al final castellà" />
								</Box>
								<Box mb={3}>
									<TextField {...register("text_post_en")} fullWidth label="Text al final anglès" />
								</Box>
							</Grid>
						</Grid>

						<Divider />
						<Box mb={3} mt={2}>
							<TextField {...register("suplement")} fullWidth type="number" label="Suplement (€)" />
						</Box>
						<Stack direction={"row"} spacing={2}>
							<CustomButton title="Tancar" fullWidth danger onClick={() => setOpen(false)} />
							<CustomButton type="submit" title={cat ? "Guardar" : "Crear"} fullWidth loading={loading} />
						</Stack>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
}

export default CartaCategoriaDialogAdd;
