import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../../components/layouts/Page";
import bg from "../../../../assets/img/fons-2.jpg";
import paperMobile from "../../../../assets/img/paper.png";
import paper from "../../../../assets/img/paper-confirmacio.png";
import { useNavigate } from "react-router";
import theme from "../../../../theme";
import { useSearchParams } from "react-router-dom";
import { getElement } from "../../../../API/API";
import CustomButton from "../../../../components/elements/CustomButton";
import CartItemCheckout from "../../../../components/elements/CartItemCheckout";
import LoadingPublic from "../../../../components/layouts/LoadingPublic";

function UrlOk() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [reserva, setReserva] = useState();
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElement("reserva", searchParams.get("r"));
			setReserva(result);
			setLoading(false);
		};
		obtenir();
	}, [searchParams]);

	return (
		<Page title="Insomnia Corp. - Finalizado correctamente">
			<Box className={classes.portada}>
				<Container maxWidth={"lg"} style={{ padding: 0 }}>
					<Box display={"flex"} justifyContent="center" flexDirection={"column"} alignItems="center">
						<Box display={"flex"} flexDirection={"column"} alignItems="center" mb={10}>
							<Typography variant="h1" color="secondary" mb={1} textAlign="center">
								Reserva finalizada
							</Typography>
							<Divider
								color={theme.color.secondary}
								sx={{
									width: "80%",
									textAlign: "center",
								}}
							/>
						</Box>
						<LoadingPublic loading={loading}>
							<Box className={classes.box} px={matches ? 8 : 2} py={10}>
								<Grid spacing={2} container>
									<Grid item md={4} xs={12} mb={3}>
										<Typography pb={1} variant="h6">
											Sus datos
										</Typography>
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>

										<Divider />
										<Typography mt={2}>
											<strong>Nº de comanda:</strong>{" "}
										</Typography>
										<Typography>{reserva?.unique_id}</Typography>
										<Typography>
											<strong>Nombre:</strong>{" "}
										</Typography>
										<Typography>{reserva?.client.nom}</Typography>
										<Typography>
											<strong>E-mail:</strong>{" "}
										</Typography>
										<Typography>{reserva?.client.email}</Typography>
										<Typography>
											<strong>Teléfono:</strong>{" "}
										</Typography>
										<Typography>{reserva?.client.telefon}</Typography>
										<Typography>
											<strong>Población:</strong>{" "}
										</Typography>
										<Typography>{reserva?.client.poblacio}</Typography>
									</Grid>
									<Grid item md={8} xs={12} mb={2}>
										<Typography pb={1} variant="h6">
											Datos compra
										</Typography>
										<Divider
											sx={{
												borderWidth: 1,
												borderColor: theme.color.background,
											}}
										/>
										{reserva?.reserva_elements.map((item, index) => (
											<CartItemCheckout item={item} last={index + 1 === reserva?.reserva_elements?.length} comprat />
										))}
									</Grid>
								</Grid>
								<Divider
									sx={{
										borderWidth: 1,
										borderColor: theme.color.background,
									}}
								/>
								<Box display="flex" justifyContent={"space-between"} my={4}>
									<Typography variant="h2">Total:</Typography>
									<Typography variant="h2">{Math.round(reserva?.preu * 100) / 100} €</Typography>
								</Box>
								<Divider />

								<Box display="flex" justifyContent={"space-between"} my={1}>
									<Typography variant="h3">Paga y señal:</Typography>
									<Typography variant="h3">{reserva?.pagat} €</Typography>
								</Box>
								<Box display="flex" justifyContent={"space-between"} my={1}>
									<Typography variant="h3">Pendiente:</Typography>
									<Typography variant="h3">{Math.round((reserva?.preu - reserva?.pagat) * 100) / 100} €</Typography>
								</Box>
								<Divider />

								<Box textAlign={"center"} mt={2} displayPrint={"none"}>
									<CustomButton title="Volver" onClick={() => navigate("/reserves")} />
								</Box>
							</Box>
						</LoadingPublic>
					</Box>
				</Container>
			</Box>
		</Page>
	);
}

export default UrlOk;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 200,
		height: "100%",
		background: `url(${bg})`,
		backgroundSize: "contain",
		backgroundAttachment: "fixed",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
		paddingBottom: 100,
	},
	box: {
		background: `url(${paper})`,
		backgroundRepeat: "round",
		height: "100%",
		[theme.breakpoints.down("sm")]: {
			background: `url(${paperMobile})`,
			backgroundRepeat: "round",
		},
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
}));
